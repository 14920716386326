<template>
  <div class="chat-page" :class="`${pageType} ${chatType}`">
    <div class="modal-title flex-center-between" v-if="type === 'modal'">
      <div class="flex-center">
        <i class="el-icon-service"></i>
        {{ $t('zai-xian-ke-fu') }}
      </div>
      <div @click="hideModal" class="action">
        <i class="el-icon-caret-bottom"></i>
      </div>
    </div>
    <headerBar v-else :title="title" @back="back"></headerBar>
    <div class="message-box">
      <div
        class="list"
        v-for="(item, i) in messageArr"
        :key="i"
        :class="item.SenderID == userId ? 'flex-start-end right' : 'flex-start'"
      >
        <div class="header" v-if="item.SenderID != userId">
          <img :src="kefuUrl" alt="" />
        </div>
        <div class="content-outer" >
          <div class="content" v-if="item.Type && !item.goodsInfo || item.Type != 2">
            {{ item.Type == 2 ? $t('shang-pin-xin-xi-huo-qu-zhong') + '...' : item.Content }}
          </div>
          <div class="content goods flex-center" v-else>
            <div class="img">
              <img :src="item.goodsInfo.MainImage" alt="">
            </div>
            <div class="info">
              <div class="goods-title">
                {{ item.goodsInfo.ProductTranslation[0]
                  ? item.goodsInfo.ProductTranslation[0].Name
                  : '' }}
                </div>
              <div class="flex-center-between">
                <div class="price">${{ item.goodsInfo.SalePrice }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="header" v-if="item.SenderID == userId">
          <img :src="logoUrl" alt="" />
        </div>
      </div>
    </div>

    <div class="goods-box flex-center" v-if="goodsId && !hideGoods && goodsInfo.ID">
      <i class="el-icon-close close" @click="hideGoodsModal"></i>
      <div class="img">
        <img :src="goodsInfo.MainImage" alt="">
      </div>
      <div class="info">
        <div class="goods-title">
          {{ goodsInfo.ProductTranslation[0]
            ? goodsInfo.ProductTranslation[0].Name
            : '' }}
          </div>
        <div class="flex-center-between">
          <div class="price">${{ goodsInfo.SalePrice }}</div>
          <div class="goods-btn" @click="sendGoods">发送商品</div>
        </div>
      </div>
    </div>

    <div class="bottom-btn flex-center-between">
      <div class="input flex-1">
        <input type="text" v-model="content" :placeholder="$t('qing-shu-ru')" />
      </div>
      <div class="chat-btn">
        <Button @click="sendMsg">{{ $t('fa-song-0') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import headerBar from '@/components/header'
import { initWebSocket } from '@/utils/common'
import { getUserId, getToken } from '@/utils/auth'
import { messageList, messageRead, translateMsg, shopProductList } from '@/api/shop'
export default {
  components: {
    Button,
    headerBar,
    Icon
  },
  data() {
    return {
      uid: getUserId(),
      title: '',
      content: '',
      kefuUrl: require('@/assets/imgs/header/header1.png'),
      logoUrl: require('@/assets/imgs/header/header2.png'),
      messageArr: [],
      client: null,
      webSocket: null,
      page: {
        page: 1
      },
      pageType: '',
      goodsId: '',
      goodsInfo: {},
      hideGoods: false
    }
  },
  props: {
    type: String
  },
  computed: {
    userId() {
      return getUserId()
    },
    lang() {
      return this.$store.state.lang
    },
    langName() {
      return this.$store.state.langName
    },
    chatType() {
      return this.$store.state.chatType
    }
  },
  beforeDestroy() {
    if (this.webSocket && this.webSocket.close) {
      this.webSocket.close()
    }
    this.markMessagesAsRead()
  },
  mounted() {
    this.uid = this.$route.query.uid
    this.title = this.$route.query.name
    this.goodsId = this.$route.query.goodsId
    this.pageType = this.$route.name === 'wapUserChat' ? 'user' : ''
    this.initMessage()
    if (this.userId) {
      this.initSocket()
    }
    if (this.goodsId) {
      this.initGoods()
    }
    this.getMessageList()
  },
  methods: {
    initGoods() {
      shopProductList({
        ID: parseFloat(this.goodsId)
      }).then((res) => {
        let list = res.data.Items
        this.goodsInfo = list[0]
      })
    },
    getGoods(data, i) {
      shopProductList({
        ID: parseFloat(data.Content)
      }).then((res) => {
        let list = res.data.Items
        let goodsInfo = list[0]
        this.$set(this.messageArr[i], 'goodsInfo', goodsInfo)
      })
    },
    initMessage() {
      this.getMessageList()
    },
    getMessageList() {
      messageList({
        current: 1,
        pageSize: 999,
        ConversationID: this.$route.query.uid
      }).then(res => {
        this.messageArr = res.data.Items.reverse()
        console.log('Message list loaded:', this.messageArr)

        // 获取商品信息
        this.messageArr.forEach((v, i) => {
          if (v.Type == 2) {
            this.getGoods(v, i)
          }
        })

        // 标记历史消息为已读
        const unreadMessages = this.messageArr
          .filter(msg => !msg.IsRead && msg.ReceiverID === Number(this.userId))
          .map(msg => msg.ID)


        if (unreadMessages.length > 0) {
          messageRead({
            MessageID: unreadMessages
          }).then(() => {
            console.log('Historical messages marked as read')
            this.$emit('update-unread')
          })
        }

        // 滚动到底部
        this.scrollToBottom()
      })
    },
    markMessagesAsRead() {
      const unreadMessages = this.messageArr
        .filter(msg => !msg.IsRead && msg.ReceiverID === Number(this.uid))
        .map(msg => msg.ID)

      console.log('Marking messages as read:', unreadMessages)

      if (unreadMessages.length > 0) {
        messageRead({
          MessageID: unreadMessages
        }).then(() => {
          console.log('Messages marked as read')
          // 通知父组件更新未读消息数量
          this.$emit('update-unread')
        }).catch(err => {
          console.error('Failed to mark messages as read:', err)
        })
      }
    },
    async initSocket() {
      //初始化weosocket
      let client = await initWebSocket(this.userId, this.websocketonmessage)
      this.client = client
      this.webSocket = client.ws
    },
    back() {
      this.$router.go(-1)
    },
    toTranslate(data, i) {
      if (data.translateMsg) {
        this.$set(this.messageArr[i], 'isTranslate', true)
        return
      }
      let form = new FormData()
      form.append('ID', data.ID)
      translateMsg(form).then((res) => {
        let msg = res.data.Content
        this.$set(this.messageArr[i], 'isTranslate', true)
        this.$set(this.messageArr[i], 'translateMsg', msg)
      })
    },
    showOriginMsg(i) {
      this.$set(this.messageArr[i], 'isTranslate', false)
    },
    websocketonmessage(e) {
      let redata = {}
      try {
        redata = JSON.parse(e.body)
        this.setListData(redata)
      } catch (e) {}
    },
    setListData(data) {
      // 判断是否是当前对话
      let isCurrentChat = data.SenderID === parseInt(this.uid) || data.ReceiverID === parseInt(this.uid)
      
      if (!isCurrentChat) {
        return
      }

      // 添加新消息到列表
      if (data.Type == 2) {
        if (this.goodsInfo.ID) {
          data.goodsInfo = this.goodsInfo
        }
        this.messageArr.push(data)
        if (!this.goodsInfo.ID) {
          let length = this.messageArr.length
          this.getGoods(data, length - 1)
        }
      } else {
        this.messageArr.push(data)
      }

      // 如果是接收到的新消息，立即标记为已读
      if (data.ReceiverID === this.userId) {
        messageRead({
          MessageID: [data.ID]
        }).then(() => {
          console.log('New message marked as read:', data.ID)
          this.$emit('update-unread')
        })
      }

      // 滚动到底部
      this.scrollToBottom()
    },
    sendGoods() {
       // 发送消息
       let message = {
        ReceiverID: parseInt(this.uid),
        Type: 2,
        Content: this.goodsId.toString(),
        token: getToken()
      }
      this.client.send(
        '/exchange/direct_logs/chat_queue',
        { 'content-type': 'application/json' },
        JSON.stringify(message)
      )

      this.hideGoods = true
    },
    sendMsg() {
      if (!this.content) {
        this.$toast.fail(this.$t('fa-song-nei-rong-bu-neng-wei-kong'))
        return
      }
      // 发送消息
      let message = {
        ReceiverID: parseInt(this.uid),
        Type: 0,
        Content: this.content,
        token: getToken()
      }
      this.client.send(
        '/exchange/direct_logs/chat_queue',
        { 'content-type': 'application/json' },
        JSON.stringify(message)
      )
      this.content = ''
    },
    hideModal() {
      this.$emit('cancel')
    },
    
    hideGoodsModal() {
      this.hideGoods = true
    },
    // 添加一个滚动到底部的方法
    scrollToBottom() {
      setTimeout(() => {
        const messageBox = document.querySelector('.message-box')
        if (messageBox) {
          messageBox.scrollTop = messageBox.scrollHeight
        }
      }, 100)
    }
  }
}
</script>
<style lang="less" scoped>
.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.message-box {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  -webkit-overflow-scrolling: touch; // 增加 iOS 滚动流畅度
}
</style>