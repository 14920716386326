<template>
  <div class="wap-debt">
    <headerBar
      :title="$t('zi-jin-ji-lu')"
      @back="back"
      right-icon="filter-o"
      @detail="toDetail"
    ></headerBar>

    <div class="debt-list">
      <List
        v-if="logList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="
          isInit && logList.length == 0 ? '' : $t('common.noMore')
        "
        @load="onLoad"
      >
        <div class="list flex-center" v-for="(item, i) in logList" :key="i">
          <div class="icon">
            <img :src="inUrl" alt="" v-if="item.Practical >= 0" />
            <img :src="outUrl" alt="" v-else />
          </div>
          <div class="flex-1 info">
            <div class="name">{{ getTypeName(item.Type) }}</div>
            <div class="time">{{ item.CreatedAt }}</div>
            <div class="time">
              {{ $t('bian-geng-hou-jine') }}
              {{ item.DebtAfter }}
            </div>
          </div>
          <div class="price" :class="item.Practical >= 0 ? 'green' : 'red'">
            {{ item.Practical >= 0 ? '+' : '' }} ${{ item.Practical }}
          </div>
        </div>
      </List>
    </div>

    <Popup v-model="showPopup" position="bottom">
      <Picker
        :columns="typeList"
        show-toolbar
        value-key="label"
        @cancel="cancel"
        @confirm="chooseType"
      ></Picker>
    </Popup>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { Popup, Button, List, Picker } from 'vant'
import { debtList, debtType } from '@/api/shop'
export default {
  components: {
    headerBar,
    Button,
    Popup,
    List,
    Picker
  },
  data() {
    return {
      inUrl: require('@/assets/imgs/icon-in.png'),
      outUrl: require('@/assets/imgs/icon-out.png'),
      logList: [],
      page: {
        page: 1,
        limit: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      typeList: [],
      showPopup: false,
      form: {
        type: ''
      }
    }
  },
  computed: {},
  mounted() {
    this.initType()
  },
  methods: {
    initType() {
      debtType().then((res) => {
        let data = res.data
        let list = [
          {
            value: '',
            label: this.$t('quan-bu')
          }
        ]
        for (let key in data) {
          let param = {
            value: key,
            label: data[key]
          }
          list.push(param)
        }
        this.typeList = list
        this.init()
      })
    },
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      debtList({
        current: this.page.page,
        pageSize: this.page.limit,
        Type: this.form.type ? parseFloat(this.form.type) : null
      })
        .then((res) => {
          if (isAdd) {
            this.logList = this.logList.concat(res.data.Items)
          } else {
            this.logList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.page) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    toDetail() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    chooseType(e) {
      this.showPopup = false
      this.form.type = e.value
      this.init()
    },
    getTypeName(type) {
      let index = this.typeList.findIndex((v) => {
        return v.value == type
      })
      if (index > -1) {
        return this.typeList[index].label
      } else {
        return ''
      }
    }
  }
}
</script>