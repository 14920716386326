<template>
  <div class="level-page">
    <el-card class="level-info">
      <div class="flex-center-between">
        <div class="flex-center">
          <div class="icon">
            <img :src="logoObj[`logo${level}`] || levelUrl" alt="" />
            <span class="level"
              >{{ $t('deng-ji') }}
              {{ levelName }}
            </span>
          </div>
          <div>
            <div class="name">
              {{ levelName }}
              {{ $t('mai-jia-deng-ji') }}
            </div>
            <div class="label">
              {{ $t('dang-qian-fen-dian-ren-shu') }}：<span class="blue"
                >0/100000</span
              >
            </div>
            <div class="label">
              {{ $t('dang-qian-yun-hang-zi-jin') }}
              <span class="blue">${{ totalMoney }}</span>
              {{ $t('ju-li-deng-ji') }}
              {{ nextLevelName }}
              {{ $t('huan-xu') }}
              <span class="blue">${{ upgradeMoney.toFixed(2) }}</span>
            </div>
            <!-- <div class="label">
              {{ $t('dang-qian-cai-gou-you-hui') }}
              <span class="blue">{{ shopInfo.ShopLevel.ProfitRate }}%</span>
            </div> -->
          </div>
        </div>
        <div class="percent-line">
          <div class="levelName" :class="`bg-${level}`">{{ levelName }}</div>
          <div class="percent-outer">
            <div class="percent" :style="`width: ${percent}%`"></div>
            <div class="percent-label" :style="`left: ${percent}%`">
              {{ percent }}%
            </div>
          </div>
          <div class="levelName" :class="`bg-${level + 1}`">
            {{ nextLevelName }}
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="level-card">
      <div class="list-outer">
        <div class="list" v-for="(item, i) in levelList" :key="i">
          <div class="flex-center-between top">
            <div>
              <div class="name">
                {{ item.LevelName }}
                {{ $t('mai-jia-deng-ji-0') }}
              </div>
              <div class="label">
                {{ $t('yun-hang-zi-jin') }}
                ${{ item.UpgradeMoney }}
              </div>
            </div>
            <div>
              <img :src="logoObj[`logo${item.Level}`] || packageUrl" alt="" />
            </div>
          </div>

          <div class="info">
            <div class="line">
              <div class="label">{{ $t('xiao-shou-li-run-bi') }}</div>
              <div class="value">
                {{ item.ProfitRate }}%-{{ item.ProfitRate }}%
              </div>
            </div>
            <div class="line">
              <div class="label">
                {{ $t('ping-tai-liu-liang-fu-chi-liang-mei-ri') }}
              </div>
              <div class="value">
                {{ item.GoodsLimit }}-{{ item.GoodsLimit }}
              </div>
            </div>
            <div class="line">
              <div class="label">{{ $t('quan-qiu-dao-huo-shi-jian') }}</div>
              <div class="value">{{ item.date }}</div>
            </div>
            <div class="line">
              <div class="label">{{ $t('cai-gou-you-hui') }}</div>
              <div class="value">{{ item.discount }}%</div>
            </div>
            <div class="line">
              <div class="label">{{ $t('sheng-ji-li-jin') }}</div>
              <div class="value">
                <i
                  class="el-icon-success"
                  style="color: #0ecb81"
                  v-if="item.upgrade"
                ></i>
                <i class="el-icon-error" style="color: #e23939" v-else></i>
              </div>
            </div>
            <div class="line">
              <div class="label">{{ $t('zhuan-shu-fu-wu') }}</div>
              <div class="value">
                <i
                  class="el-icon-success"
                  style="color: #0ecb81"
                  v-if="item.vip"
                ></i>
                <i class="el-icon-error" style="color: #e23939" v-else></i>
              </div>
            </div>
            <div class="line">
              <div class="label">{{ $t('shou-ye-tui-jian') }}</div>
              <div class="value">
                <i
                  class="el-icon-success"
                  style="color: #0ecb81"
                  v-if="item.recommend"
                ></i>
                <i class="el-icon-error" style="color: #e23939" v-else></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { shopLevelList, shopInfo } from '@/api/shop'
import { getWalletInfo } from '@/api/user'
import passwordKeyboard from '@/components/password'
export default {
  components: {
    passwordKeyboard
  },
  data() {
    return {
      password: '',
      level: -1,
      levelIcon: '',
      levelName: '',
      nextLevelName: '',
      ShopLevelID: '',
      levelList: [],
      passwordDialog: false,
      packageUrl: require('@/assets/imgs/shop-level1.png'),
      levelUrl: require('@/assets/imgs/shop-level1.png'),
      logoObj: {
        logo1: require('@/assets/imgs/shop-level1.png'),
        logo2: require('@/assets/imgs/shop-level2.png'),
        logo3: require('@/assets/imgs/shop-level3.png'),
        logo4: require('@/assets/imgs/shop-level4.png'),
        logo5: require('@/assets/imgs/shop-level5.png'),
        logo6: require('@/assets/imgs/shop-level6.png')
      },
      shopInfo: {
        ShopLevel: {}
      },
      totalMoney: 0,
      nextUpgradeMoney: 0
    }
  },
  computed: {
    upgradeMoney() {
      if (this.nextUpgradeMoney) {
        let money = this.nextUpgradeMoney - this.totalMoney
        return money > 0 ? money : 0
      } else {
        return 0
      }
    },
    percent() {
      if (this.nextUpgradeMoney && this.totalMoney) {
        let percent = parseFloat(
          ((this.totalMoney / this.nextUpgradeMoney) * 100).toFixed(2)
        )
        return percent > 100 ? 100 : percent
      } else {
        return 0
      }
    }
  },
  mounted() {
    this.init()
    this.initMoney()
  },
  methods: {
    initMoney() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.totalMoney = parseFloat((data.Balance + data.Freeze).toFixed(2))
      })
    },
    init() {
      shopLevelList().then((res) => {
        this.levelList = res.data.Items
        this.levelList.sort((a, b) => {
          return a.Level - b.Level
        })
        if (this.level != -1) {
          this.getLevelName()
        }
      })

      shopInfo().then((res) => {
        this.shopInfo = res.data
        this.level = res.data.ShopLevel.Level || 1
        if (this.levelList.length > 0) {
          this.getLevelName()
        }
      })
    },
    getLevelName() {
      let item = this.levelList.find((v) => {
        return v.Level == this.level
      })
      let nextItem = this.levelList.find((v) => {
        return v.Level == this.level + 1
      })
      if (item) {
        this.levelName = item.LevelName
      } else {
        this.levelName = this.levelList[0].LevelName
      }
      this.nextLevelName = nextItem ? nextItem.LevelName : ''
      this.nextUpgradeMoney = nextItem ? nextItem.UpgradeMoney : 0
    },
    toBuy(data) {
      this.passwordDialog = true
    }
  }
}
</script>