<template>
  <div class="goods-detail-page">
    <div class="flex">
      <div class="left">
        <div class="flex goods-info">
          <div class="banner-box">
            <div class="big-banner">
              <Swipe
                ref="bigWwipe"
                :autoplay="3000"
                :loop="true"
                :show-indicators="false"
                :height="356"
                @change="changeBig"
              >
                <SwipeItem v-for="(item, i) in bannerList" :key="`big${i}`">
                  <img :src="item" alt="" @dragstart.prevent />
                </SwipeItem>
              </Swipe>
            </div>

            <div class="small-banner">
              <Swipe
                ref="swipe"
                :autoplay="3000"
                :loop="false"
                :show-indicators="false"
                :width="97"
                :height="87"
              >
                <SwipeItem
                  v-for="(item, i) in bannerList"
                  :key="`small${i}`"
                  @click="clickSmall(i)"
                >
                  <div
                    class="img-box"
                    :class="activeIndex == i ? 'active' : ''"
                  >
                    <div class="box-inner">
                      <img :src="item" alt="" @dragstart.prevent />
                    </div>
                  </div>
                </SwipeItem>
              </Swipe>
            </div>
          </div>
          <div class="info-box">
            <div class="title">
              {{
                detailData.ProductTranslation &&
                detailData.ProductTranslation[0]
                  ? detailData.ProductTranslation[0].Name
                  : ''
              }}
            </div>
            <div class="price-line flex-center-between">
              <div class="price">
                <span class="label">{{ $t('ling-shou-jia') }}</span>
                ${{ optionPrice || detailData.SalePrice }}
              </div>
              <div class="flex">
                <div class="flex-center info" @click="showKefu">
                  <i class="el-icon-service"></i>
                  {{ $t('ke-fu') }}
                </div>
                <div class="flex-center info">
                  <i class="el-icon-star-off"></i>
                  {{ $t('shou-cang') }}
                </div>
              </div>
            </div>

            <div class="info-line flex-center-start">
              <div class="label">
                {{ $t('xiao-shou-liang') }}
                {{ detailData.Sales }}
              </div>
              <div class="value">
                {{ $t('liu-lan-liang') }}
                {{ detailData.Visible || 0 }}
              </div>
            </div>
            <div class="info-line flex-center-start">
              <div class="label">{{ $t('fa-huo') }}</div>
              <div class="value">
                {{
                  $t(
                    'shang-pin-xia-dan-hou-24-xiao-shi-nei-fa-huo-ru-xia-dan-cun-zai-wu-liu-guan-kong-ding-dan-ke-neng-bei-yan-shi-fa-huo-qing-liu-yi-ding-dan-wu-liu-xin-xi-huo-lian-xi-ke-fu'
                  )
                }}
              </div>
            </div>
            <div class="info-line flex-center-start">
              <div class="label">{{ $t('yun-fei') }}</div>
              <div class="value">
                {{ $t('mian-yun-fei') }}
                <el-tooltip class="item" effect="dark" placement="top">
                  <i class="el-icon-warning"></i>
                  <div slot="content">
                    <div class="desc">
                      {{
                        $t(
                          '1-kua-jing-shang-pin-yun-fei-gou-cheng-yun-fei-pai-song-fei-chang-tu-yun-fei-song-huo-fei'
                        )
                      }}
                    </div>
                    <div class="desc">
                      {{
                        $t(
                          '2-ru-bu-man-zu-bao-you-tiao-jian-an-shi-ji-shou-qu-yun-fei-chan-pin'
                        )
                      }}
                    </div>
                    <div class="desc">
                      {{ $t('3-zui-zhong-jie-shi-quan-gui-ping-tai-suo-you') }}
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>

            <div class="info-line flex-center-start">
              <div class="label">{{ $t('shu-liang') }}</div>
              <div class="value">
                <el-input-number
                  v-model="form.number"
                  :min="minBuyCount"
                  @change="handleNumberChange"
                ></el-input-number>
              </div>
            </div>

            <div class="info-line flex-center-start">
              <div class="label">
                {{
                  chooseOptionList.length > 0
                    ? $t('yi-xuan')
                    : $t('qing-xuan-ze')
                }}
              </div>
              <div class="value">
                {{
                  chooseOptionList.length > 0
                    ? `${chooseOptionName}`
                    : `${optionName}`
                }}
              </div>
            </div>
            <div class="option-list flex-wrap">
              <div
                class="option-line flex"
                v-for="(item, i) in optionList"
                :key="`option${i}`"
              >
                <div class="tag">{{ item.Name }}</div>
                <div class="list flex-wrap">
                  <div
                    class="option flex-center"
                    :class="option.checked ? 'active' : ''"
                    v-for="(option, index) in item.options"
                    :key="index"
                    @click="changeOption(i, index, item, option)"
                  >
                    {{ option.Value }}
                  </div>
                </div>
              </div>
            </div>

            <div class="info-line flex-center-start">
              <div class="label">{{ $t('zong-jia') }}</div>
              <div class="value">
                <div class="price">${{ parseFloat(totalMoney).toFixed(2) }}</div>
              </div>
            </div>

            <div class="btn-line flex-center-between">
              <el-button class="btn1" @click="toBuy">{{
                $t('li-ji-gou-mai')
              }}</el-button>
              <el-button class="btn2" @click="addToCar">{{
                $t('tian-jia-gou-wu-che')
              }}</el-button>
            </div>
          </div>
        </div>

        <div class="goods-evalute goods-box">
          <div class="title flex-center-between">
            <div>
              {{ $t('yong-hu-ping-jia') }}
              ({{ page.total }})
            </div>
            <div class="flex-center">
              <div
                class="tag"
                v-for="(item, i) in tagList"
                :key="`tag${i}`"
                @click="changeTag(item, i)"
                :class="tagIndex == i ? 'active' : ''"
              >
                {{ item.name }}({{ item.count }})
              </div>
            </div>
          </div>
          <div class="detail clear">
            <div
              class="evalute"
              v-for="(item, i) in evaluteList"
              :key="`evalute${i}`"
            >
              <div class="user flex-center-start">
                <img :src="headerUrl" alt="" />
                {{ item.User.Email || item.User.Phone }}
              </div>
              <div class="flex-center-start">
                <div class="icon">
                  <i
                    class="el-icon-star-on"
                    style="color: rgb(247, 186, 42)"
                    v-for="i in item.Score"
                    :key="`star1-${i}`"
                  ></i>
                  <i
                    class="el-icon-star-on"
                    style="color: rgb(239, 242, 247)"
                    v-for="i in 5 - item.Score"
                    :key="`star2-${i}`"
                  ></i>
                </div>
                <!-- <div class="status">{{ item.statusName }}</div> -->
              </div>
              <div class="content">{{ item.Content }}</div>
              <div class="imgs flex-wrap">
                <!-- <img
                  :src="img"
                  v-for="(img, index) in item.imgs"
                  :key="index"
                  alt=""
                /> -->
              </div>
              <div class="time">{{ item.CreatedAt }}</div>
            </div>
            <div class="flex-center user-pager mt-20 mb-20">
              <el-pagination
                background
                @current-change="currentChange"
                :current-page="page.current"
                :page-size="page.size"
                layout=" prev, pager,  next"
                :total="page.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>

        <div class="goods-desc goods-box">
          <div class="title flex-center-between">
            {{ $t('shang-pin-jie-shao') }}
          </div>
          <div
            class="detail"
            v-if="
              detailData.ProductTranslation && detailData.ProductTranslation[0]
            "
            v-html="detailData.ProductTranslation[0].Detail"
          ></div>
        </div>
      </div>
      <div class="right">
        <div class="shop">
          <div class="logo flex-center-start">
            <img :src="shopInfo.Logo" alt="" />
            <div class="name">{{ shopInfo.Name }}</div>
          </div>
          <div class="info flex">
            <div class="flex-1 flex-column-center">
              <div class="count">{{ shopInfo.TotalGoods || 0 }}</div>
              <div class="label">{{ $t('quan-bu-shang-pin') }}</div>
            </div>
            <div class="flex-1 flex-column-center">
              <div class="count">{{ shopInfo.TotalFollow || 0 }}</div>
              <div class="label">{{ $t('guan-zhu') }}</div>
            </div>
          </div>
          <div class="flex-column-center">
            <div class="count">{{ shopInfo.TotalDeal }}</div>
            <div class="label">{{ $t('xiao-shou-liang') }}</div>
            <el-button
              class="btn"
              @click="toPath('shopInfo', { id: shopInfo.ID })"
              >{{ $t('fang-wen-shang-dian') }}</el-button
            >
          </div>
        </div>

        <div class="sub-title">{{ $t('tui-jian-chan-pin-0') }}</div>

        <div class="goods-list-box">
          <div
            class="goods flex-center"
            v-for="(item, i) in recommondGoodsList"
            :key="i"
            @click="toDetail(item)"
          >
            <div class="img">
              <img :src="item.MainImage" alt="" />
            </div>
            <div class="flex-1">
              <div class="name">
                {{
                  item.ProductTranslation ? item.ProductTranslation[0].Name : ''
                }}
              </div>
              <div class="flex-center-between">
                <div class="price">${{ item.SalePrice }}</div>
                <div class="collect">
                  <i class="el-icon-star-off"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-title">{{ $t('cai-ni-xi-huan') }}</div>
    <goodsList :list="goodsList"></goodsList>

    <bottomLink></bottomLink>
  </div>
</template>
<script>
import { shopProductList, getProductEvaluteList } from '@/api/shop'
import { addCar } from '@/api/user'
import '@vant/touch-emulator'
import goodsList from '@/components/goodsList'
import bottomLink from '@/components/bottomLink'
import { SwipeItem, Swipe } from 'vant'
export default {
  components: {
    Swipe,
    SwipeItem,
    goodsList,
    bottomLink
  },
  data() {
    return {
      form: {
        number: 1
      },
      minBuyCount: 1,
      headerUrl: require('@/assets/imgs/header/header1.png'),
      shopInfo: {},
      tagIndex: -1,
      optionIndex: 0,
      activeIndex: 2,
      detailData: {
        ProductTranslation: [],
        ProductParametersRelation: null,
        Shop: {}
      },
      bannerList: [],
      shopUId: '',
      optionName: '',
      chooseOptionList: [],
      optionPriceList: [],
      optionList: [],
      chooseOptionName: '',
      optionPrice: '',
      totalEvaluteCount: 0,
      tagList: [
        {
          name: this.$t('you-tu'),
          count: 0
        },
        {
          name: this.$t('hao-ping'),
          count: 0
        },
        {
          name: this.$t('zhong-ping'),
          count: 0
        },
        {
          name: this.$t('cha-ping'),
          count: 0
        }
      ],
      evaluteList: [],
      recommondGoodsList: [],
      goodsList: [],
      dataId: '',
      page: {
        current: 1,
        size: 10,
        total: 0
      }
    }
  },
  computed: {
    totalMoney() {
      return (
        (this.form.number || 0) *
        (this.optionPrice || this.detailData.SalePrice)
      )
    },
    lang() {
      return this.$store.state.lang
    },
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  watch: {
    $route() {
      this.dataId = this.$route.query.id
      this.init()
      this.initGoods()
      this.initEvalute()
    },
    '$store.state.configData': {
      handler(newVal) {
        if(newVal && newVal.goods_min_buy) {
          const minBuy = parseInt(newVal.goods_min_buy)
          this.minBuyCount = minBuy
          if (this.form.number < minBuy) {
            this.form.number = minBuy
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
    this.initGoods()
    this.initEvalute()
  },
  methods: {
    initEvalute() {
      let form = new FormData()
      form.append('ProductID', this.dataId)
      form.append('current', this.page.current)
      form.append('pageSize', this.page.size)
      getProductEvaluteList(form).then((res) => {
        this.evaluteList = res.data.Items
        this.page.total = res.data.Pagination
          ? res.data.Pagination.totalRecords
          : 0
      })
    },
    currentChange(page) {
      this.page.current = page
      this.initEvalute()
    },
    init() {
      shopProductList({
        ID: parseFloat(this.dataId)
      }).then((res) => {
        let list = res.data.Items
        this.detailData = list[0]
        this.shopUId = this.detailData.Shop.UId
        this.shopInfo = this.detailData.Shop
        this.bannerList = JSON.parse(this.detailData.Images)
        let optionList = this.detailData.ProductSpecifications || []
        this.optionPriceList =
          this.detailData.BusinessProductSpecificationsRelation || []
        this.optionList = this.resetOption(optionList)
        this.optionName = this.optionList
          .map((v) => {
            return v.Name
          })
          .join()

        // 店铺推荐商品
        this.getRecommondGoods()
      })
    },
    getRecommondGoods() {
      shopProductList({
        current: 1,
        pageSize: 4,
        ShopID: this.shopInfo.ID
      }).then((res) => {
        this.recommondGoodsList = res.data.Items.filter((v) => {
          return v.ID != this.dataId
        }).slice(0, 3)
      })
    },
    initGoods() {
      shopProductList({
        current: 1,
        pageSize: 7
      }).then((res) => {
        this.goodsList = res.data.Items.filter((v) => {
          return v.ID != this.dataId
        }).slice(0, 6)
      })
    },
    resetOption(list) {
      let newList = []
      list.forEach((v) => {
        let index = newList.findIndex((val) => {
          return val.Name == v.Name
        })
        if (index > -1) {
          newList[index].options.push(v)
        } else {
          newList.push({
            Name: v.Name,
            options: [v]
          })
        }
      })
      return newList
    },
    changeBig(e) {
      this.activeIndex = e
    },
    clickSmall(e) {
      this.activeIndex = e
      this.$refs.bigWwipe.swipeTo(e)
    },
    changeTag(data, i) {
      this.tagIndex = i
    },
    toBuy() {
      if (!this.isLogin) {
        this.$router.push({
          name: 'userLogin'
        })
        return
      }
      if (this.optionList.length != this.chooseOptionList.length) {
        this.$message.warning(this.$t('qing-xuan-ze-gui-ge'))
        return
      }
      if (this.form.number < this.minBuyCount) {
        this.form.number = this.minBuyCount
        this.$message.warning(this.$t('min-buy-count-warning', { count: this.minBuyCount }))
        return
      }
      sessionStorage.setItem(
        'orderData',
        JSON.stringify([
          {
            Shop: this.detailData.Shop,
            goodsList: [
              {
                ...this.detailData,
                count: this.form.number,
                optionName:
                  this.chooseOptionList.length > 0 ? this.chooseOptionName : '',
                optionId: this.optionInfo ? this.optionInfo.ID : null
              }
            ]
          }
        ])
      )
      this.$router.push({
        name: 'order'
      })
    },
    addToCar() {
      if (!this.isLogin) {
        this.$router.push({
          name: 'userLogin'
        })
        return
      }
      if (this.optionList.length != this.chooseOptionList.length) {
        this.$message.warning(this.$t('qing-xuan-ze-gui-ge'))
        return
      }
      if (this.form.number < this.minBuyCount) {
        this.form.number = this.minBuyCount
        this.$message.warning(this.$t('min-buy-count-warning', { count: this.minBuyCount }))
        return
      }
      addCar({
        BusinessProductID: parseFloat(this.dataId),
        BusinessProductSpecificationsRelationID: this.optionInfo
          ? this.optionInfo.ID
          : null,
        Number: this.form.number
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('tian-jia-cheng-gong'))
          this.$store.state.initCar = !this.$store.state.initCar
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    toDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID
        }
      })
    },
    changeOption(i, index, classify, data) {
      let optionIndex = this.chooseOptionList.findIndex((v) => {
        return v.Name == classify.Name
      })
      if (optionIndex > -1) {
        let oldData = this.chooseOptionList[optionIndex].option
        let oldIndex = this.optionList[i].options.findIndex((v) => {
          return v.Value == oldData.Value
        })

        this.$set(this.optionList[i].options[oldIndex], 'checked', false)
        this.$set(this.optionList[i].options[index], 'checked', true)
        this.chooseOptionList[optionIndex].option = data
      } else {
        this.chooseOptionList.push({
          Name: classify.Name,
          option: data
        })
        this.$set(this.optionList[i].options[index], 'checked', true)
      }

      this.getOptionName()
      this.getOptionPrice()
    },
    getOptionName() {
      let nameList = []
      this.chooseOptionList.forEach((v) => {
        let name = v.Name + '：' + v.option.Value
        nameList.push(name)
      })
      this.chooseOptionName = nameList.join(';')
    },
    getOptionPrice() {
      let checkedIds = this.chooseOptionList
        .map((v) => {
          return v.option.ID
        })
        .sort((a, b) => {
          return a - b
        })
        .join('-')
      let index = this.optionPriceList.findIndex((v) => {
        let priceId = v.SpecificationsID.split('-')
          .sort((a, b) => {
            return a - b
          })
          .join('-')
        return priceId == checkedIds
      })
      if (index > -1) {
        this.optionPrice = this.optionPriceList[index].Price
        this.MinPrice = this.optionPriceList[index].MinPrice
        this.optionStock = this.optionPriceList[index].Stock
        this.optionInfo = this.optionPriceList[index]
      }
    },
    toPath(path, query) {
      this.$router.push({
        name: path,
        query
      })
    },
    showKefu() {
      this.$store.state.showMessageBox = true
      this.$store.state.messageUid = this.shopUId
      this.$store.state.goodsId = this.dataId
      this.$store.state.messageTitle = this.detailData.Shop.Name
    },
    handleNumberChange(value) {
      if (value < this.minBuyCount) {
        this.form.number = this.minBuyCount
        this.$message.warning(this.$t('min-buy-count-warning', { count: this.minBuyCount }))
      }
    }
  }
}
</script>