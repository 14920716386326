<template>
  <div class="wap-home">
    <div class="top">
      <div class="flex-center-between bg-box">
        <div class="flex-center logo">
          <img :src="shopInfo.Logo" alt="" />
          <div class="name">{{ shopInfo.Name }}</div>
          <div
            class="shop-level"
            :class="`level-${shopInfo.ShopLevel.Level}`"
          ></div>
        </div>
        <div class="flex-center icon">
          <Badge :content="noticeCount" v-if="noticeCount > 0">
            <i class="el-icon-bell" @click="toPath('wapNotice')"></i>
          </Badge>
          <i class="el-icon-bell" @click="toPath('wapNotice')" v-else></i>
        </div>
      </div>
      <wap-shop-auth v-if="!isShopAuth"></wap-shop-auth>
    </div>

    <div class="home-main">
      <div class="statistics-box flex">
        <div class="flex-column-center flex-1">
          <div class="count">
            ${{
              detailData.TotalSales ? detailData.TotalSales.toFixed(2) : '0.00'
            }}
          </div>
          <div class="label flex-center">
            <img :src="moneyUrl" alt="" />
            {{ $t('zong-xiao-shou-e') }}
          </div>
        </div>
        <div class="flex-column-center flex-1">
          <div class="count">
            ${{
              detailData.TotalProfit
                ? detailData.TotalProfit.toFixed(2)
                : '0.00'
            }}
          </div>
          <div class="label flex-center">
            <img :src="profitUrl" alt="" />
            {{ $t('zong-li-run') }}
          </div>
        </div>
      </div>

      <div class="today">
        <div class="title">{{ $t('jin-ri-shu-ju') }}</div>
        <div class="flex-wrap">
          <div class="box flex-center-between">
            <div>
              <div class="count">{{ visitCountObj.today }}</div>
              <div class="label">{{ $t('jin-ri-fang-ke') }}</div>
            </div>
            <div class="icon">
              <img :src="dataUrl1" alt="" />
            </div>
          </div>
          <div class="box flex-center-between">
            <div>
              <div class="count">{{ visitCountObj.last7 }}</div>
              <div class="label">{{ $t('7-ri-fang-ke') }}</div>
            </div>
            <div class="icon">
              <img :src="dataUrl2" alt="" />
            </div>
          </div>
          <div class="box flex-center-between">
            <div>
              <div class="count">{{ visitCountObj.last30 }}</div>
              <div class="label">{{ $t('30-ri-fang-ke') }}</div>
            </div>
            <div class="icon">
              <img :src="dataUrl3" alt="" />
            </div>
          </div>
          <div class="box flex-center-between">
            <div>
              <div class="count">{{ detailData.DayOrder || 0 }}</div>
              <div class="label">{{ $t('jin-ri-ding-dan') }}</div>
            </div>
            <div class="icon">
              <img :src="dataUrl4" alt="" />
            </div>
          </div>
          <div class="box flex-center-between">
            <div>
              <div class="count">
                ${{
                  detailData.DaySales ? detailData.DaySales.toFixed(2) : '0.00'
                }}
              </div>
              <div class="label">{{ $t('jin-ri-zong-e') }}</div>
            </div>
            <div class="icon">
              <img :src="dataUrl5" alt="" />
            </div>
          </div>
          <div class="box flex-center-between">
            <div>
              <div class="count">
                ${{
                  detailData.DayProfit
                    ? detailData.DayProfit.toFixed(2)
                    : '0.00'
                }}
              </div>
              <div class="label">{{ $t('yu-ji-li-run') }}</div>
            </div>
            <div class="icon">
              <img :src="dataUrl6" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="echarts-main">
        <div class="time-tab">
          <!-- <div
            class="tab"
            :class="timeFlag == 1 ? 'active' : ''"
            @click="changeTime(1)"
          >
            今日
          </div> -->
          <div
            class="tab"
            :class="timeFlag == 2 ? 'active' : ''"
            @click="changeTime(2)"
          >
            {{ $t('jin-7-tian') }}
          </div>
          <div
            class="tab"
            :class="timeFlag == 3 ? 'active' : ''"
            @click="changeTime(3)"
          >
            {{ $t('jin-30-tian') }}
          </div>
        </div>
        <div class="echart-box" id="lineEcharts"></div>
      </div>

      <div class="menu-list">
        <div
          class="menu flex-column-center"
          v-for="(item, i) in menuList"
          :key="i"
          @click="toPath(item.path)"
        >
          <img :src="item.img" alt="" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>

      <div class="shop-info">
        <div class="info flex-center-between">
          <div class="flex-center">
            <img :src="shopInfoUrl1" alt="" />
            <span class="label">{{ $t('dian-pu-guan-zhu') }}</span>
          </div>
          <div class="value">{{ detailData.ShopFollow || 0 }}</div>
        </div>
        <div class="info flex-center-between">
          <div class="flex-center">
            <img :src="shopInfoUrl2" alt="" />
            <span class="label">{{ $t('dian-pu-ping-fen') }}</span>
          </div>
          <div class="value">{{ detailData.CompositeScore || 0 }}</div>
        </div>
        <div class="info flex-center-between">
          <div class="flex-center">
            <img :src="shopInfoUrl3" alt="" />
            <span class="label">{{ $t('mai-jia-xin-yong-fen') }}</span>
            <i class="el-icon-question"></i>
          </div>
          <div class="value">{{ detailData.Credit || 0 }}</div>
        </div>
      </div>

      <div class="order-list">
        <div class="order blue">
          <div class="label">{{ $t('zong-ding-dan-0') }}</div>
          <div class="value">{{ detailData.OrderCount || 0 }}</div>
        </div>
        <div class="order orange">
          <div class="label">{{ $t('jin-hang-zhong') }}</div>
          <div class="value">{{ detailData.Delivery || 0 }}</div>
        </div>
        <div class="order green">
          <div class="label">{{ $t('yi-wan-cheng') }}</div>
          <div class="value">{{ detailData.ConfirmReceipt || 0 }}</div>
        </div>
        <div class="order black">
          <div class="label">{{ $t('qu-xiao-ding-dan') }}</div>
          <div class="value">{{ detailData.CancelOrder || 0 }}</div>
        </div>
        <div class="order pirple">
          <div class="label">{{ $t('yi-tui-kuan') }}</div>
          <div class="value">{{ detailData.Refund || 0 }}</div>
        </div>
      </div>

      <div class="page-title">
        {{ $t('nin-de-lei-bie') }}
        ({{ categoryList.length }})
      </div>

      <div class="category-list">
        <Swipe
          ref="swipe"
          :loop="false"
          :show-indicators="false"
          :width="135"
          :height="140"
        >
          <SwipeItem v-for="(item, i) in categoryList" :key="i">
            <div class="category flex-center" @click="toCategory(item)">
              <img :src="item.Image || categoryUrl" alt="" />
              <div class="count flex-column-center">
                <span>{{
                  item.ProductCategoryTranslations
                    ? item.ProductCategoryTranslations[0].Name
                    : ''
                }}</span>
                <span>({{ item.ProductCount }})</span>
              </div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>

      <div class="page-title">{{ $t('re-xiao-shang-pin-top-10') }}</div>

      <goodsList type="seller" :list="goodsList"></goodsList>
    </div>

    <div class="kefu-box wap">
      <div class="message-icon flex-center" @click="toChat">
        <Badge :content="unreadMessageCount" v-if="unreadMessageCount > 0">
          <i
            class="el-icon-chat-dot-round"
            style="color: #fff; font-size: 24px"
          ></i>
        </Badge>
        <i
          class="el-icon-chat-dot-round"
          style="color: #fff; font-size: 24px"
          v-else
        ></i>
      </div>
      <div class="kefu-icon flex-center" @click="toKefu">
        <i class="el-icon-service" style="color: #fff; font-size: 24px"></i>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import goodsList from '@/components/goodsList/wapList.vue'
import wapShopAuth from '@/components/shopAuth/wapAuth.vue'
import { Swipe, SwipeItem, Badge } from 'vant'
import {
  shopProductList,
  shopInfo,
  indexInfo,
  orderStatistics,
  visitLog,
  getShopCategoryList
} from '@/api/shop'
import { getNoticeList, getUnreadMessageCount } from '@/api/index'
import moment from 'moment'
export default {
  name: 'about',
  components: {
    Swipe,
    SwipeItem,
    Badge,
    goodsList,
    wapShopAuth
  },
  data() {
    return {
      timeFlag: 2,
      categoryUrl: require('@/assets/imgs/category1.png'),
      moneyUrl: require('@/assets/imgs/icon-money.png'),
      profitUrl: require('@/assets/imgs/icon-profit.png'),
      dataUrl1: require('@/assets/imgs/icon-data1.png'),
      dataUrl2: require('@/assets/imgs/icon-data2.png'),
      dataUrl3: require('@/assets/imgs/icon-data3.png'),
      dataUrl4: require('@/assets/imgs/icon-data4.png'),
      dataUrl5: require('@/assets/imgs/icon-data5.png'),
      dataUrl6: require('@/assets/imgs/icon-data6.png'),
      shopInfoUrl1: require('@/assets/imgs/shop-info1.png'),
      shopInfoUrl2: require('@/assets/imgs/shop-info2.png'),
      shopInfoUrl3: require('@/assets/imgs/shop-info3.png'),
      shopInfo: {
        ShopLevel: {}
      },
      goodsList: [],
      categoryList: [],
      detailData: {},
      noticeCount: 0,
      orderDataList: [],
      logDataList: [],
      xData: [],
      isOrderInit: false,
      isLogInit: false,
      visitCountObj: {
        today: 0,
        last7: 0,
        last30: 0
      },
      unreadMessageCount: 0
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    isShopAuth() {
      return this.$store.state.isShopAuth
    },
    FirstSetup() {
      return this.$store.state.FirstSetup
    },
    menuList() {
      return [
        {
          img: require('@/assets/imgs/icon-menu1.png'),
          name: this.$t('dian-pu-she-zhi'),
          path: 'wapSetting'
        },
        {
          img: require('@/assets/imgs/icon-menu2.png'),
          name: this.$t('tui-kuan-ding-dan-0'),
          path: 'wapRefund'
        },
        {
          img: require('@/assets/imgs/icon-menu3.png'),
          name: this.$t('dian-pu-zhi-tong-che'),
          path: 'wapShopCar'
        },
        {
          img: require('@/assets/imgs/icon-menu4.png'),
          name: this.$t('chong-zhi'),
          path: 'wapRecharge'
        },
        {
          img: require('@/assets/imgs/icon-menu5.png'),
          name: this.$t('ti-xian'),
          path: 'wapWithdraw'
        },
        {
          img: require('@/assets/imgs/icon-menu6.png'),
          name: this.$t('mai-jia-deng-ji-1'),
          path: 'wapShopLevel'
        }
      ]
    }
  },
  mounted() {
    this.initOrder()
    this.initVisit()
    this.init()
    this.initCategory()
  },
  methods: {
    initCategory() {
      getShopCategoryList().then((res) => {
        this.categoryList = res.data
      })
    },
    initOrder() {
      orderStatistics({
        current: 1,
        pageSize: 30
      }).then((res) => {
        this.orderDataList = res.data || []
        this.isOrderInit = true
        if (this.isLogInit) {
          this.initEchart()
        }
      })
    },
    initVisit() {
      visitLog({
        current: 1,
        pageSize: 30
      }).then((res) => {
        this.logDataList = res.data.Items
        this.isLogInit = true
        if (this.isOrderInit) {
          this.initEchart()
        }

        this.getVisitCount()
      })
    },
    getVisitCount() {
      let sum = 0
      for (let i = 0; i < 30; i++) {
        let date = moment().subtract('days', i).format('YYYY-MM-DD')
        let item = this.logDataList.find((v) => {
          return v.Date == date
        })
        let count = item ? item.Visit : 0
        sum += count
        if (i == 0) {
          this.visitCountObj.today = sum
        } else if (i == 6) {
          this.visitCountObj.last7 = sum
        } else if (i == 29) {
          this.visitCountObj.last7 = sum
        }
      }
    },
    init() {
      //店铺信息
      shopInfo().then((res) => {
        this.shopInfo = res.data
        this.$store.state.isShopAuth = this.shopInfo.IsAuth === 1
        this.$store.state.FirstSetup = this.shopInfo.FirstSetup === 1
        // 热销商品
        shopProductList({
          current: 1,
          pageSize: 10,
          ShopID: parseFloat(this.shopInfo.ID),
          Sort: 'sales'
        }).then((res) => {
          this.goodsList = res.data.Items
        })
      })

      //统计信息
      indexInfo().then((res) => {
        this.detailData = res.data
      })

      // 消息
      getNoticeList().then((res) => {
        this.noticeCount = res.data.Pagination.totalRecords
      })

      // 获取未读消息数
      this.getUnreadMessageCount()
    },
    getXData(type) {
      let size = type == 2 ? 7 : 30
      let list = []
      for (let i = 0; i < size; i++) {
        let date = moment().subtract('days', i).format('YYYY-MM-DD')
        list.push(date)
      }
      list.reverse()
      return list
    },
    getOrderData() {
      let list = []
      this.xData.forEach((v) => {
        let item = this.orderDataList.find((val) => {
          return val.Date == v
        })
        let val = item ? item.OrderNum : 0
        list.push(val)
      })
      return list
    },
    getLogData() {
      let list = []
      this.xData.forEach((v) => {
        let item = this.logDataList.find((val) => {
          return val.Date == v
        })
        let val = item ? item.Visit : 0
        list.push(val)
      })
      return list
    },
    initEchart() {
      let dom = document.getElementById('lineEcharts')
      this.lineChart = echarts.init(dom)
      let xData = this.getXData(this.timeFlag)
      this.xData = xData
      let data2 = this.getOrderData()
      let data = this.getLogData()
      const option = {
        grid: {
          // 控制chart的上下左右间距
          left: 10,
          right: 10,
          bottom: 0,
          top: 40,
          containLabel: true
        },
        legend: {
          data: [this.$t('xiao-liang-2'), this.$t('liu-lan-0')],
          left: 0
        },
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisTick: {
            // 刻度
            show: false
          },
          axisLine: {
            // 设置轴线的颜色
            lineStyle: {
              color: '#71c3ff'
            }
          },
          axisLabel: {
            // X轴文字
            show: true,
            textStyle: {
              color: '#71c3ff',
              fontSize: 12,
              fontWeight: '400'
            },
            padding: [0, 0, 0, 0] // 设置 padding 值为 [10, 0, 0, 0]
          }
        },
        yAxis: {
          type: 'value',
          axisTick: {
            // 刻度
            show: false
          },
          axisLine: {
            // 设置轴线的颜色
            show: true,
            lineStyle: {
              color: '#71c3ff'
            }
          },
          axisLabel: {
            // y轴文字
            show: true,
            textStyle: {
              color: '#71c3ff',
              fontSize: 12,
              fontWeight: '400',
              fontFamily: 'Source Han Sans CN-Regular'
            },
            padding: [0, 0, 0, 0] // 设置 padding 值为 [10, 0, 0, 0]
          }
        },
        series: [
          {
            name: this.$t('liu-lan-0'),
            data: data,
            type: 'line',
            color: '#999'
          },
          {
            name: this.$t('xiao-liang-2'),
            data: data2,
            type: 'line',
            color: '#71c3ff'
          }
        ]
      }
      this.lineChart.setOption(option)
    },
    changeTime(type) {
      this.timeFlag = type
      this.initEchart()
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    toKefu() {
      this.$router.push({
        name: 'wapKf'
      })
    },
    toChat() {
      this.$router.push({
        name: 'wapMessage'
      })
    },
    toCategory(data) {
      this.$router.push({
        name: 'wapProduct',
        query: {
          category: data.ID
        }
      })
    },
    getUnreadMessageCount() {
      getUnreadMessageCount().then(res => {
        this.unreadMessageCount = res.data
      })
    }
  }
}
</script>
<style lang="less">
</style>
