<template>
  <div class="pc-container">
    <el-container style="height: 100%">
      <el-container class="el-menu-wrap">
        <el-aside width="200PX">
          <aside-menu></aside-menu>
        </el-aside>
        <el-container direction="vertical" class="page-main">
          <pageHeader />
          <tabsbar />
          <el-main>
            <shop-auth v-if="!isShopAuth"></shop-auth>
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
    <div class="kefu-box">
      <div class="message-icon flex-center" @click="showChat">
        <i
          class="el-icon-chat-dot-round"
          style="color: #fff; font-size: 24px"
        ></i>
      </div>
      <div class="kefu-icon flex-center" @click="toKefu">
        <i class="el-icon-service" style="color: #fff; font-size: 24px"></i>
      </div>
    </div>

    <div class="other-kefu-box" v-if="showOtherKefu">
      <div @click="hideModal" class="action">
        <i class="el-icon-close"></i>
      </div>
      <iframe src="/#/wap/kf" frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>
import pageHeader from '@/components/pageHeader'
import tabsbar from '@/components/pageTabsBar'
import asideMenu from '@/components/aside-menu'
import shopAuth from '@/components/shopAuth'
export default {
  name: 'home',
  components: {
    pageHeader,
    tabsbar,
    shopAuth,
    asideMenu
  },
  computed: {
    isShopAuth() {
      return this.$store.state.isShopAuth
    },
    sellerPcRoutes() {
      return [
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            type: 'seller',
            title: this.$t('yi-biao-pan'),
            key: 'yi-biao-pan',
            icon: 'el-icon-house'
          },
          component: () => import('@/views/seller/index/index')
        },
        {
          path: '/shopOrder',
          name: 'shopOrder',
          meta: {
            type: 'seller',
            title: this.$t('dian-pu-ding-dan'),
            key: 'dian-pu-ding-dan',
            icon: 'el-icon-s-order'
          },
          component: () => import('@/views/seller/shop/shopOrder')
        },
        {
          path: '/shopReport',
          name: 'shopReport',
          meta: {
            type: 'seller',
            title: this.$t('cai-wu-bao-biao-0'),
            key: 'cai-wu-bao-biao-0',
            icon: 'el-icon-data-line'
          },
          component: () => import('@/views/seller/shop/shopReport')
        },
        {
          path: '/myWallet',
          name: 'myWallet',
          meta: {
            type: 'seller',
            title: this.$t('wo-de-qian-bao'),
            key: 'wo-de-qian-bao',
            icon: 'el-icon-wallet'
          },
          component: () => import('@/views/seller/mine/wallet')
        },
        {
          path: '/recharge',
          name: 'recharge',
          meta: {
            type: 'seller',
            title: this.$t('chong-zhi-0'),
            key: 'chong-zhi-0',
            hide: true
          },
          component: () => import('@/views/seller/mine/recharge')
        },
        {
          path: '/withdraw',
          name: 'withdraw',
          meta: {
            type: 'seller',
            title: this.$t('ti-xian-0'),
            key: 'ti-xian-0',
            hide: true
          },
          component: () => import('@/views/seller/mine/withdraw')
        },
        {
          path: '/shopRebet',
          name: 'shopRebet',
          meta: {
            type: 'seller',
            title: this.$t('zi-jin-ji-lu'),
            key: 'zi-jin-ji-lu',
            icon: 'el-icon-bank-card'
          },
          component: () => import('@/views/seller/shop/shopRebet')
        },
        {
          path: '/productsManage',
          name: 'productsManage',
          meta: {
            type: 'seller',
            title: this.$t('shang-pin-guan-li'),
            key: 'shang-pin-guan-li',
            icon: 'el-icon-shopping-bag-1'
          },
          component: () => import('@/views/blank'),
          children: [
            {
              path: '/shopProducts',
              name: 'shopProducts',
              meta: {
                type: 'seller',
                title: this.$t('dian-pu-shang-pin'),
                key: 'dian-pu-shang-pin',
                icon: 'el-icon-shopping-bag-2'
              },
              component: () => import('@/views/seller/shop/shopProduct')
            },
            {
              path: '/shopRefund',
              name: 'shopRefund',
              meta: {
                type: 'seller',
                title: this.$t('tui-kuan-qing-qiu'),
                key: 'tui-kuan-qing-qiu',
                icon: 'el-icon-warning'
              },
              component: () => import('@/views/seller/shop/shopRefund')
            },
            {
              path: '/productEvalute',
              name: 'productEvalute',
              meta: {
                type: 'seller',
                title: this.$t('shang-pin-ping-lun'),
                key: 'shang-pin-ping-lun',
                icon: 'el-icon-chat-dot-square'
              },
              component: () => import('@/views/seller/shop/productEvalute')
            },
            {
              path: '/productLibrary',
              name: 'productLibrary',
              meta: {
                type: 'seller',
                title: this.$t('shang-pin-ku-0'),
                key: 'shang-pin-ku-0',
                icon: 'el-icon-s-home'
              },
              component: () => import('@/views/seller/shop/productLibrary')
            }
          ]
        },
        {
          path: '/shopSetting',
          name: 'shopSetting',
          meta: {
            type: 'seller',
            title: this.$t('dian-pu-she-zhi-0'),
            key: 'dian-pu-she-zhi-0',
            icon: 'el-icon-s-tools'
          },
          component: () => import('@/views/seller/shop/shopSetting')
        },
        {
          path: '/saleManage',
          name: 'saleManage',
          meta: {
            type: 'seller',
            title: this.$t('ying-xiao-gong-ju'),
            key: 'ying-xiao-gong-ju',
            icon: 'el-icon-s-data'
          },
          component: () => import('@/views/blank'),
          children: [
            {
              path: '/shopCar',
              name: 'shopCar',
              meta: {
                type: 'seller',
                title: this.$t('dian-pu-zhi-tong-che-0'),
                key: 'dian-pu-zhi-tong-che-0',
                icon: 'el-icon-truck'
              },
              component: () => import('@/views/seller/shop/shopCar')
            },
            {
              path: '/buyHistory',
              name: 'buyHistory',
              meta: {
                type: 'seller',
                title: this.$t('gou-mai-li-shi'),
                key: 'gou-mai-li-shi',
                icon: 'el-icon-time'
              },
              component: () => import('@/views/seller/shop/buyHistory')
            },
            {
              path: '/shopLevel',
              name: 'shopLevel',
              meta: {
                type: 'seller',
                title: this.$t('mai-jia-deng-ji-1'),
                key: 'mai-jia-deng-ji-1',
                icon: 'el-icon-upload2'
              },
              component: () => import('@/views/seller/shop/shopLevel')
            }
          ]
        }
      ]
    }
  },
  data() {
    return {
      transitionName: '',
      showOtherKefu: false
    }
  },
  watch: {
    $route() {
      this.initRoute()
    }
  },
  mounted() {
    this.initRoute()
  },
  methods: {
    initRoute() {
      let route = this.sellerPcRoutes.find(
        (item) => item.name == this.$route.name
      )
      if (route) {
        this.$store.dispatch('addTabs', route)
      }
      this.$store.state.activeRoute = this.$route.name
    },
    showChat() {
      // this.$store.state.showChat = true
      this.$store.state.showMessageBox = true
    },
    hideModal() {
      this.showOtherKefu = false
    },
    toKefu() {
      this.showOtherKefu = true
      return
      let chatContainer = document.createElement('div')
      chatContainer.style.cssText = `
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 500px;
        height: 680px;
        z-index: 9999;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        overflow: hidden;
        background: #fff;
      `

      let chatFrame = document.createElement('iframe')
      chatFrame.src = '/#/wap/kf'
      chatFrame.style.cssText = `
        width: 500px;
        height: 640px;
        border: none;
        transform-origin: top left;
        overflow: hidden;
      `

      let titleBar = document.createElement('div')
      titleBar.innerHTML = this.$t('zai-xian-ke-fu')
      titleBar.style.cssText = `
        padding: 10px;
        background: #f5f5f5;
        border-bottom: 1px solid #eee;
        font-size: 14px;
      `

      let closeBtn = document.createElement('div')
      closeBtn.innerHTML = '×'
      closeBtn.style.cssText = `
        position: absolute;
        right: 10px;
        top: 8px;
        cursor: pointer;
        font-size: 20px;
        color: #666;
      `
      closeBtn.onclick = () => {
        document.body.removeChild(chatContainer)
      }

      chatContainer.appendChild(titleBar)
      chatContainer.appendChild(chatFrame)
      chatContainer.appendChild(closeBtn)
      document.body.appendChild(chatContainer)
    }
  }
}
</script>