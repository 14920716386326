<template>
  <div class="wap-product-detail">
    <headerBar :title="$t('ding-dan-xiang-qing-0')" @back="back"></headerBar>

    <div class="detail-box">
      <div class="flex-center-between line">
        <div class="label">{{ $t('ding-dan-hao-0') }}</div>
        <div class="value flex-center">
          {{ detailData.OrderNo }}
          <i class="el-icon-copy-document"></i>
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('xia-dan-shi-jian-1') }}</div>
        <div class="value flex-center">
          {{ detailData.CreatedAt }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('fu-kuan-fang-shi') }}</div>
        <div class="value flex-center">{{ $t('qian-bao') }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('zhi-fu-zhuang-tai-1') }}</div>
        <div class="value flex-center">
          {{ getPaymentStatus(detailData.Status) }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('wu-liu-zhuang-tai-1') }}</div>
        <div class="value flex-center">
          {{ getLogisticsStatus(detailData.Status) }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('cai-gou-jin-e') }}</div>
        <div class="value flex-center">${{ detailData.PickupAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('xiao-shou-jin-e') }}</div>
        <div class="value flex-center">${{ detailData.TotalAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('xing-ming-0') }}</div>
        <div class="value flex-center">
          {{ detailData.ShippingAddress.FullName }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shou-ji-hao') }}</div>
        <div class="value flex-center">
          {{ detailData.ShippingAddress.Phone }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('guo-jia') }}</div>
        <div class="value flex-center">
          {{ detailData.ShippingAddress.Country }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('sheng-fen') }}</div>
        <div class="value flex-center">
          {{ detailData.ShippingAddress.Province }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('cheng-shi') }}</div>
        <div class="value flex-center">
          {{ detailData.ShippingAddress.City }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('you-bian') }}</div>
        <div class="value flex-center">
          {{ detailData.ShippingAddress.ZipCode }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shou-huo-di-zhi') }}</div>
        <div class="value flex-center">
          {{ detailData.ShippingAddress.Address }}
        </div>
      </div>
    </div>

    <div
      class="detail-box"
      v-for="(good, i) in detailData.OrderProduct"
      :key="i"
    >
      <div class="title flex-center-between">
        <div class="label">{{ $t('chan-pin-bian-hao') }}</div>
        <div class="value flex-center">
          {{ good.BusinessProduct.ID }}<i class="el-icon-copy-document"></i>
        </div>
      </div>
      <div class="top-info flex">
        <div class="img">
          <img :src="good.BusinessProduct.MainImage" alt="" />
        </div>
        <div class="flex-1 info">
          <div class="name">
            {{
              good.BusinessProduct.ProductTranslation
                ? good.BusinessProduct.ProductTranslation[0].Name
                : ''
            }}
          </div>
        </div>
      </div>

      <div class="flex-center-between line">
        <div class="label">{{ $t('xiao-shou-jia') }}</div>
        <div class="value blue">${{ good.Price }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('cai-gou-jia') }}</div>
        <div class="value blue">${{ good.PickupAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('li-run-0') }}</div>
        <div class="value blue">${{ good.Income }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shu-liang') }}</div>
        <div class="value blue">{{ good.Number }}</div>
      </div>
    </div>

    <div class="detail-box">
      <div class="flex-center-between line">
        <div class="label">{{ $t('xiao-ji') }}</div>
        <div class="value">${{ detailData.TotalAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shui') }}</div>
        <div class="value">$0.00</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('yun-fei') }}</div>
        <div class="value">$0.00</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('he-ji') }}</div>
        <div class="value blue">${{ detailData.TotalAmount }}</div>
      </div>
    </div>

    <div class="link-box">
      <div class="btn" @click="toPath('wapShipping')">
        <img :src="carUrl" alt="" />
      </div>
      <div class="btn">
        <img :src="messageUrl" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import { shopProductList } from '@/api/shop'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      carUrl: require('@/assets/imgs/icon-car.png'),
      messageUrl: require('@/assets/imgs/icon-message.png'),
      dataId: '',
      detailData: {
        ShippingAddress: {},
        OrderProduct: []
      },
      logistics: {},
      logisticsList: []
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      let data = sessionStorage.getItem('orderDetail')
      if (data) {
        this.detailData = JSON.parse(data)
        console.log(111, this.detailData)
      }
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    },
    getPaymentStatus(status) {
      switch (status + '') {
        case '0':
          return this.$t('wei-zhi-fu')
        case '1':
        case '2':
        case '3':
        case '4':
        case '9':
          return this.$t('yi-zhi-fu')
        case '5':
        case '6':
          return this.$t('yi-qu-xiao')
        case '7':
        case '8':
          return this.$t('tui-kuan')
        default:
          return ''
      }
    },
    getPurchaseStatus(status) {
      switch (status + '') {
        case '0':
          return this.$t('wei-cai-gou')
        case '1':
          return this.$t('dai-cai-gou')
        case '2':
        case '3':
        case '4':
        case '9':
          return this.$t('yi-cai-gou')
        default:
          return this.$t('wu-xu-cai-gou')
      }
    },
    getLogisticsStatus(status) {
      switch (status + '') {
        case '0':
        case '1':
          return this.$t('wei-fa-huo')
        case '2':
          return this.$t('yi-fa-huo')
        case '3':
        case '4':
        case '9':
          return this.$t('yi-shou-huo')
        case '7':
          return this.$t('tui-huo-zhong')
        default:
          return this.$t('wu-wu-liu')
      }
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    }
  }
}
</script>