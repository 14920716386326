import Vue from 'vue'
import Vuex from 'vuex'
import { getCategoryList } from '@/api/shop'
Vue.use(Vuex)

const resetCategory = (list, parentId, lang) => {
  let treeData = []
  list.forEach((v) => {
    if (v.ParentID == parentId) {
      let otherList = list.filter((val) => {
        return val.ParentID != parentId
      })
      let children = resetCategory(otherList, v.ID, lang)
      if (children.length > 0) {
        v.children = children
      }
      let data = v.ProductCategoryTranslations.find(
        (item) => item.Language == lang
      )
      treeData.push({
        ...v,
        title: data ? data.Name : ''
      })
    }
  })
  return treeData
}

export default new Vuex.Store({
  state: {
    baseUrl: '/upload/',
    uploadUrl: '/api/user/upload',
    appName: '',
    shopUserName: '', // 卖家用户名
    avatar: 1,
    shareCode: '', //邀请码
    isLogin: false,
    showChat: false, //客服弹框
    showMessageBox: false, //消息弹框
    messageUid: '',
    messageTitle: '',
    goodsId: '',
    chatType: '', // 客服弹框样式
    initCar: false, //刷新购物车
    isShopAuth: true, // 店铺是否认证
    FirstSetup: false, // 店铺是否首次设置
    hideCar: false,
    pageType: 'pc',
    userType: '', //登录者身份标识，2-买家，1-商家
    username: '',
    shopId: '',
    carSize: 0, // 购物车数量
    ProfitRate: 10, // 商品商品折扣比例最大值
    tabList: [],
    categoryList: [], // 分类数据
    activeRoute: '',
    logo: '',
    configData: {},
    language: '',
    langName: 'English',
    lang: 'en',
    langIcon: require('@/assets/imgs/lang/en.png'),
    imgType: ['jpg', 'png', 'jpeg', 'gif', 'webp', 'bmp', 'svg', 'tif'],
    langListBak: [
      {
        name: 'English',
        key: 'en',
        icon: require('@/assets/imgs/lang/en.png'),
      },
        // 哈萨克斯坦语
      {
        name: 'Русский',
        key: 'kk',
        icon: require('@/assets/imgs/lang/kk.png'),
      },
      //   西班牙语
      {
        name: 'Español',
        key: 'es',
        icon: require('@/assets/imgs/lang/es.png'),
      },
        // 法语
      {
        name: 'Français',
        key: 'fr',
        icon: require('@/assets/imgs/lang/fr.png'),
      },
        // 德语
      {
        name: 'Deutsch',
        key: 'de',
        icon: require('@/assets/imgs/lang/de.png'),
      },
        // 意大利语
      {
        name: 'Italiano',
        key: 'it',
        icon: require('@/assets/imgs/lang/it.png'),
      },
        // 葡萄牙语
      {
        name: 'Português',
        key: 'pt',
        icon: require('@/assets/imgs/lang/pt.png'),
      },
        // 俄语
      {
        name: 'Pусский',
        key: 'ru',
        icon: require('@/assets/imgs/lang/ru.png'),
      },
      //   土耳其语
      {
        name: 'Türkçe',
        key: 'tr',
        icon: require('@/assets/imgs/lang/tr.png'),
      },
        // 韩语
      {
        name: '한국어',
        key: 'ko',
        icon: require('@/assets/imgs/lang/ko.png'),
      },
        // 日语
      {
        name: '日本語',
        key: 'ja',
        icon: require('@/assets/imgs/lang/ja.png'),
      },
        // 越南语
      {
        name: 'Tiếng Việt',
        key: 'vi',
        icon: require('@/assets/imgs/lang/vi.png'),
      },
        // 印尼语
      {
        name: 'Bahasa Indonesia',
        key: 'id',
        icon: require('@/assets/imgs/lang/id.png'),
      },
        // 马来语
      {
        name: 'Bahasa Melayu',
        key: 'ms',
        icon: require('@/assets/imgs/lang/ms.jpeg'),
      },
        // 泰语
      {
        name: 'ภาษาไทย',
        key: 'th',
        icon: require('@/assets/imgs/lang/th.png'),
      },
        // 阿拉伯语
      {
        name: 'العربية',
        key: 'ar',
        icon: require('@/assets/imgs/lang/ar.png'),
      },
        // 希伯来语
      {
        name: 'עברית',
        key: 'he',
        icon: require('@/assets/imgs/lang/he.png'),
      },
        // 印地语
      {
        name: 'हिन्दी',
        key: 'hi',
        icon: require('@/assets/imgs/lang/hi.png'),
      },
      {
        name: '简体中文',
        key: 'zh-CN',
        icon: require('@/assets/imgs/lang/zh.png'),
      },
      {
        name: '繁体中文',
        key: 'zh-TW',
        icon: require('@/assets/imgs/lang/zh.png'),
      },
    ],
    langList: [],
    countryList: [
      {
         title: 'A',
         id: 'A',
         children: [
             {
                 icon: require('../assets/imgs/country/af.png'),
                 title: 'Afghanistan (AF) +93',
                 key: '+93'
             },
             {
                 icon: require('../assets/imgs/country/al.png'),
                 title: 'Albania (AL) +355',
                 key: '+355'
             },
             {
                 icon: require('../assets/imgs/country/dz.png'),
                 title: 'Algeria (DZ) +213',
                 key: '+213'
             },
             {
                 icon: require('../assets/imgs/country/ad.png'),
                 title: 'Andorra (AD) +376',
                 key: '+376'
             },
             {
                 icon: require('../assets/imgs/country/ao.png'),
                 title: 'Angola (AO) +244',
                 key: '+244'
             },
             {
                 icon: require('../assets/imgs/country/ai.png'),
                 title: 'Anguilla (AI) +1264',
                 key: '+1264'
             },
             // {
             //     icon: require('../assets/imgs/country/ag.png'),
             //     title: 'AntiguaandBarbuda (AG) +1268',
             //     key: '+1268'
             // },
             {
                 icon: require('../assets/imgs/country/ar.png'),
                 title: 'Argentina (AR) +54',
                 key: '+54'
             },
             {
                 icon: require('../assets/imgs/country/am.png'),
                 title: 'Armenia (AM) +374',
                 key: '+374'
             },
             {
                 icon: require('../assets/imgs/country/sh.png'),
                 title: 'Ascension (SH) +247',
                 key: '+247'
             },
             {
                 icon: require('../assets/imgs/country/au.png'),
                 title: 'Australia (AU) +61',
                 key: '+61'
             },
             {
                 icon: require('../assets/imgs/country/at.png'),
                 title: 'Austria (AT) +43',
                 key: '+43'
             },
             {
                 icon: require('../assets/imgs/country/az.png'),
                 title: 'Azerbaijan (AZ) +994',
                 key: '+994'
             },
         ]
			},
			{
					title: 'B',
					id: 'B',
					children: [
							{
									icon: require('../assets/imgs/country/bh.png'),
									title: 'Bahrain (BH) +973',
									key: '+973'
							},
							{
									icon: require('../assets/imgs/country/bd.png'),
									title: 'Bangladesh (BD) +880',
									key: '+880'
							},
							{
									icon: require('../assets/imgs/country/bb.png'),
									title: 'Barbados (BB) +1246',
									key: '+1246'
							},
							{
									icon: require('../assets/imgs/country/by.png'),
									title: 'Belarus (BY) +375',
									key: '+375'
							},
							{
									icon: require('../assets/imgs/country/be.png'),
									title: 'Belgium (BE) +32',
									key: '+32'
							},
							{
									icon: require('../assets/imgs/country/bz.png'),
									title: 'Belize (BZ) +501',
									key: '+501'
							},
							{
									icon: require('../assets/imgs/country/bj.png'),
									title: 'Benin (BJ) +229',
									key: '+229'
							},
							{
									icon: require('../assets/imgs/country/bm.png'),
									title: 'BermudaIs. (BM) +1441',
									key: '+1441'
							},
							{
									icon: require('../assets/imgs/country/bo.png'),
									title: 'Bolivia (BO) +591',
									key: '+591'
							},
							{
									icon: require('../assets/imgs/country/bw.png'),
									title: 'Botswana (BW) +267',
									key: '+267'
							},
							{
									icon: require('../assets/imgs/country/br.png'),
									title: 'Brazil (BR) +55',
									key: '+55'
							},
							{
									icon: require('../assets/imgs/country/bn.png'),
									title: 'Brunei (BN) +673',
									key: '+673'
							},
							{
									icon: require('../assets/imgs/country/bg.png'),
									title: 'Bulgaria (BG) +359',
									key: '+359'
							},
							{
									icon: require('../assets/imgs/country/bf.png'),
									title: 'Burkina Faso (BF) +226',
									key: '+226'
							},
							{
									icon: require('../assets/imgs/country/mm.png'),
									title: 'Burma (MM) +95',
									key: '+95'
							},
							{
									icon: require('../assets/imgs/country/bi.png'),
									title: 'Burundi (BI) +257',
									key: '+257'
							},
					]
			},
			{
         title: 'C',
         id: 'C',
         children: [
             {
                 icon: require('../assets/imgs/country/cm.png'),
                 title: 'Cameroon (CM) +237',
                 key: '+237'
             },
             {
                 icon: require('../assets/imgs/country/ca.png'),
                 title: 'Canada (CA) +1',
                 key: '+1'
             },
             // {
             //     icon: '',
             //     title: 'CaymanIs. (CI) +1345',
             //     key: '+1345'
             // },
             {
                 icon: require('../assets/imgs/country/cf.png'),
                 title: 'Central African Republic (CF) +236',
                 key: '+236'
             },
             {
                 icon: require('../assets/imgs/country/td.png'),
                 title: 'Chad (TD) +235',
                 key: '+235'
             },
             {
                 icon: require('../assets/imgs/country/cl.png'),
                 title: 'Chile (CL) +56',
                 key: '+56'
             },
             {
                 icon: require('../assets/imgs/country/cn.png'),
                 title: 'China (CN) +86',
                 key: '+86'
             },
             {
                 icon: require('../assets/imgs/country/co.png'),
                 title: 'Colombia (CO) +57',
                 key: '+57'
             },
             {
                 icon: require('../assets/imgs/country/cg.png'),
                 title: 'Congo (CG) +242',
                 key: '+242'
             },
             {
                 icon: require('../assets/imgs/country/ck.png'),
                 title: 'CookIs. (CK) +682',
                 key: '+682'
             },
             {
                 icon: require('../assets/imgs/country/cr.png'),
                 title: 'CostaRica (CR) +506',
                 key: '+506'
             },
             {
                 icon: require('../assets/imgs/country/cu.png'),
                 title: 'Cuba (CU) +53',
                 key: '+53'
             },
             {
                 icon: require('../assets/imgs/country/cy.png'),
                 title: 'Cyprus (CY) +357',
                 key: '+357'
             },
             {
                 icon: require('../assets/imgs/country/cz.png'),
                 title: 'CzechRepublic (CZ) +420',
                 key: '+420'
             },
         ]
     },
     {
         title: 'D',
         id: 'D',
         children: [
             {
                 icon: require('../assets/imgs/country/dk.png'),
                 title: 'Denmark (DK) +45',
                 key: '+45'
             },
             {
                 icon: require('../assets/imgs/country/dj.png'),
                 title: 'Djibouti (DJ) +253',
                 key: '+253'
             },
             {
                 icon: require('../assets/imgs/country/do.png'),
                 title: 'DominicaRep. (DO) +1890',
                 key: '+1890'
             },
         ]
     },
     {
         title: 'E',
         id: 'E',
         children: [
             {
                 icon: require('../assets/imgs/country/sv.png'),
                 title: 'EISalvador (SV) +503',
                 key: '+503'
             },
             {
                 icon: require('../assets/imgs/country/ec.png'),
                 title: 'Ecuador (EC) +593',
                 key: '+593'
             },
             {
                 icon: require('../assets/imgs/country/eg.png'),
                 title: 'Egypt (EG) +20',
                 key: '+20'
             },
             {
                 icon: require('../assets/imgs/country/ee.png'),
                 title: 'Estonia (EE) +372',
                 key: '+372'
             },
             {
                 icon: require('../assets/imgs/country/et.png'),
                 title: 'Ethiopia (ET) +251',
                 key: '+251'
             },
         ]
     },
     {
         title: 'F',
         id: 'F',
         children: [
             {
                 icon: require('../assets/imgs/country/fj.png'),
                 title: 'Fiji (FJ) +679',
                 key: '+679'
             },
             {
                 icon: require('../assets/imgs/country/fi.png'),
                 title: 'Finland (FI) +358',
                 key: '+358'
             },
             {
                 icon: require('../assets/imgs/country/fr.png'),
                 title: 'France (FR) +33',
                 key: '+33'
             },
             {
                 icon: require('../assets/imgs/country/gf.png'),
                 title: 'FrenchGuiana (GF) +594',
                 key: '+594'
             },
             {
                 icon: require('../assets/imgs/country/pf.png'),
                 title: 'FrenchPolynesia (PF) +689',
                 key: '+689'
             },
         ]
     },
     {
         title: 'G',
         id: 'G',
         children: [
             {
                 icon: require('../assets/imgs/country/ga.png'),
                 title: 'Gabon (GA) +241',
                 key: '+241'
             },
             {
                 icon: require('../assets/imgs/country/gm.png'),
                 title: 'Gambia (GM) +220',
                 key: '+220'
             },
             {
                 icon: require('../assets/imgs/country/ge.png'),
                 title: 'Georgia (GE) +995',
                 key: '+995'
             },
             {
                 icon: require('../assets/imgs/country/de.png'),
                 title: 'Germany (DE) +49',
                 key: '+49'
             },
             {
                 icon: require('../assets/imgs/country/gh.png'),
                 title: 'Ghana (GH) +233',
                 key: '+233'
             },
             {
                 icon: require('../assets/imgs/country/gi.png'),
                 title: 'Gibraltar (GI) +350',
                 key: '+350'
             },
             {
                 icon: require('../assets/imgs/country/gr.png'),
                 title: 'Greece (GR) +30',
                 key: '+30'
             },
             // {
             //     icon: require('../assets/imgs/country/gd.png'),
             //     title: 'Grenada (GD) +1809',
             //     key: '+1809'
             // },
             {
                 icon: require('../assets/imgs/country/gu.png'),
                 title: 'Guam (GU) +1671',
                 key: '+1671'
             },
             {
                 icon: require('../assets/imgs/country/gt.png'),
                 title: 'Guatemala (GT) +502',
                 key: '+502'
             },
             {
                 icon: require('../assets/imgs/country/gn.png'),
                 title: 'Guinea (GN) +224',
                 key: '+224'
             },
             {
                 icon: require('../assets/imgs/country/gy.png'),
                 title: 'Guyana (GY) +592',
                 key: '+592'
             },
         ]
     },
     {
         title: 'H',
         id: 'H',
         children: [
             {
                 icon: require('../assets/imgs/country/ht.png'),
                 title: 'Haiti (HT) +509',
                 key: '+509'
             },
             {
                 icon: require('../assets/imgs/country/hn.png'),
                 title: 'Honduras (HN) +504',
                 key: '+504'
             },
             {
                 icon: require('../assets/imgs/country/hk.png'),
                 title: 'Hongkong (HK) +852',
                 key: '+852'
             },
             {
                 icon: require('../assets/imgs/country/hu.png'),
                 title: 'Hungary (HU) +36',
                 key: '+36'
             },
         ]
     },
     {
         title: 'I',
         id: 'I',
         children: [
             {
                 icon: require('../assets/imgs/country/is.png'),
                 title: 'Iceland (IS) +354',
                 key: '+354'
             },
             {
                 icon: require('../assets/imgs/country/in.png'),
                 title: 'India (IN) +91',
                 key: '+91'
             },
             {
                 icon: require('../assets/imgs/country/id.png'),
                 title: 'Indonesia (ID) +62',
                 key: '+62'
             },
             {
                 icon: require('../assets/imgs/country/ir.png'),
                 title: 'Iran (IR) +98',
                 key: '+98'
             },
             {
                 icon: require('../assets/imgs/country/ie.png'),
                 title: 'Ireland (IE) +353',
                 key: '+353'
             },
             {
                 icon: require('../assets/imgs/country/il.png'),
                 title: 'Israel (IL) +972',
                 key: '+972'
             },
             {
                 icon: require('../assets/imgs/country/it.png'),
                 title: 'Italy (IT) +39',
                 key: '+39'
             },

         ]
     },
     {
         title: 'J',
         id: 'J',
         children: [
             {
                 icon: require('../assets/imgs/country/jm.png'),
                 title: 'Jamaica (JM) +1876',
                 key: '+1876'
             },
             {
                 icon: require('../assets/imgs/country/jp.png'),
                 title: 'Japan (JP) +81',
                 key: '+81'
             },
             {
                 icon: require('../assets/imgs/country/jo.png'),
                 title: 'Jordan (JO) +962',
                 key: '+962'
             },

         ]
     },
     {
         title: 'K',
         id: 'K',
         children: [
             {
                 icon: require('../assets/imgs/country/kh.png'),
                 title: 'Kampuchea(Cambodia) (KH) +855',
                 key: '+855'
             },
             {
                 icon: require('../assets/imgs/country/kz.png'),
                 title: 'Kazakstan (KZ) +7',
                 key: '+7'
             },
             {
                 icon: require('../assets/imgs/country/ke.png'),
                 title: 'Kenya (KE) +254',
                 key: '+254'
             },
             {
                 icon: require('../assets/imgs/country/kr.png'),
                 title: 'Korea (KR) +82',
                 key: '+82'
             },
             {
                 icon: require('../assets/imgs/country/kw.png'),
                 title: 'Kuwait (KW) +965',
                 key: '+965'
             },
             {
                 icon: require('../assets/imgs/country/kg.png'),
                 title: 'Kyrgyzstan (KG) +996',
                 key: '+996'
             },
         ]
     },
     {
         title: 'L',
         id: 'L',
         children: [
             {
                 icon: require('../assets/imgs/country/la.png'),
                 title: 'Laos (LA) +856',
                 key: '+856'
             },
             {
                 icon: require('../assets/imgs/country/lv.png'),
                 title: 'Latvia (LV) +371',
                 key: '+371'
             },
             {
                 icon: require('../assets/imgs/country/lb.png'),
                 title: 'Lebanon (LB) +961',
                 key: '+961'
             },
             {
                 icon: require('../assets/imgs/country/lr.png'),
                 title: 'Liberia (LR) +231',
                 key: '+231'
             },
             {
                 icon: require('../assets/imgs/country/ls.png'),
                 title: 'Lesotho (LS) +266',
                 key: '+266'
             },
             {
                 icon: require('../assets/imgs/country/ly.png'),
                 title: 'Libya (LY) +218',
                 key: '+218'
             },
             {
                 icon: require('../assets/imgs/country/li.png'),
                 title: 'Liechtenstein (LI) +423',
                 key: '+423'
             },
             {
                 icon: require('../assets/imgs/country/lt.png'),
                 title: 'Lithuania (LT) +370',
                 key: '+370'
             },
             {
                 icon: require('../assets/imgs/country/lu.png'),
                 title: 'Luxembourg (LU) +352',
                 key: '+352'
             },
         ]
     },
     {
         title: 'M',
         id: 'M',
         children: [
             {
                 icon: require('../assets/imgs/country/mo.png'),
                 title: 'Macao (MO) +853',
                 key: '+853'
             },
             {
                 icon: require('../assets/imgs/country/mg.png'),
                 title: 'Madagascar (MG) +261',
                 key: '+261'
             },
             {
                 icon: require('../assets/imgs/country/mw.png'),
                 title: 'Malawi (MW) +265',
                 key: '+265'
             },
             {
                 icon: require('../assets/imgs/country/my.png'),
                 title: 'Malaysia (MY) +60',
                 key: '+60'
             },
             {
                 icon: require('../assets/imgs/country/mv.png'),
                 title: 'Maldives (MV) +960',
                 key: '+960'
             },
             {
                 icon: require('../assets/imgs/country/ml.png'),
                 title: 'Mali (ML) +223',
                 key: '+223'
             },
             {
                 icon: require('../assets/imgs/country/mt.png'),
                 title: 'Malta (MT) +356',
                 key: '+356'
             },
             {
                 icon: '',
                 title: 'MarianaIs (MP) +1670',
                 key: '+1670'
             },
             {
                 icon: '',
                 title: 'Martinique (MQ) +596',
                 key: '+596'
             },
             {
                 icon: require('../assets/imgs/country/mu.png'),
                 title: 'Mauritius (MU) +230',
                 key: '+230'
             },
             {
                 icon: require('../assets/imgs/country/mx.png'),
                 title: 'Mexico (MX) +52',
                 key: '+52'
             },
             {
                 icon: require('../assets/imgs/country/md.png'),
                 title: 'Moldova,Republicof (MD) +373',
                 key: '+373'
             },
             {
                 icon: require('../assets/imgs/country/mc.png'),
                 title: 'Monaco (MC) +377',
                 key: '+377'
             },
             {
                 icon: require('../assets/imgs/country/mn.png'),
                 title: 'Mongolia (MN) +976',
                 key: '+976'
             },
             {
                 icon: require('../assets/imgs/country/ms.png'),
                 title: 'MontserratIs (MS) +1664',
                 key: '+1664'
             },
             {
                 icon: require('../assets/imgs/country/ma.png'),
                 title: 'Morocco (MA) +212',
                 key: '+212'
             },
             {
                 icon: require('../assets/imgs/country/mz.png'),
                 title: 'Mozambique (MZ) +258',
                 key: '+258'
             },
         ]
     },
     {
         title: 'N',
         id: 'N',
         children: [
             {
                 icon: require('../assets/imgs/country/na.png'),
                 title: 'Namibia (NA) +264',
                 key: '+264'
             },
             {
                 icon: require('../assets/imgs/country/nr.png'),
                 title: 'Nauru (NR) +674',
                 key: '+674'
             },
             {
                 icon: require('../assets/imgs/country/np.png'),
                 title: 'Nepal (NP) +977',
                 key: '+977'
             },
             {
                 icon: require('../assets/imgs/country/nl.png'),
                 title: 'Netherlands (NL) +31',
                 key: '+31'
             },
             {
                 icon: require('../assets/imgs/country/nz.png'),
                 title: 'NewZealand (NZ) +64',
                 key: '+64'
             },
             {
                 icon: require('../assets/imgs/country/ni.png'),
                 title: 'Nicaragua (NI) +505',
                 key: '+505'
             },
             {
                 icon: require('../assets/imgs/country/ng.png'),
                 title: 'Nigeria (NG) +234',
                 key: '+234'
             },
             {
                 icon: require('../assets/imgs/country/kp.png'),
                 title: 'NorthKorea (KP) +850',
                 key: '+850'
             },
             {
                 icon: require('../assets/imgs/country/no.png'),
                 title: 'Norway (NO) +47',
                 key: '+47'
             },
         ]
     },
     {
         title: 'O',
         id: 'O',
         children: [
             {
                 icon: require('../assets/imgs/country/om.png'),
                 title: 'Oman (OM) +968',
                 key: '+968'
             },
         ]
     },
     {
         title: 'P',
         id: 'P',
         children: [
             {
                 icon: require('../assets/imgs/country/pk.png'),
                 title: 'Pakistan (PK) +92',
                 key: '+92'
             },
             {
                 icon: require('../assets/imgs/country/bl.png'),
                 title: 'Palestine (BL) +970',
                 key: '+970'
             },
             {
                 icon: require('../assets/imgs/country/pa.png'),
                 title: 'Panama (PA) +507',
                 key: '+507'
             },
             {
                 icon: require('../assets/imgs/country/pg.png'),
                 title: 'PapuaNewCuinea (PG) +675',
                 key: '+675'
             },
             {
                 icon: require('../assets/imgs/country/py.png'),
                 title: 'Paraguay (PY) +595',
                 key: '+595'
             },
             {
                 icon: require('../assets/imgs/country/pe.png'),
                 title: 'Peru (PE) +51',
                 key: '+51'
             },
             {
                 icon: require('../assets/imgs/country/ph.png'),
                 title: 'Philippines (PH) +63',
                 key: '+63'
             },
             {
                 icon: require('../assets/imgs/country/pl.png'),
                 title: 'Poland (PL) +48',
                 key: '+48'
             },
             {
                 icon: require('../assets/imgs/country/pt.png'),
                 title: 'Portugal (PT) +351',
                 key: '+351'
             },
             {
                 icon: require('../assets/imgs/country/pr.png'),
                 title: 'PuertoRico (PR) +1787',
                 key: '+1787'
             },
         ]
     },
     {
         title: 'Q',
         id: 'Q',
         children: [
             {
                 icon: require('../assets/imgs/country/qa.png'),
                 title: 'Qatar (QA) +974',
                 key: '+974'
             },
         ]
     },
     {
         title: 'R',
         id: 'R',
         children: [
             {
                 icon: '',
                 title: 'RepublicofIvoryCoast (KT) +225',
                 key: '+225'
             },
             {
                 icon: '',
                 title: 'Reunion (RE) +262',
                 key: '+262'
             },
             {
                 icon: require('../assets/imgs/country/ro.png'),
                 title: 'Romania (RO) +40',
                 key: '+40'
             },
             {
                 icon: require('../assets/imgs/country/ru.png'),
                 title: 'Russia (RU) +7',
                 key: '+7'
             },
         ]
     },
     {
         title: 'S',
         id: 'S',
         children: [
             {
                 icon: require('../assets/imgs/country/vc.png'),
                 title: 'Saint Vincent and The Grenadines (VC) +1784',
                 key: '+1784'
             },
             {
                 icon: require('../assets/imgs/country/as.png'),
                 title: 'SamoaEastern (AS) +684',
                 key: '+684'
             },
             {
                 icon: require('../assets/imgs/country/sm.png'),
                 title: 'SanMarino (SM) +378',
                 key: '+378'
             },
             {
                 icon: require('../assets/imgs/country/ws.png'),
                 title: 'SanMarino (WS) +685',
                 key: '+685'
             },
             {
                 icon: require('../assets/imgs/country/st.png'),
                 title: 'SaoTomeandPrincipe (ST) +239',
                 key: '+239'
             },
             {
                 icon: require('../assets/imgs/country/sa.png'),
                 title: 'SaudiArabia (SA) +966',
                 key: '+966'
             },
             {
                 icon: require('../assets/imgs/country/sn.png'),
                 title: 'Senegal (SN) +221',
                 key: '+221'
             },
             {
                 icon: require('../assets/imgs/country/sc.png'),
                 title: 'Seychelles (SC) +248',
                 key: '+248'
             },
             {
                 icon: require('../assets/imgs/country/sl.png'),
                 title: 'SierraLeone (SL) +232',
                 key: '+232'
             },
             {
                 icon: require('../assets/imgs/country/sg.png'),
                 title: 'Singapore (SG) +65',
                 key: '+65'
             },
             {
                 icon: require('../assets/imgs/country/sk.png'),
                 title: 'Slovakia (SK) +421',
                 key: '+421'
             },
             {
                 icon: require('../assets/imgs/country/si.png'),
                 title: 'Slovenia (SI) +386',
                 key: '+386'
             },
             {
                 icon: require('../assets/imgs/country/sb.png'),
                 title: 'SolomonIs (SB) +677',
                 key: '+677'
             },
             {
                 icon: require('../assets/imgs/country/so.png'),
                 title: 'Somali (SO) +252',
                 key: '+252'
             },
             {
                 icon: require('../assets/imgs/country/za.png'),
                 title: 'SouthAfrica (ZA) +27',
                 key: '+27'
             },
             {
                 icon: require('../assets/imgs/country/es.png'),
                 title: 'Spain (ES) +34',
                 key: '+34'
             },
             {
                 icon: require('../assets/imgs/country/lk.png'),
                 title: 'SriLanka (LK) +94',
                 key: '+94'
             },
             {
                 icon: require('../assets/imgs/country/lc.png'),
                 title: 'Saint Lucia (LC) +1758',
                 key: '+1758'
             },
             {
                 icon: require('../assets/imgs/country/sd.png'),
                 title: 'Sudan (SD) +249',
                 key: '+249'
             },
             {
                 icon: require('../assets/imgs/country/sr.png'),
                 title: 'Suriname (SR) +597',
                 key: '+597'
             },
             {
                 icon: require('../assets/imgs/country/sz.png'),
                 title: 'Swaziland (SZ) +268',
                 key: '+268'
             },
             {
                 icon: require('../assets/imgs/country/se.png'),
                 title: 'Sweden (SE) +46',
                 key: '+46'
             },
             {
                 icon: require('../assets/imgs/country/ch.png'),
                 title: 'Switzerland (CH) +41',
                 key: '+41'
             },
             {
                 icon: require('../assets/imgs/country/sy.png'),
                 title: 'Syria (SY) +963',
                 key: '+963'
             },
         ]
     },
     {
         title: 'T',
         id: 'T',
         children: [
             {
                 icon: require('../assets/imgs/country/tw.png'),
                 title: 'Taiwan (TW) +886',
                 key: '+886'
             },
             {
                 icon: require('../assets/imgs/country/tj.png'),
                 title: 'Tajikstan (TJ) +992',
                 key: '+992'
             },
             {
                 icon: require('../assets/imgs/country/tz.png'),
                 title: 'Tanzania (TZ) +255',
                 key: '+255'
             },
             {
                 icon: require('../assets/imgs/country/th.png'),
                 title: 'Thailand (TH) +66',
                 key: '+66'
             },
             {
                 icon: require('../assets/imgs/country/tg.png'),
                 title: 'Togo (TG) +228',
                 key: '+228'
             },
             {
                 icon: require('../assets/imgs/country/to.png'),
                 title: 'Tonga (TO) +676',
                 key: '+676'
             },
             {
                 icon: require('../assets/imgs/country/tt.png'),
                 title: 'TrinidadandTobago (TT) +1809',
                 key: '+1809'
             },
             {
                 icon: require('../assets/imgs/country/tn.png'),
                 title: 'Tunisia (TN) +216',
                 key: '+216'
             },
             {
                 icon: require('../assets/imgs/country/tr.png'),
                 title: 'Turkey (TR) +90',
                 key: '+90'
             },
             {
                 icon: require('../assets/imgs/country/tm.png'),
                 title: 'Turkmenistan (TM) +993',
                 key: '+993'
             },
         ]
     },
     {
         title: 'U',
         id: 'U',
         children: [
             {
                 icon: require('../assets/imgs/country/ug.png'),
                 title: 'Uganda (UG) +256',
                 key: '+256'
             },
             {
                 icon: require('../assets/imgs/country/ua.png'),
                 title: 'Ukraine (UA) +380',
                 key: '+380'
             },
             {
                 icon: require('../assets/imgs/country/ae.png'),
                 title: 'United Arab Emirates (AE) +971',
                 key: '+971'
             },
             {
                 icon: require('../assets/imgs/country/gb.png'),
                 title: 'United Kingdom (GB) +44',
                 key: '+44'
             },
             {
                 icon: require('../assets/imgs/country/us.png'),
                 title: 'United States (US) +1',
                 key: '+1'
             },
             {
                 icon: require('../assets/imgs/country/uy.png'),
                 title: 'Uruguay (UY) +598',
                 key: '+598'
             },
             {
                 icon: require('../assets/imgs/country/uz.png'),
                 title: 'Uzbekistan (UZ) +233',
                 key: '+233'
             },
         ]
     },
     {
         title: 'V',
         id: 'V',
         children: [
             {
                 icon: require('../assets/imgs/country/ve.png'),
                 title: 'Venezuela (VE) +58',
                 key: '+58'
             },
             {
                 icon: require('../assets/imgs/country/vn.png'),
                 title: 'Vietnam (VN) +84',
                 key: '+84'
             },
         ]
     },
     {
         title: 'Y',
         id: 'Y',
         children: [
             {
                 icon: require('../assets/imgs/country/ye.png'),
                 title: 'Yemen (YE) +967',
                 key: '+967'
             },
             {
                 icon: '',
                 title: 'Yugoslavia (YU) +38',
                 key: '+38'
             },
         ]
     },
     {
       title: 'Z',
       id: 'Z',
       children: [
           {
               icon: '',
               title: 'Zaire (ZR) +243',
               key: '+243'
           },
           {
               icon: require('../assets/imgs/country/zm.png'),
               title: 'Zambia (ZM) +260',
               key: '+260'
           },
           {
               icon: require('../assets/imgs/country/zw.png'),
               title: 'Zimbabwe (ZW) +263',
               key: '+263'
           },
       ]
     }
   ]
  },
  mutations: {
  },
  actions: {
    initLang: (store) => {
      store.state.lang = localStorage.getItem('locale') || 'en'
      store.state.langList = store.state.langListBak.filter(v => {
        return !store.state.language || store.state.language.indexOf(v.key) > -1
      })
    },
    addTabs: (store, menu) => {
      let list = store.state.tabList
      let index = list.findIndex(v => {
        return v.name == menu.name
      })
      if (index === -1) {
        store.state.tabList.push(menu)
      }
    },
    removeTab: (store, name) => {
      let list = store.state.tabList
      let index = list.findIndex(v => {
        return v.name == name
      })
      if (index > -1 && list.length > 1) {
        store.state.tabList.splice(index, 1)
      }
    },
    initCategory: (store, lang) => {
      let data = sessionStorage.getItem('categoryDataBak')
      if (data) {
        let categoryList = resetCategory(JSON.parse(data), '0', lang)
        store.state.categoryList = categoryList
      }
      getCategoryList({
        current: 1,
        pageSize: 5000
      }).then((res) => {
        let list = res.data.Items
        let categoryList = resetCategory(list, '0', lang)
        store.state.categoryList = categoryList
        sessionStorage.setItem('categoryDataBak', JSON.stringify(list))
      })
    },
    resetCategoryName: (store, lang) => {
      let list = store.state.categoryList || []
      let categoryList = resetCategory(list, '0', lang)
      store.state.categoryList = categoryList
      sessionStorage.setItem('categoryDataBak', JSON.stringify(list))
    }
  },
  modules: {
  }
})
