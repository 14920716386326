<template>
  <div class="wap-product-detail">
    <headerBar :title="$t('xiang-qing-0')" @back="back"></headerBar>

    <div class="detail-box">
      <div class="top-info flex">
        <div class="img">
          <img :src="detailData.MainImage" alt="" />
        </div>
        <div class="flex-1 info">
          <div class="name">
            {{
              detailData.ProductTranslation
                ? detailData.ProductTranslation[0].Name
                : ''
            }}
          </div>
          <div class="count">
            <span>{{ getCategoryName(detailData.ProductCategory) }}</span>
            <span
              >{{ $t('xiao-liang-2') }}
              {{ detailData.Sales }}
            </span>
          </div>
          <div class="price">${{ detailData.SalePrice }}</div>
        </div>
      </div>

      <div class="flex-center-between line">
        <div class="label">{{ $t('xiao-shou-liang') }}</div>
        <div class="value">{{ detailData.Sales }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('dan-jia') }}</div>
        <div class="value">${{ detailData.SalePrice }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('gou-mai-jia-ge') }}</div>
        <div class="value">${{ detailData.MinPrice }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('yu-qi-li-run') }}</div>
        <div class="value">${{ parseFloat(detailData.SalePrice - detailData.MinPrice).toFixed(2) }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shi-fou-shang-jia') }}</div>
        <div class="value" v-if="detailData.Status == 1">{{ $t('shi') }}</div>
        <div class="value" v-else>{{ $t('fou') }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shi-fou-tui-jian') }}</div>
        <div class="value">{{ $t('fou') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import { shopProductList } from '@/api/shop'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      dataId: '',
      detailData: {}
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      shopProductList({
        ID: parseFloat(this.dataId)
      }).then((res) => {
        let list = res.data.Items
        this.detailData = list[0]
      })
    },
    getCategoryName(data) {
      if (!data) {
        return ''
      }
      return data.ProductCategoryTranslations.find((v) => {
        return v.Language == this.lang
      }).Name
    }
  }
}
</script>