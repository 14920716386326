import fetch from "../utils/fetch";

// 退款申请
export function refundList(data) {
  return fetch({
    url: '/api/setting/list',
    method: 'get',
    data: data
  });
}
// 商家入驻
export function openShop(data) {
  return fetch({
    url: '/api/user/merchant-application-add',
    method: 'post',
    data: data
  });
}
// 店铺信息
export function shopInfo(data) {
  return fetch({
    url: '/api/user/shop-info',
    method: 'post',
    data: data
  });
}
// 仪表盘
export function indexInfo(data) {
  return fetch({
    url: '/api/user/index-info',
    method: 'post',
    data: data
  });
}
// 订单列表
export function orderList(data) {
  return fetch({
    url: '/api/user/business-order-lists',
    method: 'post',
    data: data
  });
}
// 直通车列表
export function trainList(data) {
  return fetch({
    url: '/api/user/shop-train-list',
    method: 'post',
    data: data
  });
}
// 直通车购买
export function trainBuy(data) {
  return fetch({
    url: '/api/user/shop-train-upgrade',
    method: 'post',
    data: data
  });
}
// 直通车购买记录
export function trainLog(data) {
  return fetch({
    url: '/api/user/shop-train-record-list',
    method: 'post',
    data: data
  });
}
// 店铺等级列表
export function shopLevelList(data) {
  return fetch({
    url: '/api/user/shop-level-list',
    method: 'post',
    data: data
  });
}
// 店铺等级记录
export function shopLevelLog(data) {
  return fetch({
    url: '/api/user/shop-level-record-list',
    method: 'post',
    data: data
  });
}
// 产品库列表
export function productList(data) {
  return fetch({
    url: '/api/user/product-list',
    method: 'post',
    data: data
  });
}
// 添加商品
export function addProduct(data) {
  return fetch({
    url: '/api/user/business-product-add',
    method: 'post',
    data: data
  });
}
// 店铺商品列表
export function shopProductList(data) {
  return fetch({
    url: '/api/user/business-product-list',
    method: 'post',
    data: data
  });
}
// 分类列表
export function getCategoryList(data) {
  return fetch({
    url: '/api/user/product-category-list',
    method: 'post',
    data: data
  });
}
// 店铺商品删除
export function removeProduct(data) {
  return fetch({
    url: '/api/user/business-product-delete',
    method: 'post',
    data: data
  });
}
// 店铺商品修改
export function editShopProduct(data) {
  return fetch({
    url: '/api/user/batch-edit-price',
    method: 'post',
    data: data
  });
}
// 店铺列表
export function getShopList(data) {
  return fetch({
    url: '/api/user/shop-list',
    method: 'post',
    data: data
  });
}
// 商品规格列表
export function getProductSpecificationsList(data) {
  return fetch({
    url: '/api/user/business-product-specifications-relation-list',
    method: 'post',
    data: data
  });
}
// 商品评价列表
export function getProductEvaluteList(data) {
  return fetch({
    url: '/api/user/business-product-reviews-lists',
    method: 'post',
    data: data
  });
}
// 店铺关注列表
export function getShopFollowList(data) {
  return fetch({
    url: '/api/user/shop-follow-lists',
    method: 'post',
    data: data
  });
}
// 店铺关注
export function followShop(data) {
  return fetch({
    url: '/api/user/shop-follow-add',
    method: 'post',
    data: data
  });
}
// 店铺访问
export function addShopRead(data) {
  return fetch({
    url: '/api/user/read-add',
    method: 'post',
    data: data
  });
}
// 提货付款
export function pickupPay(data) {
  return fetch({
    url: '/api/user/pickup-pay',
    method: 'post',
    data: data
  });
}
// 店铺信息设置
export function shopSetting(data) {
  return fetch({
    url: '/api/user/shop-setting',
    method: 'Post',
    data: data
  });
}
// 财务报表
export function debtList(data) {
  return fetch({
    url: '/api/user/transaction-details-list',
    method: 'Post',
    data: data
  });
}
// 交易明细类型
export function debtType(data) {
  return fetch({
    url: '/api/user/transaction-details-type',
    method: 'Post',
    data: data
  });
}
// 新增订单评论
export function addEvalute(data) {
  return fetch({
    url: '/api/user/business-product-ratings-add',
    method: 'post',
    data: data
  });
}
// 新增商品评论
export function addGoodsEvalute(data) {
  return fetch({
    url: '/api/user/business-product-reviews-add',
    method: 'post',
    data: data
  });
}
// CronList 列表
export function cronList(data) {
  return fetch({
    url: '/api/user/cron-list',
    method: 'Post',
    data: data
  });
}

// 消息列表
export function messageList(data) {
  return fetch({
    url: '/api/user/message-list',
    method: 'post',
    data: data
  });
}
// 对话列表
export function conversationList(data) {
  return fetch({
    url: '/api/user/conversation-list',
    method: 'post',
    data: data
  });
}
// 消息已读
export function messageRead(data) {
  return fetch({
    url: '/api/user/message-read',
    method: 'post',
    data
  });
}
// 翻译
export function translateMsg(data) {
  return fetch({
    url: '/api/user/translate',
    method: 'post',
    data: data
  });
}
// 商家订单评价列表
export function getProductRatingList(data) {
  return fetch({
    url: '/api/user/business-product-ratings-lists',
    method: 'post',
    data: data
  });
}
// 营业额统计
export function turnoverInfo(data) {
  return fetch({
    url: '/api/merchant/turnover',
    method: 'post',
    data: data
  });
}
// 产品库统计
export function productStock(data) {
  return fetch({
    url: '/api/user/product-stock',
    method: 'post',
    data: data
  });
}
// 批量上架、下架
export function batchEditStatus(data) {
  return fetch({
    url: '/api/user/batch-edit-status',
    method: 'post',
    data: data
  });
}
// 订单统计
export function orderStatistics(data) {
  return fetch({
    url: '/api/user/business-order-statistics',
    method: 'post',
    data: data
  });
}
// 访问记录
export function visitLog(data) {
  return fetch({
    url: '/api/user/visit-log-lists',
    method: 'post',
    data: data
  });
}
// 财务报表
export function shopReport(data) {
  return fetch({
    url: '/api/user/financial-report',
    method: 'post',
    data: data
  });
}
// 商家认证
export function shopAuth(data) {
  return fetch({
    url: '/api/user/merchant-application-auth',
    method: 'post',
    data: data
  });
}
// 商家认证状态
export function getShopAuthStatus(data) {
  return fetch({
    url: '/api/user/check-merchant-application',
    method: 'post',
    data: data
  });
}
// 商家所有分类
export function getShopCategoryList(data) {
  return fetch({
    url: '/api/user/business-product-category-list',
    method: 'post',
    data: data
  });
}