import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken, setUserType, getUserType } from '@/utils/auth'
import store from '../store'
import i18n from '@/i18n'
Vue.use(VueRouter)

export const sellerPcRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      type: 'seller',
      title: i18n.t('yi-biao-pan'),
      icon: 'el-icon-house'
    },
    component: () => import('@/views/seller/index/index'),
  },
  {
    path: '/shopOrder',
    name: 'shopOrder',
    meta: {
          type: 'seller',
      title: i18n.t('dian-pu-ding-dan'),
      icon: 'el-icon-s-order'
    },
    component: () => import('@/views/seller/shop/shopOrder'),
  },
  {
    path: '/shopReport',
    name: 'shopReport',
    meta: {
          type: 'seller',
      title: i18n.t('cai-wu-bao-biao-0'),
      icon: 'el-icon-data-line'
    },
    component: () => import('@/views/seller/shop/shopReport'),
  },
  {
    path: '/myWallet',
    name: 'myWallet',
    meta: {
          type: 'seller',
      title: i18n.t('wo-de-qian-bao'),
      icon: 'el-icon-wallet'
    },
    component: () => import('@/views/seller/mine/wallet'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
          type: 'seller',
      title: i18n.t('chong-zhi-0'),
      hide: true
    },
    component: () => import('@/views/seller/mine/recharge'),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
          type: 'seller',
      title: i18n.t('ti-xian-0'),
      hide: true
    },
    component: () => import('@/views/seller/mine/withdraw'),
  },
  {
    path: '/shopRebet',
    name: 'shopRebet',
    meta: {
          type: 'seller',
      title: i18n.t('zi-jin-ji-lu'),
      icon: 'el-icon-bank-card'
    },
    component: () => import('@/views/seller/shop/shopRebet'),
  },
  {
    path: '/productsManage',
    name: 'productsManage',
    meta: {
          type: 'seller',
      title: i18n.t('shang-pin-guan-li'),
      icon: 'el-icon-shopping-bag-1'
    },
    component: () => import('@/views/blank'),
    children: [
      {
        path: '/shopProducts',
        name: 'shopProducts',
        meta: {
          type: 'seller',
          title: i18n.t('dian-pu-shang-pin'),
          icon: 'el-icon-shopping-bag-2'
        },
        component: () => import('@/views/seller/shop/shopProduct'),
      },
      {
        path: '/shopRefund',
        name: 'shopRefund',
        meta: {
          type: 'seller',
          title: i18n.t('tui-kuan-qing-qiu'),
          icon: 'el-icon-warning'
        },
        component: () => import('@/views/seller/shop/shopRefund'),
      },
      {
        path: '/productEvalute',
        name: 'productEvalute',
        meta: {
          type: 'seller',
          title: i18n.t('shang-pin-ping-lun'),
          icon: 'el-icon-chat-dot-square'
        },
        component: () => import('@/views/seller/shop/productEvalute'),
      },
      {
        path: '/productLibrary',
        name: 'productLibrary',
        meta: {
          type: 'seller',
          title: i18n.t('shang-pin-ku-0'),
          icon: 'el-icon-s-home'
        },
        component: () => import('@/views/seller/shop/productLibrary'),
      },
    ]
  },
  {
    path: '/shopSetting',
    name: 'shopSetting',
    meta: {
          type: 'seller',
      title: i18n.t('dian-pu-she-zhi-0'),
      icon: 'el-icon-s-tools'
    },
    component: () => import('@/views/seller/shop/shopSetting'),
  },
  {
    path: '/saleManage',
    name: 'saleManage',
    meta: {
          type: 'seller',
      title: i18n.t('ying-xiao-gong-ju'),
      icon: 'el-icon-s-data'
    },
    component: () => import('@/views/blank'),
    children: [
      {
        path: '/shopCar',
        name: 'shopCar',
        meta: {
          type: 'seller',
          title: i18n.t('dian-pu-zhi-tong-che-0'),
          icon: 'el-icon-truck'
        },
        component: () => import('@/views/seller/shop/shopCar'),
      },
      {
        path: '/buyHistory',
        name: 'buyHistory',
        meta: {
          type: 'seller',
          title: i18n.t('gou-mai-li-shi'),
          icon: 'el-icon-time'
        },
        component: () => import('@/views/seller/shop/buyHistory'),
      },
      {
        path: '/shopLevel',
        name: 'shopLevel',
        meta: {
          type: 'seller',
          title: i18n.t('mai-jia-deng-ji-1'),
          icon: 'el-icon-upload2'
        },
        component: () => import('@/views/seller/shop/shopLevel'),
      },
    ]
  },
]

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      type: 'seller',
    },
    component: () => import('@/views/login/login')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      type: 'seller',
    },
    component: () => import('@/views/login/register')
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      index: 10
    },
    component: () => import('@/views/buyerH5/index/download')
  },
  // 买家pc路由
  {
    path: '/buyer',
    name: 'buyer',
    component: () => import('@/views/buyer/home/index'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/buyer/index/index'),
      },
      {
        path: '/category',
        name: 'category',
        component: () => import('@/views/buyer/index/category'),
      },
      {
        path: '/commodity',
        name: 'commodity',
        component: () => import('@/views/buyer/products/common'),
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views/buyer/products/search'),
      },
      {
        path: '/searchShop',
        name: 'searchShop',
        component: () => import('@/views/buyer/shop/search'),
      },
      {
        path: '/discount',
        name: 'discount',
        component: () => import('@/views/buyer/products/discount'),
      },
      {
        path: '/goodsDetail',
        name: 'goodsDetail',
        component: () => import('@/views/buyer/products/goodsDetail'),
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/buyer/products/order'),
      },
      {
        path: '/userLogin',
        name: 'userLogin',
        component: () => import('@/views/buyer/login/login'),
      },
      {
        path: '/userRegister',
        name: 'userRegister',
        component: () => import('@/views/buyer/login/register'),
      },
      {
        path: '/myLoan',
        name: 'myLoan',
        component: () => import('@/views/buyer/index/myLoan'),
      },
      {
        path: '/loanApply',
        name: 'loanApply',
        component: () => import('@/views/buyer/index/loanApply'),
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        redirect: '/userInfo/dashboard',
        component: () => import('@/views/buyer/userInfo/layout'),
        children: [
          {
            path: '/userInfo/dashboard',
            name: 'userInfoDashboard',
            component: () => import('@/views/buyer/userInfo/dashboard'),
          },
          {
            path: '/userInfo/wallet',
            name: 'userInfoWallet',
            component: () => import('@/views/buyer/userInfo/wallet'),
          },
          {
            path: '/userInfo/orderList',
            name: 'userInfoOrderList',
            component: () => import('@/views/buyer/userInfo/orderList'),
          },
          {
            path: '/userInfo/collect',
            name: 'userInfoCollect',
            component: () => import('@/views/buyer/userInfo/productCollect'),
          },
          {
            path: '/userInfo/followShop',
            name: 'userInfoFollowShop',
            component: () => import('@/views/buyer/userInfo/followShop'),
          },
          {
            path: '/userInfo/notice',
            name: 'userInfoNotice',
            component: () => import('@/views/buyer/userInfo/notice'),
          },
          {
            path: '/userInfo/noticeDetail',
            name: 'userInfoNoticeDetail',
            component: () => import('@/views/buyer/userInfo/noticeDetail'),
          },
          {
            path: '/userInfo/setting',
            name: 'userInfoSetting',
            component: () => import('@/views/buyer/userInfo/setting'),
          },
          {
            path: '/userInfo/editPassword',
            name: 'userInfoEditPassword',
            component: () => import('@/views/buyer/userInfo/editPassword'),
          },
          {
            path: '/userInfo/editSafePassword',
            name: 'userInfoEditSafePassword',
            component: () => import('@/views/buyer/userInfo/editSafePassword'),
          },
          {
            path: '/userInfo/address',
            name: 'userInfoAddress',
            component: () => import('@/views/buyer/userInfo/addressList'),
          },
          {
            path: '/userInfo/recharge',
            name: 'userInfoRecharge',
            component: () => import('@/views/buyer/userInfo/recharge'),
          },
          {
            path: '/userInfo/withdraw',
            name: 'userInfoWithdraw',
            component: () => import('@/views/buyer/userInfo/withdraw'),
          },
          {
            path: '/userInfo/orderDetail',
            name: 'userInfoOrderDetail',
            component: () => import('@/views/buyer/userInfo/orderDetail'),
          },

        ]
      }
    ]
  },
  {
    path: '/credit',
    name: 'credit',
    component: () => import('@/views/buyer/index/credit'),
  },
  {
    path: '/shopInfo',
    name: 'shopInfo',
    component: () => import('@/views/buyer/shop/shopInfo'),
  },
  {
    path: '/content',
    name: 'content',
    component: () => import('@/views/buyer/content/main'),
    children: [
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/buyer/content/privacy'),
      },
      {
        path: '/refundRole',
        name: 'refundRole',
        component: () => import('@/views/buyer/content/refundRole'),
      },
      {
        path: '/delivery',
        name: 'delivery',
        component: () => import('@/views/buyer/content/delivery'),
      },
      {
        path: '/shippingPolicy',
        name: 'shippingPolicy',
        component: () => import('@/views/buyer/content/shippingPolicy'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/buyer/content/about'),
      },
      {
        path: '/statement',
        name: 'statement',
        component: () => import('@/views/buyer/content/statement'),
      },
    ]
  },
  // 买家h5路由
  {
    path: '/buyerH5',
    name: 'buyerH5',
    component: () => import('@/views/buyerH5/home/index'),
    children: [
      {
        path: '/wap/index',
        name: 'wapIndex',
        meta: {
          type: 'user',
          index: 1
        },
        component: () => import('@/views/buyerH5/index/index'),
      },
      {
        path: '/wap/goods',
        name: 'wapGoods',
        meta: {
          type: 'user',
          index: 1
        },
        component: () => import('@/views/buyerH5/product/product'),
      },
      {
        path: '/wap/car',
        name: 'wapCar',
        meta: {
          type: 'user',
          index: 1
        },
        component: () => import('@/views/buyerH5/shop/car'),
      },
      {
        path: '/wap/my',
        name: 'wapMy',
        meta: {
          type: 'user',
          index: 1
        },
        component: () => import('@/views/buyerH5/mine/mine'),
      },
    ]
  },
  {
    path: '/wap/kf',
    name: 'wapKf',
    meta: {
      index: 3
    },
    component: () => import('@/views/kefu'),
  },
  {
    path: '/wap/userOrder',
    name: 'wapUserOrder',
    meta: {
          type: 'user',
      index: 2
    },
    component: () => import('@/views/buyerH5/mine/order'),
  },
  {
    path: '/wap/userOrderDetail',
    name: 'wapUserOrderDetail',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/mine/orderDetail'),
  },
  {
    path: '/wap/orderEvalute',
    name: 'wapOrderEvalute',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/mine/orderEvalute'),
  },
  {
    path: '/wap/credit',
    name: 'wapCredit',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/index/credit'),
  },
  {
    path: '/wap/loanApply',
    name: 'wapLoanApply',
    meta: {
          type: 'user',
      index: 4
    },
    component: () => import('@/views/buyerH5/index/loanApply'),
  },
  {
    path: '/wap/myLoan',
    name: 'wapMyLoan',
    meta: {
          type: 'user',
      index: 4
    },
    component: () => import('@/views/buyerH5/index/myLoan'),
  },
  {
    path: '/wap/mySetting',
    name: 'wapMySetting',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/mine/setting'),
  },
  {
    path: '/wap/userProductDetail',
    name: 'wapUserProductDetail',
    meta: {
          type: 'user',
      index: 5
    },
    component: () => import('@/views/buyerH5/product/productDetail'),
  },
  {
    path: '/wap/toOrder',
    name: 'wapToOrder',
    meta: {
          type: 'user',
      index: 4
    },
    component: () => import('@/views/buyerH5/product/order'),
  },
  {
    path: '/wap/userOrderPay',
    name: 'wapUserOrderPay',
    meta: {
          type: 'user',
      index: 5
    },
    component: () => import('@/views/buyerH5/product/orderPay'),
  },
  {
    path: '/wap/address',
    name: 'wapAddress',
    meta: {
          type: 'user',
      index: 5
    },
    component: () => import('@/views/buyerH5/mine/addressList'),
  },
  {
    path: '/wap/addAddress',
    name: 'wapAddAddress',
    meta: {
          type: 'user',
      index: 6
    },
    component: () => import('@/views/buyerH5/mine/addAddress'),
  },
  {
    path: '/wap/classify',
    name: 'wapClassify',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/product/classify'),
  },
  {
    path: '/wap/shopInfo',
    name: 'wapShopInfo',
    meta: {
          type: 'user',
      index: 4
    },
    component: () => import('@/views/buyerH5/shop/shopInfo'),
  },
  {
    path: '/wap/collect',
    name: 'wapCollect',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/mine/collect'),
  },
  {
    path: '/wap/followShop',
    name: 'wapFollowShop',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/mine/followShop'),
  },
  {
    path: '/wap/kefu',
    name: 'wapKefu',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/mine/kefu'),
  },
  {
    path: '/wap/userMessage',
    name: 'wapUserMessage',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/sellerH5/message/message'),
  },
  {
    path: '/wap/userLogin',
    name: 'wapUserLogin',
    meta: {
          type: 'user',
      index: 2
    },
    component: () => import('@/views/buyerH5/login/login'),
  },
  {
    path: '/wap/userRegister',
    name: 'wapUserRegister',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/login/register'),
  },
  {
    path: '/wap/openShop',
    name: 'wapOpenShop',
    meta: {
          type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/shop/openShop'),
  },
  {
    path: '/wap/conetnt',
    name: 'wapContent',
    meta: {
      type: 'user',
      index: 3
    },
    component: () => import('@/views/buyerH5/content/main'),
    children: [
      {
        path: '/wap/privacy',
        name: 'wapPrivacy',
        component: () => import('@/views/buyer/content/privacy'),
      },
      {
        path: '/wap/refundRole',
        name: 'wapRefundRole',
        component: () => import('@/views/buyer/content/refundRole'),
      },
      {
        path: '/wap/delivery',
        name: 'wapDelivery',
        component: () => import('@/views/buyer/content/delivery'),
      },
      {
        path: '/wap/shippingPolicy',
        name: 'wapShippingPolicy',
        component: () => import('@/views/buyer/content/shippingPolicy'),
      },
      {
        path: '/wap/about',
        name: 'wapAbout',
        component: () => import('@/views/buyer/content/about'),
      },
      {
        path: '/wap/statement',
        name: 'wapStatement',
        component: () => import('@/views/buyer/content/statement'),
      },
    ]
  },
  // 卖家pc路由
  {
    path: '/seller',
    name: 'seller',
    component: () => import('@/views/seller/home/index'),
    children: [
      ...sellerPcRoutes
    ]
  },
  // 卖家h5路由
  {
    path: '/sellerH5',
    name: 'sellerH5',
    component: () => import('@/views/sellerH5/home/index'),
    children: [
      {
        path: '/wap/home',
        name: 'wapHome',
        meta: {
          type: 'seller',
          index: 1
        },
        component: () => import('@/views/sellerH5/index/index'),
      },
      {
        path: '/wap/product',
        name: 'wapProduct',
        meta: {
          type: 'seller',
          index: 1
        },
        component: () => import('@/views/sellerH5/product/product'),
      },

      {
        path: '/wap/order',
        name: 'wapOrder',
        meta: {
          type: 'seller',
          index: 1
        },
        component: () => import('@/views/sellerH5/order/order'),
      },
      {
        path: '/wap/message',
        name: 'wapMessage',
        meta: {
          type: 'seller',
          index: 1
        },
        component: () => import('@/views/sellerH5/message/message'),
      },
      {
        path: '/wap/mine',
        name: 'wapMine',
        meta: {
          type: 'seller',
          index: 1
        },
        component: () => import('@/views/sellerH5/mine/mine'),
      },
    ]
  },
  {
    path: '/wap/notice',
    name: 'wapNotice',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/notice'),
  },
  {
    path: '/wap/productLibrary',
    name: 'wapProductLibrary',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/product/productLibrary'),
  },
  {
    path: '/wap/productEvalute',
    name: 'wapProductEvalute',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/product/productEvalute'),
  },
  {
    path: '/wap/search',
    name: 'wapSearch',
    meta: {
          type: 'seller',
      index: 4
    },
    component: () => import('@/views/sellerH5/product/search'),
  },
  {
    path: '/wap/noticeDetail',
    name: 'wapNoticeDetail',
    meta: {
          type: 'seller',
      index: 4
    },
    component: () => import('@/views/sellerH5/mine/noticeDetail'),
  },
  {
    path: '/wap/editProduct',
    name: 'wapEditProduct',
    meta: {
          type: 'seller',
      index: 5
    },
    component: () => import('@/views/sellerH5/product/editProduct'),
  },
  {
    path: '/wap/productDetail',
    name: 'wapProductDetail',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/product/productDetail'),
  },
  {
    path: '/wap/orderDetail',
    name: 'wapOrderDetail',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/order/orderDetail'),
  },
  {
    path: '/wap/avatar',
    name: 'wapAvatar',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/avatar'),
  },
  {
    path: '/wap/auth',
    name: 'wapAuth',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/auth'),
  },
  {
    path: '/wap/phoneAuth',
    name: 'wapPhoneAuth',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/phoneAuth'),
  },
  {
    path: '/wap/emailAuth',
    name: 'wapEmailAuth',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/emailAuth'),
  },
  {
    path: '/wap/editPassword',
    name: 'wapEditPassword',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/editPassword'),
  },
  {
    path: '/wap/editUserPassword',
    name: 'wapUserEditPassword',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/editPassword'),
  },
  {
    path: '/wap/editSafePassword',
    name: 'wapEditSafePassword',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/editSafePassword'),
  },
  {
    path: '/wap/editUserSafePassword',
    name: 'wapUserEditSafePassword',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/editSafePassword'),
  },
  {
    path: '/wap/shipping',
    name: 'wapShipping',
    meta: {
          type: 'seller',
      index: 4
    },
    component: () => import('@/views/sellerH5/order/shipping'),
  },
  {
    path: '/wap/orderPay',
    name: 'wapOrderPay',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/order/orderPay'),
  },
  {
    path: '/wap/chat',
    name: 'wapChat',
    meta: {
          type: 'seller',
      index: 4
    },
    component: () => import('@/views/sellerH5/message/chat'),
  },
  {
    path: '/wap/userChat',
    name: 'wapUserChat',
    meta: {
          type: 'seller',
      index: 4
    },
    component: () => import('@/views/sellerH5/message/chat'),
  },
  {
    path: '/wap/shopSetting',
    name: 'wapSetting',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/setting/setting'),
  },
  {
    path: '/wap/basicInfo',
    name: 'wapBasicInfo',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/setting/basicInfo'),
  },
  {
    path: '/wap/shopSocial',
    name: 'wapShopSocial',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/setting/shopSocial'),
  },
  {
    path: '/wap/refund',
    name: 'wapRefund',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/shop/refund'),
  },
  {
    path: '/wap/lang',
    name: 'wapLang',
    meta: {
          type: 'seller',
      index: 5
    },
    component: () => import('@/views/sellerH5/setting/lang'),
  },
  {
    path: '/wap/userInfo',
    name: 'wapUserInfo',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/mine/userInfo'),
  },
  {
    path: '/wap/shopCar',
    name: 'wapShopCar',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/shop/shopCar'),
  },
  {
    path: '/wap/shopBanner',
    name: 'wapShopBanner',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/setting/shopBanner'),
  },
  {
    path: '/wap/recharge',
    name: 'wapRecharge',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/mine/recharge'),
  },

  {
    path: '/wap/rechargeInfo',
    name: 'wapRechargeInfo',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/rechargeInfo'),
  },
  {
    path: '/wap/userRechargeInfo',
    name: 'wapUserRechargeInfo',
    meta: {
          type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/mine/rechargeInfo'),
  },
  {
    path: '/wap/rechargeLog',
    name: 'wapRechargeLog',
    meta: {
          type: 'seller',
      index: 4
    },
    component: () => import('@/views/sellerH5/mine/rechargeLog'),
  },
  {
    path: '/wap/withdrawLog',
    name: 'wapWithdrawLog',
    meta: {
          type: 'seller',
      index: 4
    },
    component: () => import('@/views/sellerH5/mine/withdrawLog'),
  },
  {
    path: '/wap/withdraw',
    name: 'wapWithdraw',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/mine/withdraw'),
  },
  {
    path: '/wap/userWithdraw',
    name: 'wapUserWithdraw',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/mine/withdraw'),
  },
  {
    path: '/wap/debt',
    name: 'wapDebt',
    meta: {
          type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/mine/debt'),
  },
  {
    path: '/wap/report',
    name: 'wapReport',
    meta: {
      type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/mine/report'),
  },
  {
    path: '/wap/setting',
    name: 'wapSystem',
    meta: {
      type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/mine/setting'),
  },
  {
    path: '/wap/shopLevel',
    name: 'wapShopLevel',
    meta: {
      type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/shop/shopLevel'),
  },
  {
    path: '/wap/login',
    name: 'wapLogin',
    meta: {
      type: 'seller',
      index: 2
    },
    component: () => import('@/views/sellerH5/login/login'),
  },
  {
    path: '/wap/register',
    name: 'wapRegister',
    meta: {
      type: 'seller',
      index: 3
    },
    component: () => import('@/views/sellerH5/login/register'),
  },
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  routes
})

const whiteArr = ['login', 'wapLogin', 'wapUserLogin', 'wapRegister', 'wapUserRegister', 'userLogin', 'userRegister']
const commonPage = ['kf', 'wapKf', 'download'] //公用页面
router.beforeEach((to, from, next) => {
  let userType = getUserType()
  let name = to.name
  if (commonPage.indexOf(to.name) > -1) {
    next()
    return
  }
  let width = window.innerWidth
  if (width > 576) {
    if (name.indexOf('wap') === 0) {
      let path = userType == 1 ? '/dashboard' : '/index'
      next(path)
      return
    }
  } else {
    if (name.indexOf('wap') != 0) {
      let path = userType == 1 ? '/wap/home' : '/wap/index'
      next(path)
      return
    }
  }


  let type = to.meta.type
  let nextUserType = type == 'seller' ? 1 : 2
  setUserType(nextUserType)
  let token = getToken()
  if (token) {
    next()
  } else {
    if (whiteArr.indexOf(to.name) > -1) {
      next()
    } else {
      // next('login')
      next()
    }
  }
})

router.afterEach((next) => {
  setTimeout(() => {
    let pageType = store.state.pageType
    if (pageType === 'pc') {
      document.body.className = 'pc-body'
      document.querySelector('html').id = 'pc-html'
    } else {
      document.body.className = 'h5-body'
      document.querySelector('html').id = ''
    }
  }, 300);

  store.state.showMessageBox = false

  document.body.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})

export default router
