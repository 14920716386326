<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-form :model="form" inline>
        <el-form-item :label="$t('hui-yuan-ni-cheng')" prop="username">
          <el-select v-model="form.username">
            <el-option
              v-for="(item, i) in statusList"
              :key="i"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('ding-dan-zhuang-tai')" prop="status">
          <el-select v-model="form.status">
            <el-option
              v-for="(item, i) in statusList"
              :key="i"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">{{ $t('cha-xun') }}</el-button>
          <el-button>{{ $t('zhong-zhi') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-container">
          <el-table :data="tableData" border>
            <el-table-column
              align="center"
              :label="$t('shang-pin-ming-cheng')"
              prop="ProductName"
            >
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('fu-wu-ping-fen-0')"
              prop="ServiceScore"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('wu-liu-ping-fen-0')"
              prop="LogisticsScore"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('shang-pin-ping-fen-0')"
              prop="ProductScore"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('ping-jia-nei-rong')"
              prop="Content"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('ping-jia-tu-pian')"
              prop="Image"
            >
              <template slot-scope="scope">
                <img :src="scope.row.Image" alt="" />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('ping-jia-shi-jian')"
              prop="CreatedAt"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getProductRatingList } from '@/api/shop'
export default {
  data() {
    return {
      tabList: [
        this.$t('quan-bu-ding-dan'),
        this.$t('dai-cai-gou'),
        this.$t('yi-cai-gou')
      ],
      tabIndex: 0,
      form: {},
      tableData: [],
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 1
      },
      statusList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: 1,
          label: this.$t('hao-ping')
        },
        {
          value: 2,
          label: this.$t('zhong-ping')
        },
        {
          value: 3,
          label: this.$t('cha-ping')
        }
      ],
      detailData: {},
      detailDialog: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getProductRatingList({
        current: this.page.current,
        pageSize: this.page.size
      }).then((res) => {
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {
      this.page.size = size
      this.init()
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    },
    orderDetail(data) {
      this.detailData = data
      this.detailDialog = true
    },
    getGoodsName(data) {
      return data.ProductTranslation && data.ProductTranslation[0]
        ? data.ProductTranslation[0].Name
        : ''
    }
  }
}
</script>