<template>
  <div class="userinfo-orderList">
    <div class="title pointer flex-center-start" @click="back">
      <i class="el-icon-arrow-left"></i>
      {{ $t('ding-dan-xin-xi') }}
    </div>

    <div class="order-detail flex">
      <div class="left">
        <div class="box-title">{{ $t('shang-pin-xin-xi') }}</div>
        <div class="goods flex">
          <div class="goods-detail flex-1">
            <div
              class="detail flex"
              v-for="(good, i) in detailData.OrderProduct"
              :key="i"
            >
              <div class="img">
                <img :src="good.BusinessProduct.MainImage" alt="" />
              </div>
              <div class="info">
                <div class="name">
                  {{
                    good.BusinessProduct.ProductTranslation
                      ? good.BusinessProduct.ProductTranslation[0].Name
                      : ''
                  }}
                </div>
                <div class="line flex-center-between">
                  <div class="flex-center">
                    <div class="price">${{ good.Price }}</div>
                    <div class="count ml-10">x{{ good.Number }}</div>
                  </div>
                  <div>
                    <el-button
                      size="mini"
                      @click="toEvalute(good)"
                      v-if="detailData.Status == 3 || detailData.Status == 4"
                      >{{ $t('ping-jia') }}</el-button
                    >
                  </div>
                </div>
                <div class="options">{{ good.optionName }}</div>
              </div>
            </div>
          </div>
          <div class="total-info">
            <div class="line">
              {{ $t('zong-jia-0') }}
              ${{ detailData.TotalAmount }}
            </div>
            <div class="line">
              {{ $t('zhe-kou-0') }}
              -$0.00
            </div>
            <div class="line">
              {{ $t('shui-shou-0') }}
              $0.00
            </div>
            <div class="line">
              {{ $t('yun-fei-0') }}
              $0.00
            </div>
            <div class="line">
              {{ $t('he-ji-0') }}
              ${{ detailData.TotalAmount }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="box-title">{{ $t('ding-dan-xin-xi-0') }}</div>

        <div class="order-info box">
          <div class="line">
            {{ $t('ding-dan-hao') }}
            {{ detailData.OrderNo }}
          </div>
          <div class="line">
            {{ $t('shi-jian') }}
            {{ detailData.CreatedAt }}
          </div>
          <div class="line">
            {{ $t('zhuang-tai') }}：<span class="orange">{{
              getStatusName(detailData.Status)
            }}</span>
          </div>
        </div>

        <div class="address-info box">
          <div class="label">{{ $t('jie-shou-zhe') }}</div>
          <div class="name">
            {{ detailData.ShippingAddress.FullName }}
            {{ detailData.ShippingAddress.Phone }}
          </div>
          <div class="address">
            {{ detailData.ShippingAddress.Country }}
            {{ detailData.ShippingAddress.Province }}
            {{ detailData.ShippingAddress.City }}
            {{ detailData.ShippingAddress.Address }}
          </div>
        </div>

        <div class="box total-info">
          <div class="line">
            <div class="label">{{ $t('ding-dan-zong-e') }}</div>
            <div class="count">${{ detailData.TotalAmount }}</div>
          </div>
          <div class="line">
            <div class="label">{{ $t('zhe-kou') }}</div>
            <div class="count">-$0.00</div>
          </div>
          <div class="line">
            <div class="label">{{ $t('shui-shou') }}</div>
            <div class="count">+$0.00</div>
          </div>
          <div class="line">
            <div class="label">{{ $t('yun-fei') }}</div>
            <div class="count">+$0.00</div>
          </div>
          <div class="action">
            <div class="flex-center-between">
              <div></div>
              <div class="total">
                {{ $t('he-ji-1') }}
                <span class="price">${{ detailData.TotalAmount }}</span>
              </div>
            </div>
            <div class="flex-center-between">
              <el-button
                v-if="detailData.Status == 0"
                @click="toPay(detailData)"
                >{{ $t('li-ji-fu-kuan') }}</el-button
              >
              <el-button
                @click="toCancel(item)"
                v-if="detailData.Status == 0 || detailData.Status == 1"
                >{{ $t('qu-xiao-ding-dan') }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('shu-ru-zhi-fu-mi-ma')"
      :visible.sync="showPopup"
      width="400px"
      class="pay-dialog"
    >
      <div class="pay-box">
        <div class="label">{{ $t('qing-shu-ru-nin-de-zhi-fu-mi-ma') }}</div>
        <passwordKeyboard v-model="password"></passwordKeyboard>
        <div class="btn">
          <el-button
            type="primary"
            :disabled="password.length != 6"
            @click="doPay"
            >{{ $t('que-ren') }}</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('ding-dan-ping-jia')"
      :visible.sync="showEvalutePopup"
      width="500px"
    >
      <div class="evalute-box">
        <el-form
          :model="evaluteForm"
          ref="evaluteForm"
          label-width="100px"
          :rules="formRule"
        >
          <el-form-item
            prop="ProductScore"
            required
            :label="$t('shang-pin-ping-fen')"
          >
            <div class="flex-center-start" style="height: 36px">
              <el-rate v-model="evaluteForm.ProductScore"></el-rate>
            </div>
          </el-form-item>
          <el-form-item
            prop="ServiceScore"
            required
            :label="$t('fu-wu-ping-fen')"
          >
            <div class="flex-center-start" style="height: 36px">
              <el-rate v-model="evaluteForm.ServiceScore"></el-rate>
            </div>
          </el-form-item>
          <el-form-item
            prop="LogisticsScore"
            required
            :label="$t('wu-liu-ping-fen')"
          >
            <div class="flex-center-start" style="height: 36px">
              <el-rate v-model="evaluteForm.LogisticsScore"></el-rate>
            </div>
          </el-form-item>
          <el-form-item :label="$t('ping-jia-tu-pian')" prop="Logo">
            <el-upload
              style="width: 100px; height: 100px"
              :show-file-list="false"
              :action="uploadUrl"
              :headers="headers"
              name="file"
              :on-success="uploadSuccess"
              :before-upload="beforeUpload"
            >
              <img
                v-if="evaluteForm.Image"
                :src="getBaseUrl(evaluteForm.Image)"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-camera-solid"
                style="font-size: 30px; color: #aaa"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item prop="Content" required :label="$t('ping-jia-0')">
            <el-input
              type="textarea"
              v-model="evaluteForm.Content"
              :placeholder="$t('qing-shu-ru-ping-jia-nei-rong-1')"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              class="submit-btn"
              type="primary"
              @click="submitEvalute"
              >{{ $t('ti-jiao') }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import passwordKeyboard from '@/components/password'
import { cancelOrder, orderPay } from '@/api/user'
import { addEvalute, addGoodsEvalute } from '@/api/shop'
import { getToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    passwordKeyboard
  },
  data() {
    return {
      detailData: {
        ShippingAddress: {}
      },
      evaluteForm: {},
      showPopup: false,
      showEvalutePopup: false,
      password: '',
      productID: '',
      headers: {},
      formRule: {
        ProductScore: [
          {
            message: this.$t('qing-xuan-ze-shang-pin-ping-fen'),
            required: true
          }
        ],
        LogisticsScore: [
          { message: this.$t('qing-xuan-ze-wu-liu-ping-fen'), required: true }
        ],
        ServiceScore: [
          { message: this.$t('qing-xuan-ze-fu-wu-ping-fen'), required: true }
        ],
        Content: [
          {
            message: this.$t('qing-shu-ru-ping-jia-nei-rong-0'),
            required: true
          }
        ]
      }
    }
  },
  computed: {
    totalMoney() {
      return this.detailData.price * this.detailData.count
    },
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {
      let data = sessionStorage.getItem('orderDetailData')

      if (data) {
        this.detailData = JSON.parse(data)
      }
    },
    back() {
      this.$router.go(-1)
    },
    changeCoinType(val) {
      let index = this.coinTypeList.findIndex((v) => {
        return v.key === val
      })
      this.linkList = JSON.parse(
        JSON.stringify(this.coinTypeList[index].linkList)
      )
      this.form.network = this.linkList[0].key
      this.form.coinName = this.coinTypeList[index].name
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    },
    toCancel(data) {
      this.$confirm(
        this.$t('que-ren-qu-xiao-ding-dan'),
        this.$t('que-ren')
      ).then((res) => {
        cancelOrder({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success(this.$t('qu-xiao-cheng-gong'))
            this.detailData.Status = 5
          } else {
            this.$message.warning(res.msg)
          }
        })
      })
    },
    toPay(data) {
      this.orderId = data.ID
      this.showPopup = true
    },
    doPay() {
      orderPay({
        OrderID: this.orderId,
        Password: parseInt(this.password)
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('zhi-fu-cheng-gong'))
          this.showPopup = false
          this.detailData.Status = 1
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    toEvalute(data) {
      this.showEvalutePopup = true
      this.productID = data.BusinessProductId
    },
    uploadSuccess(res) {
      this.evaluteForm.Image = res.data.FileName
      this.$forceUpdate()
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    submitEvalute() {
      this.$refs.evaluteForm.validate((vali) => {
        if (vali) {
          addEvalute({
            OrderId: this.detailData.ID,
            ProductScore: this.evaluteForm.ProductScore,
            ServiceScore: this.evaluteForm.ServiceScore,
            LogisticsScore: this.evaluteForm.LogisticsScore,
            Content: this.evaluteForm.Content,
            Image: this.evaluteForm.Image || null
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('ping-jia-cheng-gong'))
              this.showEvalutePopup = false
              this.$refs.evaluteForm.resetFields()
            } else {
              this.$message.warning(res.msg)
            }
          })

          //商品评论
          addGoodsEvalute({
            ProductID: this.productID,
            Score: this.evaluteForm.ProductScore,
            Content: this.evaluteForm.Content
          })
        }
      })
    }
  }
}
</script>