<template>
  <div class="wap-edit-product">
    <headerBar :title="$t('bian-ji-shang-pin')" @back="back"></headerBar>

    <div class="wap-form main">
      <div class="flex-center-between form-item">
        <div class="label big">{{ $t("shi-fou-shang-jia") }}</div>
        <div>
          <vanSwitch v-model="form.isPush" size="18px"></vanSwitch>
        </div>
      </div>

      <div class="form-item">
        <div class="label">{{ $t("li-run-bi-li") }}</div>
        <Field
          v-model="form.ProfitPercentage"
          label=""
          type="number"
          :max="ProfitRate"
          :placeholder="$t('qing-shu-ru-li-run-bi-li-3')"
        >
          <template #button> % </template>
        </Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t("zhe-kou-kai-shi-ri-qi") }}</div>
        <Field
          v-model="form.start"
          readonly
          label=""
          :placeholder="$t('qing-xuan-ze')"
          @click="changeStart(1)"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t("zhe-kou-jie-shu-ri-qi") }}</div>
        <Field
          v-model="form.end"
          readonly
          label=""
          :placeholder="$t('qing-xuan-ze-0')"
          @click="changeStart(2)"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t("zhe-kou-bi-li") }}</div>
        <Field
          v-model="form.DiscountPercentage"
          label=""
          type="number"
          :placeholder="$t('qing-shu-ru-li-run-bi-li-4')"
        >
          <template #button> % </template>
        </Field>
      </div>

      <Button type="info" class="submit-btn" @click="submit">{{
        $t("ti-jiao")
      }}</Button>
      <Button
        type="danger"
        class="submit-btn mt-20"
        v-if="isEditOne"
        @click="deleteProduct"
        >{{ $t("shan-chu") }}</Button
      >
    </div>

    <Popup v-model="showPopup" position="bottom">
      <DatetimePicker
        v-model="dateTime"
        type="date"
        :title="$t('xuan-ze-nian-yue-ri')"
        @cancel="cancel"
        @confirm="chooseDate"
      ></DatetimePicker>
    </Popup>
  </div>
</template>
<script>
import {
  Button,
  DatetimePicker,
  Popup,
  Switch as vanSwitch,
  Field,
  Dialog
} from "vant";
import headerBar from "@/components/header";
import { editShopProduct, batchEditStatus, removeProduct } from "@/api/shop";
import moment from "moment";
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field
  },
  data() {
    return {
      form: {
        isPush: true,
        start: "",
        end: "",
        Price: ""
      },
      showPopup: false,
      dateTime: "",
      timeFlag: "",
      editId: "",
      isEditOne: false
    };
  },
  computed: {
    langList() {
      return this.$store.state.langList;
    },
    lang() {
      return this.$store.state.lang;
    },
    ProfitRate() {
      return this.$store.state.ProfitRate;
    }
  },
  mounted() {
    this.editId = this.$route.query.ids;
    let status = this.$route.query.status;
    this.form.isPush = status == 1;
    this.isEditOne = this.$route.query.type == "one";
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    changeStart(flag) {
      this.timeFlag = flag;
      this.showPopup = true;
      let date = flag == 1 ? this.form.start : this.form.end;
      this.dateTime = date ? new Date(date) : new Date();
    },
    cancel() {
      this.showPopup = false;
    },
    chooseDate(e) {
      if (this.timeFlag == 1) {
        this.form.start = moment(e).format("YYYY-MM-DD");
      } else {
        this.form.end = moment(e).format("YYYY-MM-DD");
      }
      this.showPopup = false;
    },
    async submit() {
      try {
        if (this.form.ProfitPercentage > this.ProfitRate) {
          this.$toast(
              this.$t("li-run-bi-li-bu-neng-chao-guo-thisprofitrate", [
                this.ProfitRate
              ])
          );
          return;
        }
        let ids = this.editId.split(",");
        //批量上架、下架
        let form = new FormData();
        form.append("IDs", ids);
        form.append("Status", this.form.isPush ? 1 : 2);
        batchEditStatus(form);
        // 批量修改价格
        let form2 = new FormData();
        form2.append("IDs", ids);
        form2.append("ProfitPercentage", this.form.ProfitPercentage);
        form2.append(
            "DiscountStartDate",
            moment(this.form.start).format("YYYY-MM-DD")
        );
        form2.append(
            "DiscountEndDate",
            moment(this.form.end).format("YYYY-MM-DD")
        );
        form2.append("DiscountPercentage", this.form.DiscountPercentage);
        let res = await editShopProduct(form2);
        if (res.code == 0) {
          this.init();
          this.selectRows = [];
        } else {
          this.$toast(res.msg);
        }
      } catch (e) {
        console.log("错误")
        console.log("e", e);
      }

    },
    deleteProduct() {
      Dialog.confirm({
        title: this.$t("que-ren"),
        message: this.$t("que-ren-shan-chu-0")
      }).then(() => {
        removeProduct({
          ID: parseFloat(this.editId)
        }).then((res) => {
          if (res.code == 0) {
            this.$toast(this.$t("shan-chu-cheng-gong"));
            setTimeout(() => {
              this.back();
            }, 1500);
          } else {
            this.$toast(res.msg);
          }
        });
      });
    }
  }
};
</script>
