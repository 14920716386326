<template>
  <div class="wap-order-page">
    <headerBar :title="$t('ding-dan-que-ren-0')" @back="back"></headerBar>

    <div class="main">
      <div class="address-box flex-center-between" @click="changeAddress">
        <div>
          <div class="flex-center-start user">
            <i class="el-icon-location-outline"></i>
            <div>{{ addressInfo.FullName }}{{ addressInfo.Phone }}</div>
          </div>
          <div class="address">
            {{ addressInfo.Country }} {{ addressInfo.Province }}
            {{ addressInfo.City }} {{ addressInfo.Address }}
          </div>
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <div class="shop-box">
        <div class="shop" v-for="(item, index) in shoppingList" :key="index">
          <div class="check-line">
            <Checkbox
              v-model="item.checked"
              checked-color="#9d48f7"
              @click="clickShop(item, index)"
              icon-size="16px"
            >
              {{ item.Shop.Name }}
            </Checkbox>
          </div>
          <div class="goods-list">
            <div
              class="goods flex-center"
              v-for="(good, i) in item.goodsList"
              :key="i"
            >
              <div class="check">
                <Checkbox
                  v-model="good.checked"
                  checked-color="#9d48f7"
                  @click="clickGoods(good, i, index)"
                  icon-size="16px"
                ></Checkbox>
              </div>
              <div class="img">
                <img :src="good.MainImage" alt="" />
              </div>
              <div class="flex-1 info">
                <div class="name">
                  {{
                    good.ProductTranslation && good.ProductTranslation[0]
                      ? good.ProductTranslation[0].Name
                      : ''
                  }}
                </div>
                <div class="flex-center-between">
                  <div class="price">
                    ${{ good.optionPrice || good.SalePrice }}
                  </div>
                  <div class="number">
                    <el-input-number
                      class="gray"
                      size="mini"
                      v-model="good.count"
                      :min="1"
                    ></el-input-number>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="total-box">
        <div class="line">
          <div class="label">{{ $t('shang-pin-jin-e') }}</div>
          <div class="value">${{ totalMoney }}</div>
        </div>
        <div class="line">
          <div class="label">{{ $t('shui-shou') }}</div>
          <div class="value primary">+$0.00</div>
        </div>
        <div class="line">
          <div class="label">{{ $t('yun-fei') }}</div>
          <div class="value primary">+$0.00</div>
        </div>
        <div class="line total">
          <div class="label">{{ $t('zong-ji-0') }}</div>
          <div class="value primary">${{ totalMoney }}</div>
        </div>
      </div>
    </div>
    <div class="bottom-action flex-center-between">
      <div class="flex flex-1">
        <div class="flex-1">
          {{ $t('zong-ji-0') }}
          <span class="price">${{ totalMoney }}</span>
        </div>
        <div class="flex-1">
          {{ $t('xuan-ze') }}<span class="primary">{{
            chooseList.length
          }}</span
          >{{ $t('jian') }}
        </div>
      </div>
      <div>
        <Button @click="submit">{{ $t('que-ren') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import bottomLink from '@/components/bottomLink'
import passwordKeyboard from '@/components/password'
import {
  getWalletInfo,
  addOrder,
  orderPay,
  shippingAddressList
} from '@/api/user'
import { Checkbox, Button } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    bottomLink,
    Checkbox,
    passwordKeyboard,
    Button,
    headerBar
  },
  data() {
    return {
      password: '',
      addressInfo: {},
      payMethods: 0,
      showPopup: false,
      chooseList: [],
      shoppingList: [],
      goodsList: [],
      checkAll: true,
      showDialog: false,
      showAddDialog: false,
      orderId: '',
      addressList: [],
      payList: [
        {
          name: this.$t('yu-e'),
          img: require('@/assets/imgs/icon-pay1.png'),
          desc: '',
          checked: true
        },
        {
          name: this.$t('bei-bao'),
          img: require('@/assets/imgs/icon-pay2.png'),
          desc: this.$t(
            'gai-zhi-fu-fang-shi-shang-wei-dui-nin-suo-zai-de-di-qu-kai-fang-1'
          )
        },
        {
          name: this.$t('visa'),
          img: require('@/assets/imgs/icon-pay3.png'),
          desc: this.$t(
            'gai-zhi-fu-fang-shi-shang-wei-dui-nin-suo-zai-de-di-qu-kai-fang-1'
          )
        },
        {
          name: this.$t('wan-shi-da-ka'),
          img: require('@/assets/imgs/icon-pay4.png'),
          desc: this.$t(
            'gai-zhi-fu-fang-shi-shang-wei-dui-nin-suo-zai-de-di-qu-kai-fang-1'
          )
        }
      ],
      userMoney: ''
    }
  },
  computed: {
    totalMoney() {
      return this.chooseList
        .reduce((a, b) => {
          return a + (b.optionPrice || b.SalePrice) * b.count
        }, 0)
        .toFixed(2)
    }
  },
  mounted() {
    let flag = sessionStorage.getItem('addressFlag')
    if (flag == 1) {
      let data = sessionStorage.getItem('addressData')
      sessionStorage.setItem('addressFlag', 0)
      if (data) {
        this.addressInfo = JSON.parse(data)
      }
    } else {
      this.initAddress(true)
    }
    let data = sessionStorage.getItem('orderData')
    if (data) {
      this.shoppingList = JSON.parse(data)
      this.chooseList = []
      this.shoppingList.forEach((v) => {
        v.checked = true
        v.goodsList.forEach((val) => {
          val.checked = true
        })
        this.chooseList = this.chooseList.concat(v.goodsList)
      })
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    initAddress(getDefault) {
      shippingAddressList().then((res) => {
        let list = res.data.Items
        this.addressList = list
        if (getDefault) {
          if (list && list.length > 0) {
            let index = list.findIndex((v) => {
              return v.IsSelected == 1
            })
            if (index === -1) {
              index = 0
            }
            this.addressInfo = list[index] || {}
          }
        }
      })
    },
    clickShop(item, index) {
      let checked = item.checked
      this.shoppingList[index].goodsList.forEach((v) => {
        v.checked = checked
      })
      this.$forceUpdate()
      this.resetCheckList()
    },
    clickGoods(data, i, index) {
      let checked = data.checked

      let goodsList = this.shoppingList[index].goodsList
      let size = goodsList.filter((v) => {
        return v.checked
      }).length
      if (size == goodsList.length) {
        this.shoppingList[index].checked = true
      } else {
        this.shoppingList[index].checked = false
      }
      this.$forceUpdate()
      this.resetCheckList()
    },
    resetCheckList() {
      this.chooseList = []
      this.shoppingList.forEach((v) => {
        v.goodsList.forEach((val) => {
          if (val.checked) {
            this.chooseList.push(val)
          }
        })
      })
    },
    submit() {
      if (this.chooseList.length === 0) {
        this.$message.warning(this.$t('qing-gou-xuan-shang-pin'))
        return
      }
      let list = []
      this.shoppingList.forEach((v) => {
        v.goodsList.forEach((val) => {
          let param = {
            BusinessProductID: val.ID,
            BusinessProductSpecificationsRelationID: val.optionId || null,
            Number: val.count
          }
          list.push(param)
        })
      })
      addOrder({
        ShippingAddressID: this.addressInfo.ID,
        Lists: list
      }).then((res) => {
        if (res.code == 0) {
          this.orderId = res.data
          this.$router.push({
            name: 'wapUserOrderPay',
            query: {
              id: this.orderId,
              price: this.totalMoney
            }
          })
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    closeDialog() {
      this.showPopup = false
    },
    changeAddress() {
      this.$router.push({
        name: 'wapAddress',
        query: {
          type: 'choose'
        }
      })
    },
    showAdd() {
      this.showAddDialog = true
    },
    chooseAddress(data) {
      this.addressInfo = data
      this.showDialog = false
      this.showAddDialog = false
    }
  }
}
</script>