<template>
  <div class="wap-goods-detail">
    <div class="top-tab flex-center">
      <div class="back flex-center" @click="back">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div
        class="tab"
        :class="tabIndex == 1 ? 'active' : ''"
        @click="changeTab(1)"
      >
        {{ $t('common.user-tab2') }}
      </div>
      <div
        class="tab"
        :class="tabIndex == 2 ? 'active' : ''"
        @click="changeTab(2)"
      >
        {{ $t('ping-jia-1') }}
      </div>
      <div
        class="tab"
        :class="tabIndex == 3 ? 'active' : ''"
        @click="changeTab(3)"
      >
        {{ $t('xiang-qing') }}
      </div>
    </div>

    <div class="top-banner">
      <Swipe
        ref="bigWwipe"
        :autoplay="3000"
        :loop="true"
        :show-indicators="false"
        @change="changeBig"
      >
        <SwipeItem v-for="(item, i) in bannerList" :key="`big${i}`">
          <img :src="item" alt="" @dragstart.prevent />
        </SwipeItem>
      </Swipe>
    </div>

    <div class="info-main">
      <div class="main-title">
        {{
          detailData.ProductTranslation && detailData.ProductTranslation[0]
            ? detailData.ProductTranslation[0].Name
            : ''
        }}
      </div>

      <div class="flex-center-between info-detail gray">
        <div class="">
          <span class="mr-5">{{ $t('dan-jia') }}</span>
          <span class="price">${{ optionPrice || detailData.SalePrice }}</span>
        </div>
        <div class="label">
          {{ $t('xiao-shou-liang') }}
          {{ detailData.Sales }}
        </div>
      </div>

      <div class="option-box">
        <div class="info-line flex-center-start">
          <div class="label">
            {{
              chooseOptionList.length > 0 ? $t('yi-xuan') : $t('qing-xuan-ze')
            }}
          </div>
          <div class="value">
            {{
              chooseOptionList.length > 0
                ? `${chooseOptionName}`
                : `${optionName}`
            }}
          </div>
        </div>
        <div class="option-list flex-wrap">
          <div
            class="option-line flex"
            v-for="(item, i) in optionList"
            :key="`option${i}`"
          >
            <div class="tag">{{ item.Name }}</div>
            <div class="list flex-wrap">
              <div
                class="option flex-center"
                :class="option.checked ? 'active' : ''"
                v-for="(option, index) in item.options"
                :key="index"
                @click="changeOption(i, index, item, option)"
              >
                {{ option.Value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex info-detail small">
        <div class="mr-5">{{ $t('fa-huo') }}</div>
        <div class="label flex-1">
          {{
            $t(
              'shang-pin-xia-dan-hou-24-xiao-shi-nei-fa-huo-ru-xia-dan-cun-zai-wu-liu-guan-kong-ding-dan-ke-neng-bei-yan-shi-fa-huo-qing-liu-yi-ding-dan-wu-liu-xin-xi-huo-lian-xi-ke-fu'
            )
          }}
        </div>
      </div>
      <div class="flex-center-between info-detail small">
        <div class="">{{ $t('yun-fei') }}</div>
        <div class="flex-center" @click="showShipping">
          <span class="mr-5">{{ $t('mian-yun-fei') }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-center-between info-detail small">
        <div class="">{{ $t('shui-fei') }}</div>
        <div class="">
          <span class="">$0.00</span>
        </div>
      </div>
      <div class="flex-center-between info-detail small">
        <div class="">{{ $t('shu-liang') }}</div>
        <div class="">
          <el-input-number
            class="gray"
            v-model="form.number"
            size="mini"
            :min="1"
          ></el-input-number>
        </div>
      </div>

      <div class="evalute-box" id="evaluteBox">
        <div class="flex-center-between sub-title">
          <span>{{ $t('yong-hu-ping-jia') }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="tabs flex">
          <div
            class="tag"
            v-for="(item, i) in tagList"
            :key="`tag${i}`"
            @click="changeTag(item, i)"
            :class="tagIndex == i ? 'active' : ''"
          >
            {{ item.name }}({{ item.count }})
          </div>
        </div>
        <div class="evalute-list">
          <div
            class="evalute"
            v-for="(item, i) in evaluteList"
            :key="`evalute${i}`"
          >
            <div class="user flex-center-start">
              <img :src="headerUrl" alt="" />
              {{ item.User.Email || item.User.Phone }}
            </div>
            <div class="flex-center-start">
              <div class="icon">
                <i
                  class="el-icon-star-on"
                  style="#9d48f7"
                  v-for="i in item.Score"
                  :key="`star1-${i}`"
                ></i>
              </div>
            </div>
            <div class="content">{{ item.Content }}</div>
            <div class="imgs flex-wrap"></div>
            <div class="time">{{ item.CreatedAt }}</div>
          </div>
        </div>
      </div>

      <div class="shop-box">
        <div class="flex-center-between">
          <div class="flex-center-start">
            <img :src="shopInfo.Logo" alt="" />
            <div>
              <div class="name">{{ shopInfo.Name }}</div>
              <div class="label">
                {{ $t('ping-fen-0') }}
                {{ shopInfo.GoodScore }}
              </div>
            </div>
          </div>
          <Button
            class="btn"
            @click="toPath('wapShopInfo', { id: shopInfo.ID })"
            >{{ $t('fang-wen-shang-dian') }}</Button
          >
        </div>
        <div class="info flex">
          <div class="flex-1 flex-column-center">
            <div class="count">{{ shopInfo.TotalGoods || 0 }}</div>
            <div class="label">{{ $t('quan-bu-shang-pin') }}</div>
          </div>
          <div class="flex-1 flex-column-center">
            <div class="count">{{ shopInfo.TotalFollow || 0 }}</div>
            <div class="label">{{ $t('guan-zhu') }}</div>
          </div>
          <div class="flex-1 flex-column-center">
            <div class="count">{{ shopInfo.TotalDeal }}</div>
            <div class="label">{{ $t('xiao-shou-liang') }}</div>
          </div>
        </div>
      </div>

      <div class="page-title flex-center-between" id="descBox">
        {{ $t('shang-pin-jie-shao') }}
      </div>
      <div class="goods-desc">
        <div
          class="detail"
          v-if="
            detailData.ProductTranslation && detailData.ProductTranslation[0]
          "
          v-html="detailData.ProductTranslation[0].Detail"
        ></div>
      </div>

      <div class="page-title">{{ $t('cai-ni-xi-huan') }}</div>
      <goodsList :list="goodsList"></goodsList>
    </div>

    <div class="botttom-action flex-center">
      <div class="flex-center flex-1">
        <div class="flex-column-center flex-1">
          <i class="el-icon-star-off"></i>
          {{ $t('shou-cang') }}
        </div>
        <div
          class="flex-column-center flex-1"
          @click=" toPath('wapChat', {
              uid: shopUId,
              name: detailData.Shop.Name,
              goodsId: dataId
            })"
        >
          <i class="el-icon-service"></i>
          {{ $t('ke-fu-0') }}
        </div>
        <div class="flex-column-center flex-1" @click="toAdd(1)">
          <Badge :content="carSize" v-if="carSize > 0">
            <i class="el-icon-shopping-cart-full"></i>
          </Badge>
          <i class="el-icon-shopping-cart-full" v-else></i>
          {{ $t('common.user-tab3') }}
        </div>
      </div>
      <Button @click="toAdd(2)">
        <div>
          <div>${{ totalMoney }}</div>
          <div>{{ $t('li-ji-gou-mai') }}</div>
        </div>
      </Button>
    </div>

    <div class="to-top flex-center" v-show="showTop" @click="toTop">
      <i class="el-icon-arrow-up"></i>
    </div>

    <footerBar></footerBar>

    <Popup v-model="showShippingPopup" position="bottom">
      <div class="shipping-desc">
        <div class="popup-title flex-center">{{ $t('yun-fei-shuo-ming') }}</div>
        <div class="line">
          {{
            $t(
              '1-kua-jing-shang-pin-yun-fei-gou-cheng-yun-fei-pai-song-fei-chang-tu-yun-fei-song-huo-fei'
            )
          }}
        </div>
        <div class="line">
          {{
            $t(
              '2-ru-bu-man-zu-bao-you-tiao-jian-an-shi-ji-shou-qu-yun-fei-chan-pin'
            )
          }}
        </div>
        <div class="line">
          {{ $t('3-zui-zhong-jie-shi-quan-ge-ping-tai-suo-you') }}
        </div>
      </div>
    </Popup>

    <Popup v-model="showCarPopup" position="bottom" round>
      <div class="car-popup">
        <div class="popup-title flex-center-between">
          <div></div>
          <div>{{ $t('tian-jia-gou-wu-che') }}</div>
          <div @click="cancel">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="popup-main">
          <div class="goods-info flex-center-start">
            <div class="img flex-center">
              <img :src="detailData.MainImage" alt="" />
            </div>
            <div>
              <div class="price">${{ totalMoney }}</div>
              <div class="option-name">
                {{
                  chooseOptionList.length > 0
                    ? $t('yi-xuan')
                    : $t('qing-xuan-ze')
                }}
                {{
                  chooseOptionList.length > 0
                    ? `${chooseOptionName}`
                    : `${optionName}`
                }}
              </div>
              <div class="">
                {{ $t('shu-liang-0') }}
                {{ form.number }}
              </div>
            </div>
          </div>

          <div class="option-list">
            <div
              class="option-line"
              v-for="(item, i) in optionList"
              :key="`option${i}`"
            >
              <div class="tag">{{ item.Name }}</div>
              <div class="list flex-wrap">
                <div
                  class="option flex-center"
                  :class="option.checked ? 'active' : ''"
                  v-for="(option, index) in item.options"
                  :key="index"
                  @click="changeOption(i, index, item, option)"
                >
                  {{ option.Value }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex-center-between number">
            <div>{{ $t('shu-liang-1') }}</div>
            <div>
              <el-input-number
                class="gray"
                v-model="form.number"
                size="mini"
                :min="1"
              ></el-input-number>
            </div>
          </div>

          <div class="btn">
            <Button @click="submit">{{ $t('que-ding') }}</Button>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { shopProductList, getProductEvaluteList } from '@/api/shop'
import { addCar } from '@/api/user'
import footerBar from '@/components/footer/wapFooter'
import goodsList from '@/components/goodsList/wapUserList'
import { SwipeItem, Swipe, Button, Popup, Badge } from 'vant'
export default {
  components: {
    Swipe,
    SwipeItem,
    goodsList,
    footerBar,
    Button,
    Popup,
    Badge
  },
  data() {
    return {
      tabIndex: 1,
      form: {
        number: 1
      },
      headerUrl: require('@/assets/imgs/header/header1.png'),
      shopInfo: {},
      tagIndex: -1,
      optionIndex: 0,
      minCount: 1,
      activeIndex: 2,
      detailData: {
        ProductTranslation: [],
        ProductParametersRelation: null,
        Shop: {}
      },
      bannerList: [],
      shopUId: '',
      optionName: '',
      chooseOptionList: [],
      optionPriceList: [],
      optionList: [],
      chooseOptionName: '',
      optionPrice: '',
      tagList: [
        {
          name: this.$t('you-tu'),
          count: 0
        },
        {
          name: this.$t('hao-ping'),
          count: 0
        },
        {
          name: this.$t('zhong-ping'),
          count: 0
        },
        {
          name: this.$t('cha-ping'),
          count: 0
        }
      ],
      evaluteList: [],
      recommondGoodsList: [],
      goodsList: [],
      dataId: '',
      showTop: false,
      showShippingPopup: false,
      showCarPopup: false,
      actionType: ''
    }
  },
  computed: {
    totalMoney() {
      return (
        (this.form.number || 0) *
        (this.optionPrice || this.detailData.SalePrice || 0)
      )
    },
    lang() {
      return this.$store.state.lang
    },
    isLogin() {
      return this.$store.state.isLogin
    },
    carSize() {
      return this.$store.state.carSize
    }
  },
  watch: {
    $route() {
      this.dataId = this.$route.query.id
      this.init()
      this.initGoods()
      this.initEvalute()
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.pageScorll)
  },
  mounted() {
    document.body.addEventListener('scroll', this.pageScorll)
    this.dataId = this.$route.query.id
    this.init()
    this.initGoods()
    this.initEvalute()
  },
  methods: {
    pageScorll(e) {
      let top = e.target.scrollTop
      if (top > 300) {
        this.showTop = true
      } else {
        this.showTop = false
      }
    },
    back() {
      this.$router.go(-1)
    },
    changeTab(i) {
      this.tabIndex = i
      let top = 0
      if (i == 2) {
        let dom = document.getElementById('evaluteBox')
        top = dom.offsetTop - 50
      } else if (i == 3) {
        let dom = document.getElementById('descBox')
        top = dom.offsetTop - 50
      }
      document.body.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    },
    initEvalute() {
      let form = new FormData()
      form.append('ProductID', this.dataId)
      getProductEvaluteList(form).then((res) => {
        this.evaluteList = res.data.Items
      })
    },
    init() {
      shopProductList({
        ID: parseFloat(this.dataId)
      }).then((res) => {
        let list = res.data.Items
        this.detailData = list[0]
        this.shopUId = this.detailData.Shop.UId
        this.shopInfo = this.detailData.Shop
        this.bannerList = JSON.parse(this.detailData.Images)
        let optionList = this.detailData.ProductSpecifications || []
        this.optionPriceList =
          this.detailData.BusinessProductSpecificationsRelation || []
        this.optionList = this.resetOption(optionList)
        this.optionName = this.optionList
          .map((v) => {
            return v.Name
          })
          .join()

        // 店铺推荐商品
        this.getRecommondGoods()
      })
    },
    getRecommondGoods() {
      shopProductList({
        current: 1,
        pageSize: 4,
        ShopID: this.shopInfo.ID
      }).then((res) => {
        this.recommondGoodsList = res.data.Items.filter((v) => {
          return v.ID != this.dataId
        }).slice(0, 3)
      })
    },
    initGoods() {
      shopProductList({
        current: 1,
        pageSize: 7
      }).then((res) => {
        this.goodsList = res.data.Items.filter((v) => {
          return v.ID != this.dataId
        }).slice(0, 6)
      })
    },
    resetOption(list) {
      let newList = []
      list.forEach((v) => {
        let index = newList.findIndex((val) => {
          return val.Name == v.Name
        })
        if (index > -1) {
          newList[index].options.push(v)
        } else {
          newList.push({
            Name: v.Name,
            options: [v]
          })
        }
      })
      return newList
    },
    changeBig(e) {
      this.activeIndex = e
    },
    clickSmall(e) {
      this.activeIndex = e
      this.$refs.bigWwipe.swipeTo(e)
    },
    changeTag(data, i) {
      this.tagIndex = i
    },
    toBuy() {
      if (!this.isLogin) {
        this.$router.push({
          name: 'wapUserLogin'
        })
        return
      }
      sessionStorage.setItem(
        'orderData',
        JSON.stringify([
          {
            Shop: this.detailData.Shop,
            goodsList: [
              {
                ...this.detailData,
                count: this.form.number,
                optionName:
                  this.chooseOptionList.length > 0 ? this.chooseOptionName : '',
                optionId: this.optionInfo ? this.optionInfo.ID : null
              }
            ]
          }
        ])
      )
      this.$router.push({
        name: 'wapToOrder'
      })
    },
    toAdd(type) {
      this.showCarPopup = true
      this.actionType = type
    },
    submit() {
      if (this.optionList.length != this.chooseOptionList.length) {
        this.$toast(this.$t('qing-xuan-ze-gui-ge'))
        return
      }
      if (this.actionType == 1) {
        //添加购物车
        this.addToCar()
      } else {
        //立即购买
        this.toBuy()
      }
    },
    addToCar() {
      if (!this.isLogin) {
        this.$router.push({
          name: 'userLogin'
        })
        return
      }
      addCar({
        BusinessProductID: parseFloat(this.dataId),
        BusinessProductSpecificationsRelationID: this.optionInfo
          ? this.optionInfo.ID
          : null,
        Number: this.form.number
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('tian-jia-cheng-gong'))
          this.showCarPopup = false
        } else {
          this.$toast(res.msg)
        }
      })
    },
    toDetail(data) {
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: data.ID
        }
      })
    },
    changeOption(i, index, classify, data) {
      let optionIndex = this.chooseOptionList.findIndex((v) => {
        return v.Name == classify.Name
      })
      if (optionIndex > -1) {
        let oldData = this.chooseOptionList[optionIndex].option
        let oldIndex = this.optionList[i].options.findIndex((v) => {
          return v.Value == oldData.Value
        })

        this.$set(this.optionList[i].options[oldIndex], 'checked', false)
        this.$set(this.optionList[i].options[index], 'checked', true)
        this.chooseOptionList[optionIndex].option = data
      } else {
        this.chooseOptionList.push({
          Name: classify.Name,
          option: data
        })
        this.$set(this.optionList[i].options[index], 'checked', true)
      }

      this.getOptionName()
      this.getOptionPrice()
    },
    getOptionName() {
      let nameList = []
      this.chooseOptionList.forEach((v) => {
        let name = v.Name + '：' + v.option.Value
        nameList.push(name)
      })
      this.chooseOptionName = nameList.join(';')
    },
    getOptionPrice() {
      let checkedIds = this.chooseOptionList
        .map((v) => {
          return v.option.ID
        })
        .sort((a, b) => {
          return a - b
        })
        .join('-')
      let index = this.optionPriceList.findIndex((v) => {
        let priceId = v.SpecificationsID.split('-')
          .sort((a, b) => {
            return a - b
          })
          .join('-')
        return priceId == checkedIds
      })
      if (index > -1) {
        this.optionPrice = this.optionPriceList[index].Price
        this.MinPrice = this.optionPriceList[index].MinPrice
        this.optionStock = this.optionPriceList[index].Stock
        this.optionInfo = this.optionPriceList[index]
      }
    },
    toPath(path, query) {
      this.$router.push({
        name: path,
        query
      })
    },
    toTop() {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    showShipping() {
      this.showShippingPopup = true
    },
    cancel() {
      this.showShippingPopup = false
      this.showCarPopup = false
    }
  }
}
</script>