<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-form :model="form" inline>
        <el-form-item :label="$t('shang-pin-ming-cheng')" prop="name">
          <el-input
            v-model="form.title"
            :placeholder="$t('qing-shu-ru-shang-pin-ming-cheng')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('shang-pin-id')" prop="id">
          <el-input
            v-model="form.id"
            :placeholder="$t('qing-shu-ru-shang-pin-id')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('shang-pin-fen-lei')" prop="category">
          <el-select v-model="form.category1" filterable @change="handleCategory1Change" clearable>
            <el-option
              v-for="(item, i) in categoryList"
              :key="i"
              :value="item.ID"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-select
            v-if="form.category1"
            v-model="form.category2"
            filterable
            @change="handleCategory2Change"
            style="margin-left: 10px"
            clearable
          >
            <el-option
              v-for="(item, i) in childCategory"
              :key="i"
              :value="item.ID"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-select
            v-if="form.category2"
            v-model="form.category3"
            filterable
            @change="handleCategory3Change"
            style="margin-left: 10px"
            clearable
          >
            <el-option
              v-for="(item, i) in subChildCategory"
              :key="i"
              :value="item.ID"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">{{
            $t('cha-xun')
          }}</el-button>
          <el-button @click="reset">{{ $t('zhong-zhi') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-btns">
          <el-button type="primary" @click="batchAdd" :disabled="!isShopAuth">{{
            $t('pi-liang-tian-jia')
          }}</el-button>
        </div>
        <div class="table-container">
          <el-table
            :data="tableData"
            v-loading="tableLoading"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('shang-pin-id-0')"
              prop="ID"
              width="120px"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('feng-mian-tu')"
              prop="MainImage"
              width="120px"
            >
              <template slot-scope="scope">
                <div class="image-container">
                  <el-image
                    :src="scope.row.MainImage"
                    :preview-src-list="[scope.row.MainImage]"
                    fit="contain"
                    style="width: 60px; height: 60px;"
                  ></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('shang-pin-ming-cheng')"
              prop="name"
            >
              <template slot-scope="scope">
                {{
                  scope.row.ProductTranslation &&
                  scope.row.ProductTranslation[0]
                    ? scope.row.ProductTranslation[0].Name
                    : ''
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('fen-lei')"
              prop="orderNumber"
              width="140px"
            >
              <template slot-scope="scope">
                {{ scope.row.ProductCategory.ParentID }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('er-ji-fen-lei')"
              prop="orderNumber"
              width="140px"
            >
              <template slot-scope="scope">
                {{ getCategoryName(scope.row.ProductCategory) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('cai-gou-jia-ge')"
              prop="SalePrice"
              width="140px"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('cao-zuo')"
              width="140px"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="addProductToShop(scope.row)"
                  :disabled="!isShopAuth"
                  >{{ $t('tian-jia') }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      :title="$t('xiu-gai-shang-pin')"
      :visible.sync="addDialog"
      width="460px"
      class="table-dialog"
    >
      <el-form :model="editForm" ref="editForm">
        <el-form-item
          :label="$t('li-run-bi-li')"
          prop="ProfitPercentage"
          required
        >
          <el-input
            v-model="editForm.ProfitPercentage"
            :placeholder="$t('qing-shu-ru-li-run-bi-li')"
            :rules="[{ validator: checkProfit }]"
          >
            <template slot="append">%</template>
          </el-input>
          <div class="form-desc">
            {{
              $t(
                'jiang-xuan-zhong-de-shang-pin-fa-bu-dao-ni-de-dian-pu-bing-tian-xie-li-run-bi-li'
              )
            }}
          </div>
          <div class="form-desc" v-if="maxProfitRate">
            {{ $t('zui-da-li-run-bi-li') }}: {{maxProfitRate}}%
          </div>
        </el-form-item>
        <el-form-item :label="$t('zhe-kou-ri-qi')" prop="date">
          <el-date-picker
            style="width: 100%"
            v-model="editForm.date"
            type="daterange"
            :start-placeholder="$t('kai-shi-ri-qi')"
            :end-placeholder="$t('jie-shu-ri-qi')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          :label="$t('zhe-kou-bi-li')"
          prop="DiscountPercentage"
        >
          <el-input
            v-model="editForm.DiscountPercentage"
            :placeholder="$t('qing-shu-ru-zhe-kou-bi-li')"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">{{ $t('qu-xiao') }}</el-button>
        <el-button type="primary" @click="doAdd">{{
          $t('que-ding-0')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { productList, addProduct, getCategoryList, shopInfo } from '@/api/shop'
import moment from 'moment'
export default {
  data() {
    return {
      form: {
        title: '',
        id: '',
        category1: '',
        category2: '',
        category3: '',
        category: ''
      },
      tableLoading: false,
      editForm: {
        date: [],
        ProfitPercentage: '',
        DiscountPercentage: ''
      },
      addType: '',
      tableData: [],
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 123
      },
      categoryList: [],
      statusList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: 1,
          label: this.$t('shang-jia')
        },
        {
          value: 2,
          label: this.$t('xia-jia')
        }
      ],
      detailData: {},
      paramDialog: false,
      addDialog: false,
      addIds: [],
      maxProfitRate: 0,
      childCategory: [],
      subChildCategory: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    isShopAuth() {
      return this.$store.state.isShopAuth
    }
  },
  mounted() {
    this.init()
    this.initCategory()
  },
  created() {
    this.getShopInfo()
  },
  methods: {
    initCategory() {
      getCategoryList({
        current: 1,
        pageSize: 10000,
        ParentID: "0"
      }).then((res) => {
        this.categoryList = res.data.Items.map((v) => {
          return {
            ...v,
            name: v.ProductCategoryTranslations.find(
              val => val.Language === this.lang
            )?.Name
          }
        })
      })
    },
    init() {
      this.tableLoading = true
      productList({
        current: this.page.current,
        pageSize: this.page.size,
        title: this.form.title,
        Rand: 1,
        Distinct: 1,
        ID: this.form.id || null,
        CategoryID: this.form.category || null
      }).then((res) => {
        this.tableLoading = false
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    search() {
      this.page.page = 1
      this.page.size = 10
      this.init()
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {
      this.page.size = size
      this.init()
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    },
    showParams(data) {
      this.paramDialog = true
    },
    removeProduct(data) {
      this.$confirm(
        this.$t('que-ren-shan-chu-gai-shu-ju'),
        this.$t('que-ding')
      ).then(() => {})
    },
    addProductToShop(data) {
      this.addType = 2
      this.addDialog = true
      this.addIds = [data.ID]
      this.editForm = {
        date: [],
        ProfitPercentage: '',
        DiscountPercentage: ''
      }
    },
    batchAdd() {
      if (this.selectRows.length === 0) {
        this.$message.warning(this.$t('qing-gou-xuan-shu-ju'))
        return
      }
      this.addType = 2
      this.addDialog = true
      this.getShopInfo()
      this.addIds = this.selectRows.map((v) => {
        return v.ID
      })
      this.editForm = {
        date: [],
        ProfitPercentage: '',
        DiscountPercentage: ''
      }
    },
    doAdd() {
      this.$refs.editForm.validate((vali) => {
        if (vali) {
          let date = this.editForm.date
          addProduct({
            ProductID: this.addIds,
            ProfitPercentage: parseFloat(this.editForm.ProfitPercentage),
            DiscountStartDate: moment(date[0]).format('YYYY-MM-DD'),
            DiscountEndDate: moment(date[1]).format('YYYY-MM-DD'),
            DiscountPercentage: parseFloat(this.editForm.DiscountPercentage)
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('tian-jia-cheng-gong'))
              this.selectRows = []
              this.addIds = []
              this.addDialog = false
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    reset() {
      this.form = {
        title: '',
        id: '',
        category1: '',
        category2: '',
        category3: '',
        category: ''
      }
      this.childCategory = []
      this.subChildCategory = []
      this.search()
    },
    getCategoryName(data) {
      return data.ProductCategoryTranslations.find((v) => {
        return v.Language == this.lang
      }).Name
    },
    getShopInfo() {
      shopInfo().then(res => {
        if(res.code === 0) {
          this.maxProfitRate = res.data.ShopLevel?.ProfitRate || 0
          this.editForm.ProfitPercentage = this.maxProfitRate.toString()
        }
      })
    },
    checkProfit(val) {
      if(val > this.maxProfitRate) {
        return this.$t('li-run-bi-li-bu-neng-gao-yu') + this.maxProfitRate + '%'
      }
      return true
    },
    handleCategory1Change(val) {
      this.form.category2 = ''
      this.form.category3 = ''
      this.childCategory = []
      this.subChildCategory = []
      
      if (!val) {
        this.form.category = ''
        return
      }
      
      getCategoryList({
        current: 1,
        pageSize: 10000,
        ParentID: val.toString()
      }).then(res => {
        this.childCategory = res.data.Items.map(v => {
          return {
            ...v,
            name: v.ProductCategoryTranslations.find(
              t => t.Language === this.lang
            )?.Name
          }
        })
      })
      
      this.form.category = val
      this.search()
    },
    handleCategory2Change(val) {
      this.form.category3 = ''
      this.subChildCategory = []
      
      if (!val) {
        this.form.category = this.form.category1
        return
      }
      
      getCategoryList({
        current: 1,
        pageSize: 10000,
        ParentID: val.toString()
      }).then(res => {
        this.subChildCategory = res.data.Items.map(v => {
          return {
            ...v,
            name: v.ProductCategoryTranslations.find(
              t => t.Language === this.lang
            )?.Name
          }
        })
      })
      
      this.form.category = val
      this.search()
    },
    handleCategory3Change(val) {
      if (!val) {
        this.form.category = this.form.category2
      } else {
        this.form.category = val
      }
      this.search()
    }
  }
}
</script>
<style scoped>
.image-container {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-image) {
  max-width: 100%;
  max-height: 100%;
}

.form-desc {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}
</style>