<template>
  <div class="wap-search" :class="pageType == 3 ? 'white' : ''">
    <div class="top-search flex-center">
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="input flex-center flex-1">
        <i class="el-icon-search"></i>
        <Field
          v-model="keyword"
          :placeholder="$t('qing-shu-ru-shang-pin')"
          @input="changeKeyword"
          clearable
        ></Field>
      </div>
      <div class="btn" @click="search">{{ $t('sou-suo-0') }}</div>
    </div>

    <div class="result-box" v-if="keyword">
      <div class="result-line" @click="searchShop" v-show="!showShop">
        <i class="el-icon-s-shop"></i>
        <span class="space">“{{keyword}}”</span>
        <span>{{ $t('xiang-guan-de-dian-pu') }}</span>
      </div>
      <div class="result-line" v-show="showShop">
        <span>{{ $t('wei-nin-zhao-dao-0') }}</span>
        <span class="space">“{{keyword}}”</span>
        <span>{{ $t('xiang-guan-de-dian-pu-resultlistlength-jia', [resultList.length]) }}</span>
      </div>

      <div class="result-line link" v-for="(item, i) in resultList" :key="`product-${i}`" @click="toDetail(item)" v-show="!showShop">
        {{item.ProductTranslation
                ? item.ProductTranslation[0].Name
                : ''}}
      </div>
      
      <div class="shop-list" v-for="(item, i) in resultList" :key="`shop${i}`"  v-show="showShop">
        <div class="shop">
          <div class="flex-center top">
            <div class="img">
              <img :src="item.Logo" />
            </div>
            <div class="flex-1 flex-center-between">
              <div class="flex-1">
                <div class="name">{{ item.Name }}</div>
                <div class="flex-center-between">
                  <div>
                    <div class="info">
                      {{ $t('guan-zhu-0') }}
                      0
                    </div>
                    <div class="info">
                      {{ $t('shang-pin') }}
                      0
                    </div>
                  </div>
                </div>
              </div>
              <el-button @click="toShop(item)"
                >{{ $t('fang-wen-shang-dian')
                }}<i class="el-icon-arrow-right"></i
              ></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="result-list" v-if="isInit">
      <List
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
        v-if="resultList.length > 0"
      >
        <goodsList
          v-if="pageType != 3"
          :list="resultList"
          :showEdit="true"
          type="seller"
          @edit="editProduct"
        ></goodsList>
        <userGoodsList v-else :list="resultList"></userGoodsList>
      </List>

      <Empty v-if="resultList.length == 0" :description="$t('zan-wu-shu-ju')"></Empty>
    </div>

    <div class="history" v-else>
      <div class="flex-center-between">
        <div>{{ $t('li-shi-sou-suo') }}</div>
        <div class="flex-center" @click="removeHistory">
          <i class="el-icon-delete"></i>
          {{ $t('shan-chu') }}
        </div>
      </div>

      <div class="history-list flex-wrap">
        <div
          class="tag"
          v-for="(item, i) in historyList"
          :key="i"
          @click="chooseHistory(item)"
        >
          {{ item }}
        </div>
      </div>

      <Empty v-if="historyList.length == 0" :description="$t('zan-wu-shu-ju')"></Empty>
    </div>
  </div>
</template>
<script>
import { Button, List, Field, Empty, Dialog } from 'vant'
import headerBar from '@/components/header'
import { productList, shopProductList, addProduct, getShopList } from '@/api/shop'
import { getUserId } from '@/utils/auth'
import goodsList from '@/components/goodsList/wapList.vue'
import userGoodsList from '@/components/goodsList/wapUserList.vue'
export default {
  components: {
    Button,
    goodsList,
    userGoodsList,
    headerBar,
    List,
    Field,
    Empty
  },
  data() {
    return {
      pageType: '', // 1: 卖家店铺商品搜索， 2： 卖家产品库搜索, 3：买家搜索
      keyword: '',
      resultList: [],
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      historyList: [],
      timer: '',
      resultList: [],
      showShop: false
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.pageType = this.$route.query.type
    let history = sessionStorage.getItem('searchHistory')
    if (history) {
      this.historyList = JSON.parse(history)
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    search() {
      this.page.current = 1
      let history = this.historyList.concat([this.keyword])
      sessionStorage.setItem('searchHistory', JSON.stringify(history))
      this.init()
    },
    chooseHistory(data) {
      this.keyword = data
      this.search()
    },
    removeHistory() {
      this.historyList = []
      sessionStorage.setItem('searchHistory', '')
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    async init(isAdd) {
      this.loading = true
      let res = ''
      if (this.pageType == 1) {
        res = await shopProductList({
          current: this.page.page,
          pageSize: this.page.limit,
          Title: this.keyword,
          UId: parseFloat(getUserId())
        })
      } else if (this.pageType == 2) {
        res = await productList({
          current: this.page.current,
          pageSize: this.page.size,
          title: this.keyword
        })
      } else if (this.pageType == 3) {
        res = await shopProductList({
          current: this.page.page,
          pageSize: this.page.limit,
          Title: this.keyword
        })
      }
      if (res) {
        if (isAdd) {
          this.resultList = this.resultList.concat(res.data.Items)
        } else {
          this.resultList = res.data.Items
        }
        this.totalPage = res.data.Pagination ? res.data.Pagination.totalPage : 1
        if (this.totalPage <= this.page.current) {
          this.finished = true
        }
        this.isInit = true
        this.loading = false
      } else {
        this.isInit = true
        this.loading = false
      }
    },
    editProduct(data) {
      if (this.pageType == 1) {
        this.$router.push({
          name: 'wapEditProduct',
          query: {
            ids: data.ID,
            status: data.Status,
            type: 'one'
          }
        })
      } else {
        Dialog.confirm({
          title: this.$t('que-ren'),
          message: this.$t('que-ren-tian-jia-gai-shang-pin')
        }).then(() => {
          addProduct({
            ProductID: [parseFloat(data.ID)]
          }).then((res) => {
            if (res.code == 0) {
              this.$toast(this.$t('tian-jia-cheng-gong'))
            } else {
              this.$toast(res.msg)
            }
          })
        })
      }
    },
    changeKeyword() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.searchProduct()
      }, 400)
    },
    searchProduct() {
      if (!this.keyword) {
        this.resultList = []
        return
      }
      this.showShop = false
      shopProductList({
        Title: this.keyword,
        pageSize: 30
      }).then(res => {
        this.resultList = res.data.Items
      })
    },
    searchShop() {
      if (!this.keyword) {
        this.resultList = []
        return
      }
      this.resultList = []
      this.showShop = true
      getShopList({
        Title: this.keyword,
        pageSize: 30
      }).then(res => {
        this.resultList = res.data.Items
      })
    },
     toDetail(data) {
      this.keyword = ''
      this.$router.replace({
        name: 'wapUserProductDetail',
        query: {
          id: data.ID
        }
      })
    },
    toShop(data) {
      this.$router.replace({
        name: 'wapShopInfo',
        query: {
          id: data.ID
        }
      })
    },
  }
}
</script>