<template>
  <div class="shop-search">
    <div class="goods-main">
      <div class="result-line" >
        <span>{{ $t('wei-nin-zhao-dao') }}</span>
        <span class="space">“{{keyword}}”</span>
        <span>{{ $t('xiang-guan-de-dian-pu-shoplistlength-jia', [shopList.length]) }}</span>
      </div>

      <div class="shop-list">
         <div class="shop flex-center" v-for="(item, i) in shopList" :key="i">
              <div class="img">
                <img :src="item.Logo" alt=""  />
              </div>
              <div class="flex-1">
                <div class="name">{{ item.Name }}</div>
                <div class="flex-center-between">
                  <div>
                    <!-- <div class="info">商品：0</div> -->
                    <div class="info">
                      {{ $t('xiao-liang') }}
                      {{ item.TotalDeal }}
                    </div>
                    <div class="info">
                      {{ $t('ping-fen') }}
                      {{ item.GoodsScore }}
                    </div>
                  </div>
                  <el-button @click="toShop(item)"
                    >{{ $t('fang-wen-shang-dian')
                    }}<i class="el-icon-arrow-right"></i
                  ></el-button>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getShopList } from '@/api/shop'
export default {
  components: {
  },
  data() {
    return {
      tabIndex: 1,
      sortType: 'desc',
      shopList: [],
      form: {
        sort: ''
      },
      page: {
        current: 1,
        size: 30,
        total: 0
      },
      keyword: ''
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  watch: {
    $route(val) {
      this.keyword = this.$route.query.keyword
      this.initShop()
    }
  },
  mounted() {
    this.keyword = this.$route.query.keyword
    this.initShop()
  },
  methods: {
    initShop() {
      getShopList({
        pageSize: 1000,
        Title: this.keyword,
      }).then((res) => {
        this.shopList = res.data.Items
      })
    },
      toShop(data) {
      this.$router.push({
        name: 'shopInfo',
        query: {
          id: data.ID
        }
      })
    },
    
  }
}
</script>