<template>
  <div class="wap-library">
    <headerBar :title="$t('shang-pin-ping-lun')" @back="back"></headerBar>
    <div class="evalute-list">
      <List
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
        v-if="dataList.length > 0"
      >
        <div class="list" v-for="(item, i) in dataList" :key="i">
          <div class="top flex-center-between">
            <div class="flex-center">
              <div class="user-header header1 mini"></div>
              <div class="name">{{ item.userName }}</div>
            </div>
            <div>
              <Rate :size="18" color="#ffd21e" v-model="item.Score"></Rate>
            </div>
          </div>
          <div class="time">{{ item.CreatedAt }}</div>
          <div class="content">{{ item.Content }}</div>
        </div>
      </List>
      <Empty v-if="dataList.length == 0" :description="$t('zan-wu-shu-ju')"></Empty>
    </div>
  </div>
</template>
<script>
import { Button, Popup, Checkbox, Field, Rate, List, Empty } from 'vant'
import headerBar from '@/components/header'
import { getProductRatingList } from '@/api/shop'
import goodsList from '@/components/goodsList/wapList.vue'
import moment from 'moment'
export default {
  components: {
    Button,
    goodsList,
    headerBar,
    Rate,
    Checkbox,
    Popup,
    Field,
    List,
    Empty
  },
  data() {
    return {
      form: {
        category: '',
        category1: '',
        category2: '',
        category3: '',
        categoryName1: '',
        categoryName2: '',
        categoryName3: ''
      },
      checkedAll: false,
      showPopup: false,
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      dataList: []
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    // this.init()
  },
  methods: {
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      getProductRatingList({
        current: this.page.current,
        pageSize: this.page.size
      })
        .then((res) => {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    back() {
      this.$router.go(-1)
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>