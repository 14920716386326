<template>
  <div class="page-form recharge">
    <el-card class="form-card">
      <el-form :model="form">
        <el-form-item class="qrcode-item" label="" prop="qrcode">
          <div class="flex-center-start">
            <div class="qrcode" ref="qrcode" id="qrcode"></div>
            <div class="flex-column-center">
              <span>{{ $t('sao-miao-er-wei-ma') }}</span>
              <el-button>{{ $t('bao-cun-er-wei-ma') }}</el-button>
            </div>
          </div>
        </el-form-item>

        <!-- 币种选择 - 改用button group -->
        <el-form-item :label="$t('bi-zhong')" prop="coinType">
          <el-button-group class="coin-buttons">
            <el-button
              v-for="coin in coinList"
              :key="coin.key"
              :type="form.coinType === coin.key ? 'primary' : ''"
              @click="handleCoinChange(coin.key)"
              :data-coin="coin.key"
            >
              {{ coin.name }}
            </el-button>
          </el-button-group>
        </el-form-item>

        <!-- 网络选择 - 改用button group -->
        <el-form-item :label="$t('wang-luo')" prop="network" v-if="form.coinType">
          <el-button-group class="network-buttons">
            <el-button
              v-for="network in currentNetworks"
              :key="network.key"
              :type="form.network === network.key ? 'primary' : ''"
              @click="handleNetworkChange(network.key)"
            >
              {{ network.name }}
            </el-button>
          </el-button-group>
        </el-form-item>

        <el-form-item :label="$t('chong-zhi-di-zhi')" prop="address">
          <el-input
            v-model="form.address"
            :placeholder="$t('qing-shu-ru-chong-zhi-di-zhi-0')"
            readonly
          >
            <template slot="suffix">
              <el-button class="copy-icon" @click="copyData" type="text">
                {{ $t('fu-zhi') }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>

        <!-- 其他表单项保持不变 -->
        <el-form-item :label="$t('chong-zhi-shu-liang')" prop="number">
          <el-input
            v-model="form.number"
            clearable
            :placeholder="$t('qing-shu-ru-chong-zhi-shu-liang')"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('shang-chuan-tu-pian-shang-chuan-zhi-fu-xiang-qing-jie-tu')"
          prop="img"
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="uploadUrl"
            name="file"
            :headers="headers"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="form.logo" :src="getBaseUrl(form.logo)" class="avatar" />
            <i v-else class="el-icon-camera-solid" style="font-size: 30px; color: #aaa"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button class="submit-btn" @click="submit" type="primary">
            {{ $t('ti-jiao') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { getRechargeInfo, addRecharge } from '@/api/user'
import Clipboard from 'clipboard'
import { getToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'

export default {
  data() {
    return {
      form: {
        coinType: '', // 币种
        network: '', // 网络
        address: '', // 充值地址
        number: '', // 充值数量
        logo: '' // 上传图片
      },
      coinList: [], // 币种列表
      networkMap: {}, // 网络映射表
      addressList: [], // 后端返回的地址列表
      currentNetworks: [], // 当前币种可用的网络列表
      headers: {}
    }
  },
  computed: {
    realMoney() {
      return this.form.number ? parseFloat(this.form.number).toFixed(2) : '0.00'
    },
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {
      getRechargeInfo().then((res) => {
        if (res.code === 0 && res.data.Items) {
          this.addressList = res.data.Items
          this.formatPaymentData(res.data.Items)
        }
      })
    },
    formatPaymentData(addressList) {
      // 临时存储币种和网络的映射
      const tempNetworkMap = {}
      const tempCoinSet = new Set()

      // 使用Map来存储唯一的网络-地址组合
      const uniqueNetworks = new Map()

      // 遍历地址列表,提取币种和网络信息
      addressList.forEach(item => {
        const network = item.RechargeNetwork
        if (network) {
          // 使用网络作为key来确保唯一性
          if (!uniqueNetworks.has(network)) {
            uniqueNetworks.set(network, item)

            const [coin, protocol] = network.split('-')

            // 添加币种到集合
            tempCoinSet.add(coin)

            // 初始化币种的网络列表
            if (!tempNetworkMap[coin]) {
              tempNetworkMap[coin] = []
            }

            // 添加网络信息
            tempNetworkMap[coin].push({
              key: network,
              name: protocol,
              address: item.RechargeAddress,
              id: item.ID
            })
          }
        }
      })

      // 手动添加银行卡选项
      tempCoinSet.add('BANK')
      tempNetworkMap['BANK'] = [{
        key: 'BANK-TRANSFER',
        name: this.$t('线下转账'), // 或者使用固定文本 '线下转账'
        address: ''
      }]

      // 设置币种列表
      this.coinList = Array.from(tempCoinSet).map(coin => ({
        key: coin,
        name: coin === 'BANK' ? this.$t('银行卡') : coin // 或者使用固定文本 '银行卡'
      }))

      // 设置网络映射
      this.networkMap = tempNetworkMap
    },
    // 修改币种选择处理方法
    handleCoinChange(coinType) {
      this.form.coinType = coinType
      this.form.network = ''
      this.form.address = ''
      this.currentNetworks = this.networkMap[coinType] || []

      // 如果选择了银行卡，调用客服方法
      if (coinType === 'BANK') {
        // 获取父组件实例
        let homeComponent = this.findHomeComponent(this)
        if (homeComponent) {
          homeComponent.toKefu()
        }
      }

      // 清空二维码
      let dom = document.getElementById('qrcode')
      if (dom) {
        dom.innerHTML = ''
      }
    },
    // 查找 home 组件
    findHomeComponent(component) {
      let parent = component.$parent
      while (parent) {
        if (parent.$options.name === 'home') {
          return parent
        }
        parent = parent.$parent
      }
      return null
    },
    // 修改网络选择处理方法
    handleNetworkChange(network) {
      this.form.network = network
      // 从当前网络列表中找到对应的网络信息
      const networkInfo = this.currentNetworks.find(item => item.key === network)
      if (networkInfo) {
        this.form.address = networkInfo.address
        this.resetQrcode()
      }
    },
    resetQrcode() {
      let dom = document.getElementById('qrcode')
      if (dom) {
        dom.innerHTML = ''
      }
      if (this.form.address) {
        new QRCode(this.$refs.qrcode, {
          text: this.form.address,
          width: 120,
          height: 120,
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    uploadSuccess(file) {
      this.form.logo = file.data.FileName
    },
    copyData() {
      let data = this.form.address
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$message.success(this.$t('fu-zhi-cheng-gong'))
    },
    submit() {
      if (!this.form.coinType) {
        this.$message.warning(this.$t('请选择币种'))
        return
      }
      if (!this.form.network) {
        this.$message.warning(this.$t('请选择网络'))
        return
      }
      if (!this.form.number) {
        this.$message.warning(this.$t('请输入充值数量'))
        return
      }
      if (!this.form.logo) {
        this.$message.warning(this.$t('请上传支付凭证'))
        return
      }

      addRecharge({
        Amount: parseFloat(this.form.number),
        PaymentVoucher: this.form.logo
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('ti-jiao-cheng-gong'))
          this.form.logo = ''
          this.form.number = ''
          this.$refs.form.resetFields()
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>

<style>
/* 使用普通的CSS而不是SCSS */
.recharge .qrcode-item {
  margin-bottom: 20px;
}

.recharge .qrcode {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}

.recharge .el-radio-group {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}

.recharge .el-radio {
  margin: 6px;
}

.recharge .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recharge .el-upload:hover {
  border-color: #409EFF;
}

.recharge .avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.recharge .el-radio.is-checked .el-radio__label {
  color: #409EFF;
}

/* 添加按钮组样式 */
.recharge .coin-buttons,
.recharge .network-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.recharge .el-button-group {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.recharge .el-button-group > .el-button {
  margin-right: 0;
  margin-left: 0;
  border-radius: 4px !important;
  min-width: 100px;
}

/* 移除按钮组默认样式 */
.recharge .el-button-group > .el-button:not(:first-child):not(:last-child) {
  border-radius: 4px !important;
}
.recharge .el-button-group > .el-button:first-child {
  border-radius: 4px !important;
}
.recharge .el-button-group > .el-button:last-child {
  border-radius: 4px !important;
}

/* 按钮hover效果 */
.recharge .el-button:hover {
  border-color: #409EFF;
  color: #409EFF;
}

/* 选中状态样式 */
.recharge .el-button--primary {
  background-color: #409EFF;
  border-color: #409EFF;
  color: white;
}

.recharge .el-button--primary:hover {
  background-color: #66b1ff;
  border-color: #66b1ff;
  color: white;
}

/* 按钮大小和间距 */
.recharge .el-button {
  padding: 8px 15px;
  font-size: 14px;
  height: 36px;
  line-height: 1;
}

/* 表单项间距 */
.recharge .el-form-item {
  margin-bottom: 20px;
}

/* 为银行卡选项添加特殊样式 */
.recharge .el-button-group > .el-button[data-coin="BANK"] {
  background-color: #67c23a;
  border-color: #67c23a;
  color: white;
}

.recharge .el-button-group > .el-button[data-coin="BANK"]:hover {
  background-color: #85ce61;
  border-color: #85ce61;
  color: white;
}
</style>