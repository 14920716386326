<template>
  <div class="wap-message">
    <template v-if="!isChat">
      <headerBar
        :title="$t('xiao-xi-lie-biao')"
        v-if="pageType === 'user'"
        @back="back"
      ></headerBar>
      <div class="title" v-else>{{ $t('common.tab4') }}</div>
      <div class="message-list">
        <div
          class="message flex-center"
          v-for="(item, i) in messageList"
          :key="i"
          @click="toDetail(item)"
        >
          <div class="avatar">
            <div class="user-header header-2"></div>
          </div>
          <div class="info">
            <div class="name flex-center-between">
              <span>{{ showName(item) }}</span>
              <span class="unread-count" v-if="item.ReceiverUnreadCount > 0 && item.ReceiverID == uid">
                {{ item.ReceiverUnreadCount }}
              </span>
              <span class="unread-count" v-if="item.SenderUnreadCount > 0 && item.SenderID == uid">
                {{ item.SenderUnreadCount }}
              </span>
            </div>
            <div class="flex-center">
              <div class="content text-ellipsis">
                {{ getLastMessageContent(item) }}
              </div>
              <div class="date">{{ resetDate(item.LastMessageTime) }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <router-view v-else @update-unread="init"></router-view>
  </div>
</template>
<script>
import { conversationList } from '@/api/shop'
import { getUserId } from '@/utils/auth'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    headerBar
  },
  data() {
    return {
      messageList: [],
      pageType: '',
      uid: getUserId()
    }
  },
  mounted() {
    this.init()
    this.pageType = this.$route.name === 'wapUserMessage' ? 'user' : ''
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      conversationList({
        current: 1,
        pageSize: 30
      }).then((res) => {
        console.log('Conversation list:', res.data.Items)
        console.log('Current user ID:', this.uid)
        this.messageList = res.data.Items.map(item => {
          console.log('Item unread counts:', {
            senderUnread: item.SenderUnreadCount,
            receiverUnread: item.ReceiverUnreadCount,
            senderId: item.SenderID,
            receiverId: item.ReceiverID
          })
          return item
        })
      })
    },
    resetDate(date) {
      let today = moment().format('YYYY-MM-DD')
      if (date.indexOf(today) === 0) {
        return date.substring(11, 16)
      } else {
        return date.substring(0, 16)
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'wapChat',
        query: {
          uid: data.SenderID === Number(getUserId()) ? data.ReceiverID : data.SenderID,
          name: this.showName(data)
        }
      })
    },
    showName(item) {
      const uid = Number(getUserId())
      if (uid !== item.ReceiverID) {
        if (item.ReceiverShop?.ID > 0) {
          return item.ReceiverShop.Name
        } else {
          return item.ReceiveUser?.Email || item.ReceiveUser?.Phone
        }
      } else {
        if (item.SenderShop?.ID > 0) {
          return item.SenderShop.Name
        } else {
          return item.SendUser?.Email || item.SendUser?.Phone
        }
      }
    },
    getUnreadCount(item) {
      const currentUserId = getUserId()
      if (currentUserId === item.SenderID) {
        return item.SenderUnreadCount || 0
      } else if (currentUserId === item.ReceiverID) {
        return item.ReceiverUnreadCount || 0
      }
      return 0
    },
    getLastMessageContent(conversation) {
      const currentUserId = Number(getUserId())
      const senderMessage = conversation.LastSenderMessage
      const receiverMessage = conversation.LastReceiverMessage
      
      // 如果两条消息都不存在，返回空字符串
      if (!senderMessage && !receiverMessage) return ''
      
      // 如果只有一条消息存在
      if (!senderMessage) {
        return this.formatMessage(receiverMessage, currentUserId === receiverMessage.SenderID)
      }
      if (!receiverMessage) {
        return this.formatMessage(senderMessage, currentUserId === senderMessage.SenderID)
      }
      
      // 比较消息ID，确定最后一条消息
      const lastMessage = senderMessage.ID > receiverMessage.ID ? senderMessage : receiverMessage
      const isCurrentUserSender = currentUserId === lastMessage.SenderID
      
      return this.formatMessage(lastMessage, isCurrentUserSender)
    },
    
    formatMessage(message, isCurrentUserSender) {
      if (message.Type === 2) {
        return `[${this.$t('shang-pin-zi-xun')}]`
      }
      return isCurrentUserSender ? `${this.$t('nin')}：${message.Content}` : message.Content
    }
  },
  computed: {
    isChat() {
      return this.$route.name === 'wapChat'
    }
  }
}
</script>
<style lang="less" scoped>
.message {
  padding: 12px;
  border-bottom: 1px solid #f5f5f5;
  
  &:active {
    background: #f5f5f5;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  
  .user-header {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.info {
  flex: 1;
  margin-left: 12px;
  
  .name {
    font-size: 14px;
    color: #333;
    margin-bottom: 4px;
  }
  
  .content {
    color: #999;
    font-size: 12px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .date {
    color: #999;
    font-size: 12px;
    margin-left: 8px;
    white-space: nowrap;
  }
}

.unread-count {
  background: #f56c6c;
  color: white;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 10px;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>