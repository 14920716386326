<template>
  <div v-if="isLoaded" id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
    <div class="chat-modal" v-if="showChat">
      <chat-view type="modal" @cancel="hideChat"></chat-view>
    </div>
    <message-box
      type="modal"
      v-if="showMessageBox"
      @cancel="hideChat"
    ></message-box>
  </div>
  <div v-else class="loading-container">
    <div class="loading-spinner"></div>
  </div>
</template>
<script>
import { configList, customization, getKefuList } from '@/api/user'
import { getToken, getUserType } from '@/utils/auth'
import chatView from '@/views/sellerH5/message/chat.vue'
import messageBox from '@/components/message/message.vue'
export default {
  components: {
    chatView,
    messageBox
  },
  data() {
    return {
      transitionName: '',
      logoUrl: require('@/assets/imgs/logo1.png'),
      isLoaded: true
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.transitionName = 'slide-right'
      } else if (to.meta.index > from.meta.index) {
        this.transitionName = 'slide-left'
      }
      let name = this.$route.name
      if (name == 'download') {
        this.hideKefu = true
      }
    },
    lang() {
      this.$store.dispatch('resetCategoryName', this.lang)
      // this.$store.dispatch('resetTabs')
      this.resetTabs()
    }
  },
  computed: {
    kefuLink() {
      return this.$store.state.lineKf
    },
    pageType() {
      return this.$store.state.pageType
    },
    isLogin() {
      return this.$store.state.isLogin
    },
    showChat() {
      return this.$store.state.showChat
    },
    langList() {
      return this.$store.state.langListBak
    },
    lang() {
      return this.$store.state.lang
    },
    showMessageBox() {
      return this.$store.state.showMessageBox
    }
  },
  beforeMount() {
    let width = document.body.clientWidth
    this.$store.state.pageType = width > 576 ? 'pc' : 'h5'

    if (getToken()) {
      this.$store.state.isLogin = true
    }
  },
  beforeDestroy() {},
  mounted() {
    window.addEventListener('resize', this.pageResize)
    setTimeout(() => {
      this.pageResize()
    }, 300)
    this.init()
    let locale = localStorage.getItem('locale') || 'en'
    // 从缓存获取logo
    let logo = localStorage.getItem('logo')
    if (logo) {
      this.$store.state.logo = logo
    }
    this.i18n.locale = locale
    let item = this.langList.find((v) => {
      return v.key == locale
    })
    if (item) {
      this.$store.state.langIcon = item.icon
      this.$store.state.langName = item.name
    }
    this.$store.dispatch('initLang')
  },
  async created() {
    try {
      const res = await configList()
      if (res.code === 0) {
        // 预加载logo
        await this.preloadImage(res.data.logo)
        this.$store.commit('SET_CONFIG', res.data)
        // this.isLoaded = true
      }
    } catch (error) {
      console.error('Failed to load config:', error)
      // 如果加载失败,也显示页面,避免白屏
      // this.isLoaded = true
    }
  },
  methods: {
    resetTabs() {
      this.$store.state.tabList = this.$store.state.tabList.map((v) => {
        return {
          ...v,
          meta: {
            ...v.meta,
            title: this.$t(v.meta.key)
          }
        }
      })
    },
    init() {
      configList().then((res) => {
        let data = res.data.Items
        // 分享链接
        this.$store.state.appPromotion = data.filter((v) => {
          return v.Name == 'app_promotion'
        })[0].Value

        // 语言
        this.$store.state.language = data.filter((v) => {
          return v.Name == 'language'
        })[0].Value
        // Logo
        this.$store.state.logo = data.filter((v) => {
          return v.Name == 'logo'
        })[0].Value
        if (this.$store.state.logo == '') {
          this.$store.state.logo = this.logoUrl
        } else {
          this.$store.state.logo = '/upload/' + this.$store.state.logo
        }
        // 把logo存到缓存
        localStorage.setItem('logo', this.$store.state.logo)

        // appName
        this.$store.state.appName = data.filter((v) => {
          return v.Name == 'app_name'
        })[0].Value
        window.document.title = this.$store.state.appName
        // 格式化
        this.$store.state.configData = data.reduce((acc, cur) => {
          acc[cur.Name] = cur.Value
          return acc
        }, {})
        console.log('configData:', this.$store.state.configData)
        
        // 触发配置加载完成事件
        this.$root.$emit('configLoaded', this.$store.state.configData)
      })
    },
    toKefu() {
      window.location.href = this.kefuLink
    },
    hideChat() {
      this.$store.state.showChat = false
      this.$store.state.showMessageBox = false
    },
    kfInit() {
      getKefuList().then((res) => {
        let data = res.data.Items
        if (data.length === 0) {
          return
        }
        if (data[0].Type == 'chatra') {
          // 预加载
          ;(function (d, w, c, token) {
            w.ChatraID = token
            var s = d.createElement('script')
            w[c] =
              w[c] ||
              function () {
                ;(w[c].q = w[c].q || []).push(arguments)
              }
            s.async = true
            s.src = 'https://call.chatra.io/chatra.js'
            if (d.head) d.head.appendChild(s)
            window.ChatraSetup = {
              startHidden: true
            }
          })(document, window, 'Chatra', data.Token)
          return
        }
        // 检查连接包含的字符串qiabot.html
        if (data[0].Link.indexOf('qiabot.html') > -1) {
          let src = 'https://a.wauda.net/starter.js'
          this.insertScript(src)
          return
        }

        if (data[0].Link.indexOf('lc.chat') > -1) {
          const regex = /\/(\d+)\//
          const match = data[0].Link.match(regex)
          if (match) {
            this.LiveChat = true
            this.license = match[1]
          }
        }
        if (data[0].Link.indexOf('kefu-global') > -1) {
          const regex = /\/(\d+)\//
          const match = data[0].Link.match(regex)
          let src = data[0].Link + '/packs/js/sdk.js'
          this.insertScript(src)
          this.chatwoot(data[0].Link)
        }
        if (data[0].Link.indexOf('tawk.to') > -1) {
          window.localStorage.setItem('propertyId', data[0].Token)
          window.localStorage.setItem('widgetId', data[0].IdentifierHash)
          this.$tawk()
          this.$tawkMessenger.$on('load', () => {
            console.log('加载完成')
            this.$store.state.tawkLoaded = true
            // place your code here
          })
        }
      })
    },
    pageResize() {
      let width = window.innerWidth
      let name = this.$route.name || 'index'
      let userType = getUserType()
      if (name === 'download') {
        return
      }
      if (width < 576) {
        //小于576px, 使用h5页面
        if (this.pageType == 'pc' || name.indexOf('wap') !== 0) {
          // 切换到h5首页
          let name = userType == 1 ? 'wapHome' : 'wapIndex'
          // let name = 'wapIndex'
          this.$router.push({
            name: name
          })
        }
        this.$store.state.pageType = 'h5'
      } else {
        if (this.pageType == 'h5' || name.indexOf('wap') == 0) {
          // 切换到pc首页
          let name = userType == 1 ? 'dashboard' : 'index'
          this.$router.push({
            name: name
          })
        }
        this.$store.state.pageType = 'pc'
      }
    },
    preloadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve()
        img.onerror = () => reject()
        img.src = src
      })
    }
  }
}
</script>


<style lang="less">
/* 页面切换动画效果 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 200ms;
  position: absolute;
  // backface-visibility: hidden;
  // perspective: 1000px;
}
.slide-right-enter,
.slide-right-enter-active {
  animation: bounce-left-in 200ms;
}
.slide-right-leave-active {
  animation: bounce-left-out 200ms;
}
.slide-left-enter,
.slide-left-enter-active {
  animation: bounce-right-in 200ms;
}
.slide-left-leave-active {
  animation: bounce-right-out 200ms;
}

@keyframes bounce-right-in {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-right-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes bounce-left-in {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce-left-out {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>