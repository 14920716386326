<template>
  <div class="page-message-box" :class="pageType">
    <div class="title flex-center-between">
      <div class="logo flex-center">
        <img :src="logo || logoUrl" alt="" />
        <span class="app-name">{{ appName }}</span>
      </div>
      <div class="name">{{ title }}</div>
      <div class="close" @click="close">
        <i class="el-icon-close"></i>
      </div>
    </div>

    <div class="main">
      <div class="left">
        <div
          class="user-list flex-center"
          v-for="(item, i) in userList"
          :key="i"
          @click="changeUser(item, i)"
          :class="activeIndex == i ? 'active' : ''"
        >
          <div class="header">
            <img :src="item.header || headerUrl" alt="" />
            <span
              class="red"
              v-if="item.UnreadMessageCount > 0 && item.ReceiverID == userId"
            ></span>
          </div>
          <div class="flex-1 right-content">
            <div class="name">{{ showName(item) }}</div>
            <div class="flex-center-between">
              <div class="content flex-1">
                {{ item.Content }}
              </div>
              <div class="time">{{ resetDate(item.LastMessageTime) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="message-box" id="chatMessageBox">
          <div
            class="list"
            v-for="(item, i) in messageArr"
            :key="i"
            :class="
              item.SenderID == userId
                ? 'flex-start-end header-right'
                : 'flex-start'
            "
          >
            <div class="header" v-if="item.SenderID != userId">
              <img :src="kefuUrl" alt="" />
            </div>
            <div class="content-outer">
              <div class="content" v-if="item.Type && !item.goodsInfo || item.Type != 2">
                {{ item.Type == 2 ? $t('shang-pin-xin-xi-huo-qu-zhong') + '...' : item.Content }}
              </div>
              <div class="content goods flex-center" v-else>
                <div class="img">
                  <img :src="item.goodsInfo.MainImage" alt="">
                </div>
                <div class="info">
                  <div class="goods-title">
                    {{ item.goodsInfo.ProductTranslation && item.goodsInfo.ProductTranslation[0]
                      ? item.goodsInfo.ProductTranslation[0].Name
                      : '' }}
                    </div>
                  <div class="flex-center-between">
                    <div class="price">${{ item.goodsInfo.SalePrice }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header" v-if="item.SenderID == userId">
              <img :src="headerUrl" alt="" />
            </div>
          </div>
        </div>

        <div class="goods-box flex-center" v-if="goodsId && !hideGoods && goodsInfo.ID">
          <i class="el-icon-close close" @click="hideGoodsModal"></i>
          <div class="img">
            <img :src="goodsInfo.MainImage" alt="">
          </div>
          <div class="info">
            <div class="goods-title">
              {{ goodsInfo.ProductTranslation && goodsInfo.ProductTranslation[0]
                ? goodsInfo.ProductTranslation[0].Name
                : '' }}
              </div>
            <div class="flex-center-between">
              <div class="price">${{ goodsInfo.SalePrice }}</div>
              <div class="goods-btn" @click="sendGoods">发送商品</div>
            </div>
          </div>
        </div>

        <div class="send-message flex-center">
          <el-input v-model="content" :disabled="!uid"></el-input>
          <el-button
            size="small"
            type="primary"
            @click="sendMsg"
            :disabled="!uid"
            >{{ $t('fa-song') }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserId, getToken } from '@/utils/auth'
import { conversationList, messageList, messageRead, shopProductList } from '@/api/shop'
import { initWebSocket } from '@/utils/common'
import moment from 'moment'
export default {
  data() {
    return {
      logoUrl: require('@/assets/imgs/logo.png'),
      headerUrl: require('@/assets/imgs/header/header2.png'),
      kefuUrl: require('@/assets/imgs/header/header3.png'),
      messageArr: [],
      title: '消息',
      userList: [],
      content: '',
      uid: '',
      client: null,
      webSocket: null,
      pageType: '',
      activeIndex: -1,
      hideGoods: false,
      goodsInfo: {}
    }
  },
  computed: {
    goodsId() {
      return this.$store.state.goodsId
    },
    logo() {
      return this.$store.state.logo
    },
    appName() {
      return this.$store.state.appName
    },
    userId() {
      return getUserId()
    }
  },
  watch: {
    $route() {
      this.initType()
    }
  },
  mounted() {
    this.initType()
    this.init()
    if (this.userId) {
      this.initSocket()
    }
    if (this.goodsId) {
      this.initGoods()
    }
  },
  methods: {
    initGoods() {
      shopProductList({
        ID: parseFloat(this.goodsId)
      }).then((res) => {
        let list = res.data.Items || []
        this.goodsInfo = list[0]
      })
    },
    initType() {
      let name = this.$route.name
      if (name == 'goodsDetail') {
        this.pageType = 'user'
        this.uid = this.$store.state.messageUid
        this.title = this.$store.state.messageTitle
      } else {
        this.pageType = 'seller'
      }
    },
    init(onlyList) {
      conversationList({
        current: 1,
        pageSize: 30
      }).then((res) => {
        this.userList = res.data.Items
        if (onlyList) {
          return
        }
        if (this.pageType == 'user') {
          //指定客服进入,如果消息列表存在对应对话，高亮，否则新增一条
          let index = this.userList.findIndex((v) => {
            return (
              (v.ReceiverID == this.userId && v.SenderID == this.uid) ||
              (v.ReceiverID == this.uid && v.SenderID == this.userId)
            )
          })
          if (index === -1) {
            this.userList.splice(0, 0, {
              Name: this.title,
              ReceiverID: this.userId,
              LastMessageTime: moment().format('YYYY-MM-DD HH:mm:ss'),
              ReceiverShop: {},
              SenderShop: {
                ID: this.uid,
                Name: this.title
              }
            })
            this.activeIndex = 0
          } else {
            this.activeIndex = index
          }
        } else {
          if (this.userList.length > 0) {
            let data = this.userList[0]
            this.uid = data.SenderID == this.userId ? data.ReceiverID : data.SenderID
            this.title = this.showName(this.userList[0])
            this.activeIndex = this.userList.findIndex((v) => {
              return v.SenderID == this.uid
            })
          }
        }
        this.initMessage()
      })
    },
    initMessage() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 30)
      form.append('ConversationID', this.uid)
      messageList(form).then((res) => {
        let list = res.data.Items.reverse()
        this.messageArr = list
        setTimeout(() => {
          this.toBottom()
        }, 300)
        this.initRead()
         // 获取商品信息
         this.messageArr.forEach((v, i) => {
          if (v.Type == 2) {
            this.getGoods(v, i)
          }
        })
      })
    },
    getGoods(data, i) {
      shopProductList({
        ID: parseFloat(data.Content)
      }).then((res) => {
        let list = res.data.Items
        if (list && list.length > 0) {
          let goodsInfo = list[0]
          this.$set(this.messageArr[i], 'goodsInfo', goodsInfo)
        }
      })
    },
    initRead() {
      let ids = this.messageArr
        .filter((v) => {
          return (
            (v.ReceiverID == this.userId && v.SenderID == this.uid) ||
            (v.ReceiverID == this.uid && v.SenderID == this.userId)
          )
        })
        .map((v) => {
          return v.ID
        })
      if (ids.length > 0) {
        messageRead({
          MessageID: ids
        })
      }
    },
    changeUser(data, i) {
      this.uid = data.SenderID == this.userId ? data.ReceiverID : data.SenderID
      this.title = this.showName(data)
      this.activeIndex = i
      this.initMessage()
    },
    async initSocket() {
      //初始化weosocket
      let client = await initWebSocket(this.userId, this.websocketonmessage)
      this.client = client
      this.webSocket = client.ws
    },
    websocketonmessage(e) {
      let redata = {}
      try {
        redata = JSON.parse(e.body)
        this.setListData(redata)
      } catch (e) {}
    },
    setListData(data) {
      // 判断是否是当前对话
      let isNowChat =
        (data.ReceiverID == this.userId && data.SenderID == this.uid) ||
        (data.ReceiverID == this.uid && data.SenderID == this.userId)
      
      if (isNowChat) {
        // 检查是否已存在相同的消息
        const existingMessage = this.messageArr.find(msg => msg.ID === data.ID);
        if (existingMessage) {
          return; // 如果消息已存在，直接返回
        }

        if (data.Type == 2) {
          if (this.goodsInfo.ID) {
            data.goodsInfo = this.goodsInfo;
          }
          this.messageArr.push(data);
          if (!this.goodsInfo.ID) {
            let length = this.messageArr.length;
            this.getGoods(data, length - 1);
          }
        } else {
          this.messageArr.push(data);
        }

        let ids = [data.ID];
        messageRead({
          MessageID: ids
        });
        
        setTimeout(() => {
          this.toBottom();
        }, 300);
      } else {
        this.init(true);
      }
    },
    sendGoods() {
       // 发送消息
       let message = {
        ReceiverID: parseInt(this.uid),
        Type: 2,
        Content: this.goodsId.toString(),
        token: getToken()
      }
      this.client.send(
        '/exchange/direct_logs/chat_queue',
        { 'content-type': 'application/json' },
        JSON.stringify(message)
      )

      this.hideGoods = true
    },
    sendMsg() {
      if (!this.content) {
        this.$toast.fail(this.$t('fa-song-nei-rong-bu-neng-wei-kong'))
        return
      }
      // 发送消息
      let message = {
        ReceiverID: parseInt(this.uid),
        Type: 0,
        Content: this.content,
        token: getToken()
      }
      this.client.send(
        '/exchange/direct_logs/chat_queue',
        { 'content-type': 'application/json' },
        JSON.stringify(message)
      )
      this.content = ''
    },
    close() {
      this.$store.state.showMessageBox = false
      if (this.webSocket && this.webSocket.close) {
        this.webSocket.close()
      }
    },
    showName(item) {
      let uid = getUserId()
      if (uid != item.ReceiverID) {
        if (item.ReceiverShop.ID > 0) {
          return item.ReceiverShop.Name
        } else {
          return item.ReceiveUser.Email || item.ReceiveUser.Phone
        }
      } else {
        if (item.SenderShop.ID > 0) {
          return item.SenderShop.Name
        } else {
          return item.SendUser.Email || item.SendUser.Phone
        }
      }
    },
    resetDate(date) {
      let today = moment().format('YYYY-MM-DD')
      if (date.indexOf(today) === 0) {
        return date.substring(11, 16)
      } else {
        return date.substring(0, 16)
      }
    },
    toBottom() {
      let dom = document.getElementById('chatMessageBox')
      dom.scrollTo({
        top: 10000,
        behavior: 'smooth'
      })
    },
    hideGoodsModal() {
      this.hideGoods = true
    }
  }
}
</script>