<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-form :model="form" inline>
        <el-form-item :label="$t('ding-dan-lei-xing')" prop="type">
          <el-select v-model="form.type">
            <el-option
              v-for="(item, i) in typeList"
              :key="i"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('xia-dan-shi-jian-1')" prop="orderTime">
          <el-date-picker
            v-model="form.orderTime"
            type="daterange"
            :start-placeholder="$t('kai-shi-ri-qi')"
            :end-placeholder="$t('jie-shu-ri-qi')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">{{
            $t('cha-xun')
          }}</el-button>
          <el-button @click="reset">{{ $t('zhong-zhi') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-container">
          <el-table
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              :label="$t('ding-dan-lei-xing-0')"
              prop="Type"
            >
              <template slot-scope="scope">
                <div>{{ getTypeName(scope.row.Type) }}</div>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              :label="$t('bian-geng-jin-e')"
              prop="Practical"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('bian-geng-qian')"
              prop="DebtBefore"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('bian-geng-hou')"
              prop="DebtAfter"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('zhang-bian-shi-jian')"
              prop="CreatedAt"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { debtList, debtType } from '@/api/shop'
import moment from 'moment'
export default {
  data() {
    return {
      form: {
        type: '',
        orderTime: []
      },
      tableData: [],
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 1
      },
      detailData: {},
      showOrderDialog: false,
      showLogisticsDialog: false,
      typeList: []
    }
  },
  mounted() {
    this.initType()
  },
  methods: {
    initType() {
      debtType().then((res) => {
        let data = res.data
        let list = []
        for (let key in data) {
          let param = {
            value: key,
            label: data[key]
          }
          list.push(param)
        }
        this.typeList = list
        this.init()
      })
    },
    init() {
      let start = null
      let end = null
      if (this.form.orderTime && this.form.orderTime.length > 0) {
        start =
          moment(this.form.orderTime[0]).format('YYYY-MM-DD') + ' 00:00:00'
        end = moment(this.form.orderTime[1]).format('YYYY-MM-DD') + ' 23:59:59'
      }
      debtList({
        current: this.page.current,
        pageSize: this.page.size,
        Type: this.form.type ? parseFloat(this.form.type) : null,
        Start: start,
        End: end
      }).then((res) => {
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    getTypeName(type) {
      let index = this.typeList.findIndex((v) => {
        return v.value == type
      })
      if (index > -1) {
        return this.typeList[index].label
      } else {
        return ''
      }
    },
    changeTab(i) {
      this.tabIndex = i
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {
      this.page.size = size
      this.init()
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    },
    orderDetail(data) {
      this.detailData = data
      this.showOrderDialog = true
    },
    orderLogistics(data) {
      this.showLogisticsDialog = true
    },
    search() {
      this.init()
    },
    reset() {
      this.form = {
        type: '',
        orderTime: []
      }
    }
  }
}
</script>