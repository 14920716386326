<template>
  <div class="shop-order">
    <el-card class="top-card">
      <el-form :model="form" inline>
        <el-form-item :label="$t('shang-pin-ming-cheng')" prop="name">
          <el-input
            v-model="form.title"
            :placeholder="$t('qing-shu-ru-shang-pin-ming-cheng-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('shang-pin-id-0')" prop="id">
          <el-input
            v-model="form.id"
            :placeholder="$t('qing-shu-ru-shang-pin-id-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('shang-pin-fen-lei')" prop="category">
          <el-cascader
            v-model="category"
            :options="categoryList"
            filterable
            :props="{ checkStrictly: true }"
          ></el-cascader>
        </el-form-item>
        <el-form-item :label="$t('shang-pin-zhuang-tai')" prop="status">
          <el-select v-model="form.status">
            <el-option
              v-for="(item, i) in statusList"
              :key="i"
              :value="item.value"
              ::label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">{{
            $t("cha-xun")
          }}</el-button>
          <el-button @click="reset">{{ $t("zhong-zhi") }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-btns">
          <el-button type="primary" @click="batchEdit(1)">{{
            $t("pi-liang-shang-jia")
          }}</el-button>
          <el-button type="danger" @click="batchEdit(2)">{{
            $t("pi-liang-xia-jia")
          }}</el-button>
          <el-button type="primary" @click="batchEditProduct">{{
            $t("pi-liang-xiu-gai")
          }}</el-button>
          <el-button type="danger" @click="batchRemove">{{
            $t("pi-liang-shan-chu")
          }}</el-button>
        </div>
        <div class="table-container">
          <el-table
            :data="tableData"
            v-loading="tableLoading"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('shang-pin-id-0')"
              prop="ID"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('feng-mian-tu')"
              prop="MainImage"
            >
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.MainImage"
                  :preview-src-list="[scope.row.MainImage]"
                  style="width: 40px; height: 40px"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('shang-pin-ming-cheng')"
              prop="name"
              width="320px"
            >
              <template slot-scope="scope">
                {{
                  scope.row.ProductTranslation &&
                  scope.row.ProductTranslation[0]
                    ? scope.row.ProductTranslation[0].Name
                    : ""
                }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              :label="$t('fen-lei-id')"
              prop="orderNumber"
            >
              <template slot-scope="scope">
                {{
                  scope.row.ProductCategory
                    ? scope.row.ProductCategory.ParentID
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('fen-lei-ming-cheng')"
              prop="orderNumber"
            >
              <template slot-scope="scope">
                {{ getCategoryName(scope.row.ProductCategory) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('cai-gou-jia-ge')"
              prop="MarketPrice"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('biao-qian')"
              prop="orderNumber"
              width="120px"
            >
              <template slot-scope="scope">
                <div class="table-action">
                  <div class="flex-center">
                    {{ $t("shang-jia-0") }}
                    <el-switch
                      @change="(e) => changeStatus(e, scope.row)"
                      v-model="scope.row.up"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    ></el-switch>
                  </div>
                  <div class="flex-center">
                    {{ $t("zhi-tong-che") }}
                    <el-switch
                      v-model="scope.row.car"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    ></el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :label="$t('xiao-shou-jia-ge')"
              prop="SalePrice"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('zhe-kou-jia')"
              prop="MinPrice"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('li-run-0')"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('lei-ji-xiao-liang')"
              prop="Sales"
            ></el-table-column>
            <el-table-column
              align="center"
              :label="$t('cao-zuo')"
              width="140px"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="showParams(scope.row)">{{
                  $t("gui-ge-0")
                }}</el-button>
                <el-button type="text" @click="editProduct(scope.row)">{{
                  $t("xiu-gai")
                }}</el-button>
                <el-button
                  type="text"
                  class="danger"
                  @click="remove(scope.row)"
                  >{{ $t("shan-chu") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      :title="$t('shang-pin-gui-ge')"
      :visible.sync="paramDialog"
      width="800px"
      class="table-dialog"
    >
      <el-table :data="paramList" border>
        <el-table-column
          align="center"
          :label="$t('yan-se')"
          prop="orderNumber"
        ></el-table-column>
        <el-table-column
          align="center"
          :label="$t('da-xiao')"
          prop="orderNumber"
        ></el-table-column>
        <el-table-column
          align="center"
          :label="$t('cai-gou-jia-ge')"
          prop="orderNumber"
        ></el-table-column>
        <el-table-column
          align="center"
          :label="$t('xiao-shou-jia-ge')"
          prop="orderNumber"
        ></el-table-column>
        <el-table-column
          align="center"
          :label="$t('zhe-kou-jia-0')"
          prop="orderNumber"
        ></el-table-column>
        <el-table-column
          align="center"
          :label="$t('li-run-0')"
          prop="orderNumber"
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paramDialog = false">{{ $t("qu-xiao") }}</el-button>
        <el-button type="primary" @click="paramDialog = false">{{
          $t("que-ding-0")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('xiu-gai-shang-pin')"
      :visible.sync="editDialog"
      width="460px"
      class="table-dialog"
    >
      <el-form :model="editForm" ref="editForm" :rules="formRules">
        <!-- <el-form-item :label="$t('can-kao-shou-jia')" prop="price" required>
          <el-input
            v-model="editForm.price"
            :placeholder="$t('qing-shu-ru-can-kao-shou-jia-0')"
          ></el-input>
          <div class="form-desc">
            {{ $t('zui-zhong-shou-jia-yi-li-run-bi-li-huan-suan-wei-zhun') }}
          </div>
        </el-form-item> -->
        <el-form-item :label="$t('li-run-bi-li')" prop="ProfitPercentage">
          <el-input
            v-model="editForm.ProfitPercentage"
            :placeholder="$t('qing-shu-ru-li-run-bi-li-1')"
          >
            <template slot="append">%</template>
          </el-input>
          <div class="form-desc">
            {{
              $t(
                "jiang-xuan-zhong-de-shang-pin-fa-bu-dao-ni-de-dian-pu-bing-tian-xie-li-run-bi-li"
              )
            }}
          </div>
          <div class="form-desc">{{ $t("jian-yi-li-run-bi-li-50-150") }}</div>
        </el-form-item>
        <el-form-item :label="$t('zhe-kou-ri-qi')" prop="date">
          <el-date-picker
            style="width: 100%"
            v-model="editForm.date"
            type="daterange"
            :start-placeholder="$t('kai-shi-ri-qi')"
            :end-placeholder="$t('jie-shu-ri-qi')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('zhe-kou-bi-li')" prop="DiscountPercentage">
          <el-input
            v-model="editForm.DiscountPercentage"
            :placeholder="$t('qing-shu-ru-li-run-bi-li-2')"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="full-width" @click="submit">{{
            $t("ti-jiao")
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  shopProductList,
  removeProduct,
  editShopProduct,
  batchEditStatus,
  shopInfo
} from "@/api/shop";
import { getUserId } from "@/utils/auth";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        title: "",
        id: "",
        category: ""
      },
      category: [],
      editForm: {
        date: [],
        ProfitPercentage: "",
        DiscountPercentage: ""
      },
      tableData: [],
      selectRows: [],
      formRules: {
        ProfitPercentage: [
          { validator: this.checkNumber, trigger: "change", required: true }
        ]
      },
      page: {
        size: 10,
        current: 1,
        total: 1
      },
      statusList: [
        {
          value: "",
          label: this.$t("quan-bu")
        },
        {
          value: 1,
          label: this.$t("shang-jia")
        },
        {
          value: 2,
          label: this.$t("xia-jia")
        }
      ],
      detailData: {},
      paramDialog: false,
      editDialog: false,
      tableLoading: false,
      paramList: [],
      editId: ""
    };
  },
  computed: {
    ProfitRate() {
      return this.$store.state.ProfitRate;
    },
    lang() {
      return this.$store.state.lang;
    },
    categoryList() {
      return this.resetCategoryList(this.$store.state.categoryList);
    }
  },
  mounted() {
    let category = this.$route.query.category;
    this.category = category;
    this.init();
    this.initCategory();
  },
  methods: {
    initCategory() {
      this.$store.dispatch("initCategory", this.lang);
    },
    resetCategoryList(list) {
      return list.map((v) => {
        return {
          value: v.ID,
          label: v.title,
          children: v.children ? this.resetCategoryList(v.children) : null
        };
      });
    },
    init() {
      this.tableLoading = true;
      if (this.category && this.category.length > 0) {
        this.form.category = this.category[this.category.length - 1];
      } else {
        if (this.category instanceof Array) {
          this.form.category = null;
        } else {
          this.form.category = this.category;
        }
      }
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        Title: this.form.title,
        ID: this.form.id || null,
        CategoryID: this.form.category || null,
        Status: this.form.status,
        UId: parseFloat(getUserId())
      }).then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.Items.map((v) => {
          return {
            ...v,
            up: v.Status == 1
          };
        });
        this.page.total = res.data.Pagination.totalRecords;
      });
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },
    search() {
      this.page.page = 1;
      this.page.size = 10;
      this.init();
    },
    sizeChange(size) {
      this.page.size = size;
      this.init();
    },
    currentChange(page) {
      this.page.page = page;
      this.init();
    },
    showParams(data) {
      this.paramDialog = true;
    },
    remove(data) {
      this.$confirm(
        this.$t("que-ren-shan-chu-gai-shu-ju"),
        this.$t("que-ding")
      ).then(() => {
        removeProduct({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success(this.$t("shan-chu-cheng-gong"));
            this.init();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
    editProduct(data) {
      this.editDialog = true;
      this.editId = [data.ID];
      this.editForm = {
        date: [],
        ProfitPercentage: "",
        DiscountPercentage: ""
      };
    },
    batchEditProduct() {
      if (this.selectRows.length === 0) {
        this.$message.warning(this.$t("qing-gou-xuan-shu-ju"));
        return;
      }
      this.editForm = {
        date: [],
        ProfitPercentage: "",
        DiscountPercentage: ""
      };
      this.editDialog = true;
      this.editId = this.selectRows.map((v) => {
        return v.ID;
      });
    },
    reset() {
      this.form = {
        title: "",
        id: "",
        category: ""
      };
      this.category = [];
    },
    getCategoryName(data) {
      if (!data) {
        return "";
      }
      return data.ProductCategoryTranslations.find((v) => {
        return v.Language == this.lang;
      }).Name;
    },
    async batchRemove() {
      if (this.selectRows.length === 0) {
        this.$message.warning(this.$t("qing-gou-xuan-shu-ju"));
        return;
      }
      let index = 0;
      for (let i = 0; i < this.selectRows.length; i++) {
        let id = this.selectRows[i].ID;
        let res = await removeProduct({
          ID: id
        });
        if (res.code == 0) {
          index += 1;
          if (index == this.selectRows.length) {
            this.init();
            this.selectRows = [];
          }
          continue;
        } else {
          this.$message.warning(res.msg);
          break;
        }
      }
    },
    batchEdit(status) {
      if (this.selectRows.length === 0) {
        this.$message.warning(this.$t("qing-gou-xuan-shu-ju"));
        return;
      }
      let ids = this.selectRows.map((v) => {
        return v.ID;
      });
      let form = new FormData();
      form.append("IDs", ids);
      form.append("Status", status);
      batchEditStatus({
        IDs: ids,
        Status: status
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t("cao-zuo-cheng-gong"));
          this.page.current = 1;
          this.init();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    checkNumber(rules, value, callback) {
      if (!value) {
        callback(new Error(this.$t("qing-shu-ru-li-run-bi-li-1")));
      } else if (value > this.ProfitRate) {
        callback(
          new Error(
            this.$t("li-run-bi-li-bu-neng-chao-guo-thisprofitrate", [
              this.ProfitRate
            ])
          )
        );
      } else {
        callback();
      }
    },
    submit() {
      this.$refs.editForm.validate((vali) => {
        if (vali) {
          let date = this.editForm.date;
          editShopProduct({
            IDs: this.editId,
            ProfitPercentage: parseFloat(this.editForm.ProfitPercentage),
            DiscountStartDate: moment(date[0]).format("YYYY-MM-DD"),
            DiscountEndDate: moment(date[1]).format("YYYY-MM-DD"),
            DiscountPercentage: parseFloat(this.editForm.DiscountPercentage)
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t("xiu-gai-cheng-gong"));
              this.editDialog = false;
            } else {
              this.$message.warning(res.msg);
            }
          });
        }
      });
    },
    changeStatus(e, data) {
      batchEditStatus({
        IDs: [parseFloat(data.ID)],
        Status: e ? 1 : 2
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(
            e ? this.$t("shang-jia-cheng-gong") : this.$t("xia-jia-cheng-gong")
          );
          this.page.current = 1;
          this.init();
        } else {
          this.$message.warning(res.msg);
        }
      });
    }
  }
};
</script>
