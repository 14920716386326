<template>
  <div class="menu-container">
    <div class="top-info flex-column-center">
      <div class="header">
        <img :src="headerUrl" alt="" />
      </div>
      <div class="info">
        <div class="name">{{ shopName }}</div>
        <div class="id">{{ userId }}</div>
        <div class="email">{{ shopEmail }}</div>
      </div>
      <el-button type="primary" size="mini" @click="toShop">{{
        $t("cha-kan-wo-de-dian-pu")
      }}</el-button>
    </div>
    <div class="menu-outer">
      <el-menu :default-active="activeName" class="menu" @select="select">
        <div class="wrapper">
          <MenuItem :menus="menuList" type="111"></MenuItem>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
import MenuItem from "./MenuItem.vue";
import { shopInfo } from "@/api/shop";
import { getUserId } from "@/utils/auth";
export default {
  components: {
    MenuItem
  },
  data() {
    return {
      headerUrl: require("@/assets/imgs/header.jpeg"),
      menuList: [],
      shopName: "",
      shopId: "",
      shopEmail: "",
      activeName: ""
    };
  },
  computed: {
    activeRoute() {
      return this.$store.state.activeRoute;
    },
    lang() {
      return this.$store.state.lang;
    },
    userId() {
      return getUserId();
    },
    sellerPcRoutes() {
      return [
        {
          path: "/dashboard",
          name: "dashboard",
          meta: {
            type: "seller",
            title: this.$t("yi-biao-pan"),
            key: "yi-biao-pan",
            icon: "el-icon-house"
          },
          component: () => import("@/views/seller/index/index")
        },
        {
          path: "/shopOrder",
          name: "shopOrder",
          meta: {
            type: "seller",
            title: this.$t("dian-pu-ding-dan"),
            key: "dian-pu-ding-dan",
            icon: "el-icon-s-order"
          },
          component: () => import("@/views/seller/shop/shopOrder")
        },
        {
          path: "/shopReport",
          name: "shopReport",
          meta: {
            type: "seller",
            title: this.$t("cai-wu-bao-biao-0"),
            key: "cai-wu-bao-biao-0",
            icon: "el-icon-data-line"
          },
          component: () => import("@/views/seller/shop/shopReport")
        },
        {
          path: "/myWallet",
          name: "myWallet",
          meta: {
            type: "seller",
            title: this.$t("wo-de-qian-bao"),
            key: "wo-de-qian-bao",
            icon: "el-icon-wallet"
          },
          component: () => import("@/views/seller/mine/wallet")
        },
        {
          path: "/recharge",
          name: "recharge",
          meta: {
            type: "seller",
            title: this.$t("chong-zhi-0"),
            key: "chong-zhi-0",
            hide: true
          },
          component: () => import("@/views/seller/mine/recharge")
        },
        {
          path: "/withdraw",
          name: "withdraw",
          meta: {
            type: "seller",
            title: this.$t("ti-xian-0"),
            key: "ti-xian-0",
            hide: true
          },
          component: () => import("@/views/seller/mine/withdraw")
        },
        {
          path: "/shopRebet",
          name: "shopRebet",
          meta: {
            type: "seller",
            title: this.$t("zi-jin-ji-lu"),
            key: "zi-jin-ji-lu",
            icon: "el-icon-bank-card"
          },
          component: () => import("@/views/seller/shop/shopRebet")
        },
        {
          path: "/productsManage",
          name: "productsManage",
          meta: {
            type: "seller",
            title: this.$t("shang-pin-guan-li"),
            key: "shang-pin-guan-li",
            icon: "el-icon-shopping-bag-1"
          },
          component: () => import("@/views/blank"),
          children: [
            {
              path: "/shopProducts",
              name: "shopProducts",
              meta: {
                type: "seller",
                title: this.$t("dian-pu-shang-pin"),
                key: "dian-pu-shang-pin",
                icon: "el-icon-shopping-bag-2"
              },
              component: () => import("@/views/seller/shop/shopProduct")
            },
            {
              path: "/shopRefund",
              name: "shopRefund",
              meta: {
                type: "seller",
                title: this.$t("tui-kuan-qing-qiu"),
                key: "tui-kuan-qing-qiu",
                icon: "el-icon-warning"
              },
              component: () => import("@/views/seller/shop/shopRefund")
            },
            {
              path: "/productEvalute",
              name: "productEvalute",
              meta: {
                type: "seller",
                title: this.$t("shang-pin-ping-lun"),
                key: "shang-pin-ping-lun",
                icon: "el-icon-chat-dot-square"
              },
              component: () => import("@/views/seller/shop/productEvalute")
            },
            {
              path: "/productLibrary",
              name: "productLibrary",
              meta: {
                type: "seller",
                title: this.$t("shang-pin-ku-0"),
                key: "shang-pin-ku-0",
                icon: "el-icon-s-home"
              },
              component: () => import("@/views/seller/shop/productLibrary")
            }
          ]
        },
        {
          path: "/shopSetting",
          name: "shopSetting",
          meta: {
            type: "seller",
            title: this.$t("dian-pu-she-zhi-0"),
            key: "dian-pu-she-zhi-0",
            icon: "el-icon-s-tools"
          },
          component: () => import("@/views/seller/shop/shopSetting")
        },
        {
          path: "/saleManage",
          name: "saleManage",
          meta: {
            type: "seller",
            title: this.$t("ying-xiao-gong-ju"),
            key: "ying-xiao-gong-ju",
            icon: "el-icon-s-data"
          },
          component: () => import("@/views/blank"),
          children: [
            {
              path: "/shopCar",
              name: "shopCar",
              meta: {
                type: "seller",
                title: this.$t("dian-pu-zhi-tong-che-0"),
                key: "dian-pu-zhi-tong-che-0",
                icon: "el-icon-truck"
              },
              component: () => import("@/views/seller/shop/shopCar")
            },
            {
              path: "/buyHistory",
              name: "buyHistory",
              meta: {
                type: "seller",
                title: this.$t("gou-mai-li-shi"),
                key: "gou-mai-li-shi",
                icon: "el-icon-time"
              },
              component: () => import("@/views/seller/shop/buyHistory")
            },
            {
              path: "/shopLevel",
              name: "shopLevel",
              meta: {
                type: "seller",
                title: this.$t("mai-jia-deng-ji-1"),
                key: "mai-jia-deng-ji-1",
                icon: "el-icon-upload2"
              },
              component: () => import("@/views/seller/shop/shopLevel")
            }
          ]
        }
      ];
    }
  },
  watch: {
    activeRoute() {
      this.activeName = this.activeRoute;
    },
    lang() {
      this.menuList = this.sellerPcRoutes.filter((v) => {
        return !v.meta.hide;
      });
    }
  },
  mounted() {
    this.menuList = this.sellerPcRoutes.filter((v) => {
      return !v.meta.hide;
    });
    this.init();
  },
  methods: {
    init() {
      shopInfo().then((res) => {
        let data = res.data;
        this.headerUrl = data.Logo;
        this.shopName = data.Name;
        this.$store.state.shopUserName = data.Name;
        this.shopId = data.ID;
        this.shopEmail = this.$store.state.username;
        this.$store.state.shopId = data.ID;
        this.$store.state.isShopAuth = data.IsAuth === 1;
        this.$store.state.FirstSetup = data.FirstSetup === 1;
        this.$store.state.ProfitRate = data.ShopLevel.ProfitRate;
      });
    },
    select(name) {
      this.$router.push(name);
      this.$store.state.activeRoute = name;
      let menu = this.findMenu(name);
      if (menu) {
        this.$store.dispatch("addTabs", menu);
      }
    },
    findMenu(name) {
      let menu = {};
      this.menuList.forEach((v) => {
        if (v.children && v.children.length > 0) {
          v.children.forEach((val) => {
            if (val.name == name) {
              menu = val;
            }
          });
        } else {
          if (v.name == name) {
            menu = v;
          }
        }
      });
      return menu;
    },
    toShop() {
      this.$router.push({
        name: "shopInfo",
        query: {
          id: this.shopId
        }
      });
    }
  }
};
</script>
