<template>
  <div class="wap-login">
    <div class="flex-center-between top-info">
      <div class="title">{{ $t('deng-lu-0') }}</div>
      <div class="flex-center lang" @click="toPath('wapLang')">
        <img :src="langIcon" alt="" />
        <i class="el-icon-caret-bottom"></i>
      </div>
    </div>

    <div class="logo flex-column-center">
      <img :src="logo" alt="" />
      <span class="name">{{ appName }}</span>
    </div>

    <div class="tabs flex">
      <div
        class="tab"
        :class="tabIndex == 1 ? 'active' : ''"
        @click="changeTab(1)"
      >
        {{ $t('you-xiang-0') }}
      </div>
      <div
        class="tab"
        :class="tabIndex == 2 ? 'active' : ''"
        @click="changeTab(2)"
      >
        {{ $t('shou-ji-hao') }}
      </div>
    </div>

    <div class="login-form">
      <div class="form-item" v-if="tabIndex == 1">
        <div class="label">{{ $t('you-xiang-0') }}</div>
        <Field
          v-model="form.email"
          :placeholder="$t('qing-shu-ru-you-xiang-7')"
        />
      </div>
      <div class="form-item" v-if="tabIndex == 2">
        <div class="label">{{ $t('shou-ji-hao') }}</div>
        <Field
          v-model="form.phone"
          :placeholder="$t('qing-shu-ru-shou-ji-hao-12')"
        >
          <template #left-icon>
            <div class="flex-center" @click="changeCountry">
              {{ form.phonePre }}
              <i class="el-icon-caret-bottom"></i>
            </div>
          </template>
        </Field>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('mi-ma') }}</div>
        <Field
          v-model="form.password"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$t('qing-shu-ru-mi-ma-4')"
        >
          <template #button>
            <div class="flex-center" @click="showPassword = !showPassword">
              <img :src="eyeUrl" alt="" v-if="!showPassword" />
              <img :src="eyeUrl2" alt="" v-else />
            </div>
          </template>
        </Field>
      </div>
    </div>

    <div class="remember-pwd">
      <van-checkbox v-model="rememberPwd" shape="square">{{ $t('ji-zhu-mi-ma') }}</van-checkbox>
    </div>

    <div class="form-info flex-center-between">
      <div>
        {{ $t('ru-guo-nin-mei-you-zhang-hao') }}
        <span class="blue" @click="toPath('wapRegister')">{{
          $t('dian-ji-zhu-ce')
        }}</span>
      </div>
      <div class="blue">{{ $t('wang-ji-mi-ma-0') }}</div>
    </div>

    <div class="form-btn">
      <Button type="info" @click="submit">{{ $t('deng-lu-0') }}</Button>
    </div>

    <div class="kefu flex-center" @click="showChat">
      <i class="el-icon-service"></i>
    </div>

    <CountryList
      :showDialog.sync="showDialog"
      :phonePre.sync="form.phonePre"
    ></CountryList>
  </div>
</template>
<script>
import { Button, Field, Checkbox } from 'vant'
import CountryList from '@/components/countryList'
import { login } from '@/api/login'
import { setToken, setUser, setUserId, setUserType } from '@/utils/auth'
export default {
  components: {
    Button,
    Field,
    CountryList,
    vanCheckbox: Checkbox
  },
  data() {
    return {
      form: {
        email: localStorage.getItem('rememberedEmail') || '',
        phone: localStorage.getItem('rememberedPhone') || '',
        phonePre: localStorage.getItem('rememberedPhonePre') || '+86',
        password: localStorage.getItem('rememberedPassword') || ''
      },
      showPassword: false,
      showDialog: false,
      tabIndex: Number(localStorage.getItem('rememberedTabIndex')) || 1,
      logoUrl: require('@/assets/imgs/logo.png'),
      eyeUrl: require('@/assets/imgs/icon-eye.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2.png'),
      rememberPwd: localStorage.getItem('rememberedPwd') === 'true'
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    },
    appName() {
      return this.$store.state.appName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    lang() {
      return this.$store.state.lang
    }
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index
      localStorage.setItem('rememberedTabIndex', index)
    },
    submit() {
      if (this.tabIndex === 1 && !this.form.email) {
        this.$toast(this.$t('qing-shu-ru-you-xiang-0'))
        return
      }
      if (this.tabIndex === 2 && !this.form.phone) {
        this.$toast(this.$t('qing-shu-ru-shou-ji-hao-10'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.$t('qing-shu-ru-mi-ma-0'))
        return
      }
      login({
        Account:
          this.tabIndex === 1
            ? this.form.email
            : `${this.form.phonePre}${this.form.phone}`,
        Password: this.form.password,
        IsBusiness: 1
      }).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('rememberedPwd', this.rememberPwd)
          
          if (this.rememberPwd) {
            if (this.tabIndex === 1) {
              localStorage.setItem('rememberedEmail', this.form.email)
            } else {
              localStorage.setItem('rememberedPhone', this.form.phone)
              localStorage.setItem('rememberedPhonePre', this.form.phonePre)
            }
            localStorage.setItem('rememberedPassword', this.form.password)
          } else {
            localStorage.removeItem('rememberedEmail')
            localStorage.removeItem('rememberedPhone')
            localStorage.removeItem('rememberedPhonePre')
            localStorage.removeItem('rememberedPassword')
          }
          
          this.$toast(this.$t('deng-lu-cheng-gong'))
          let data = res.data
          this.$store.state.userType = data.business
          this.$store.state.isLogin = true
          setUserId(data.id)
          setUserType(data.business)
          setToken(data.token)
          this.$router.push({
            name: data.business == 1 ? 'wapHome' : 'wapIndex'
          })
        } else {
          this.$toast(res.msg)
        }
      })
    },
    changeCountry() {
      this.showDialog = true
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    showChat() {
      this.$store.state.chatType = ''
      this.$router.push({
        name: 'wapChat',
        query: {
          name: this.$t('zai-xian-ke-fu'),
          uid: '1'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.remember-pwd {
  padding: 0;
  margin: 15px 0px;
  
  :deep(.van-checkbox__label) {
    color: #666;
    font-size: 14px;
  }
}
</style>